import { createCssCompNode } from '../cssCompNode'
import { createCompNode } from '@wix/thunderbolt-catharsis'
import { withModes } from '../modesCompNode'
import { hasResponsiveLayout } from '../../utils/hasResponsiveLayout'

const OVERRIDEN_PROPS = ['layout' as const]

const rotation = createCompNode({
	getDependencies: () => null,
	toViewItem: (component) =>
		!hasResponsiveLayout(component) && component.layout.rotationInDegrees
			? { transform: `rotate(${component.layout.rotationInDegrees}deg)` }
			: undefined,
})

export const classicRotation = createCssCompNode(
	'classicRotation',
	'classicRotation',
	withModes(rotation, OVERRIDEN_PROPS)
)
