import { createDataNode } from '../nodeFactory/dataNodeUtils'

import {
	ActionBehavior,
	AnimationsBehavior,
	ObsoleteBehaviorsList as ObsoleteBehaviorsListType,
} from '@wix/thunderbolt-becky-types'
import { ObsoleteBehaviorsListViewItem } from '../types/ObsoleteBehaviorsListViewItem.types'

const isAnimationsBehavior = (item: any): item is AnimationsBehavior =>
	item.hasOwnProperty('action') && !item.hasOwnProperty('behavior')

export const ObsoleteBehaviorsList = createDataNode({
	getDependencies: () => null,
	toViewItem: (dataItem: ObsoleteBehaviorsListType): ObsoleteBehaviorsListViewItem => {
		const items = JSON.parse(dataItem.items) as Array<ActionBehavior>
		const parsedDataItem: ObsoleteBehaviorsListViewItem = {
			id: dataItem.id,
			type: dataItem.type,
			animations: [],
			behaviors: [],
		}

		return items.reduce((acc, item) => {
			if (isAnimationsBehavior(item)) {
				acc.animations.push(item)
			} else {
				acc.behaviors.push(item)
			}
			return acc
		}, parsedDataItem)
	},
})
