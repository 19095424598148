import { createRef } from '@wix/materializer'
import { ThemeData, ThemeFontMap } from '@wix/thunderbolt-becky-types'
import type { BeckyModel } from '@wix/thunderbolt-becky-root'

export const envRefs = {
	renderScrollSnap: createRef<boolean>(['environment', 'renderFlags', 'renderScrollSnap']),
	renderSticky: createRef<boolean>(['environment', 'renderFlags', 'renderSticky']),
	showScreenInComp: createRef<boolean>(['environment', 'renderFlags', 'showScreenInComp']),
	experiment: (experiment: string) => createRef<boolean>(['environment', 'atoms', 'experiments', experiment]),
	experiments: createRef<BeckyModel['experiments']>(['environment', 'atoms', 'experiments']),
	// Where this should be?
	isMobileView: createRef<boolean>(['environment', 'mobile', 'isMobileView']),
	isNonAdaptiveMobile: createRef<boolean>(['environment', 'mobile', 'isNonAdaptiveMobile']),
	deviceType: createRef<BeckyModel['deviceType'] | undefined>(['environment', 'mobile', 'deviceType']),
	viewMode: createRef<BeckyModel['viewMode']>(['environment', 'atoms', 'viewMode']),
	meshRuntimeData_absoluteComps: createRef<BeckyModel['meshRuntimeData']['absoluteComps']>([
		'environment',
		'atoms',
		'meshRuntimeData',
		'absoluteComps',
	]),
	siteWidth: createRef<number>(['data', 'document_data', 'masterPage', 'renderModifiers', 'siteWidth']),
	componentViewMode: createRef<BeckyModel['renderFlags']['componentViewMode']>([
		'environment',
		'renderFlags',
		'componentViewMode',
	]),
	enableVariantsTransitionsInEditor: createRef<BeckyModel['enableVariantsTransitionsInEditor']>([
		'environment',
		'atoms',
		'enableVariantsTransitionsInEditor',
	]),
	publicTopology: createRef<BeckyModel['publicTopology']>(['environment', 'atoms', 'publicTopology']),
	qaMode: createRef<BeckyModel['qaMode']>(['environment', 'atoms', 'qaMode']),
	language: createRef<BeckyModel['language']>(['environment', 'atoms', 'language'], 'en'),
	metaSiteId: createRef<BeckyModel['metaSiteId']>(['environment', 'atoms', 'metaSiteId']),
	externalBaseUrl: createRef<BeckyModel['externalBaseUrl']>(['environment', 'atoms', 'externalBaseUrl']),
}

export const themeRefs = {
	themeFonts: createRef<ThemeFontMap>(['data', 'theme_data', 'THEME_DATA', 'fontMap']),
	siteColors: createRef<ThemeData['color']>(['data', 'theme_data', 'THEME_DATA', 'color']),
}

export const qaRefs = {
	fullNameCompType: (id: string) => createRef<string>(['QA', 'fullNameCompType', id]),
}
