/*
 * Deprecated skins
 */
export const deprecatedSkinsFallbacks: { [key: string]: string } = {
	// DropDownMenu
	AdminLoginSkin: 'AdminLoginButtonSkin',
	ArrowsMenuSkin: 'TextOnlyMenuButtonSkin',
	ArrowsMenuNSkin: 'TextOnlyMenuButtonSkin',
	CirclesMenuSkin: 'TextOnlyMenuButtonSkin',
	CirclesMenuNSkin: 'TextOnlyMenuButtonSkin',
	ForkedBannerMenuSkin: 'TextOnlyMenuButtonSkin',
	ForkedRibbonMenuSkin: 'TextOnlyMenuButtonSkin',
	SeparateArrowDownMenuSkin: 'TextOnlyMenuButtonSkin',
	SeparateArrowDownMenuNSkin: 'TextOnlyMenuButtonSkin',
	SeparatedArrowsMenuSkin: 'TextOnlyMenuButtonSkin',
	SeparatedArrowsMenuNSkin: 'TextOnlyMenuButtonSkin',
	SlantedMenuNSkin: 'TextOnlyMenuButtonSkin',
	// TextAreaInput
	TextAreaInputSkin: 'TextAreaDefaultSkin',
	AppsTextAreaInputSkin: 'TextAreaDefaultSkin',
	AppsTextAreaInputSkinNoValidation: 'TextAreaDefaultSkin',
	// ComboBoxInput
	AppsComboBoxInputSkin: 'ComboBoxInputSkin',
	AppsComboBoxInputSkinNoValidation: 'ComboBoxInputSkin',
	ComboBoxInputSkinNoValidation: 'ComboBoxInputSkin',
	// AudioPlayer
	AudioPlayerMinimalSkin: 'SimplePlayer',
}
