import { parseFontFaces } from './fontsParser'

export const studioFonts = parseFontFaces(`
/* fonts helvetica */

/*@import url("//fast.fonts.com/t/1.css?apiType=css&projectid=33bd0973-5b82-45be-bb77-837225874dfe");*/

html{
    background-image:url("//fast.fonts.com/t/1.css?apiType=css&projectid=33bd0973-5b82-45be-bb77-837225874dfe");
}


/* Original old fonts */

@font-face {
    font-display: block;
    font-family: "Helvetica Neue";
    src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix") format("embedded-opentype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg#da09f1f1-062a-45af-86e1-2bbdb3dd94f9") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Helvetica Neue Thin";
    font-weight: 200;
    src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/56be84de-9d60-4089-8df0-0ea6ec786b84.eot?#iefix") format("embedded-opentype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/278bef59-6be1-4800-b5ac-1f769ab47430.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2e309b1b-08b8-477f-bc9e-7067cf0af0b3.svg#2e309b1b-08b8-477f-bc9e-7067cf0af0b3") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Helvetica Neue Medium";
    font-weight: bold;
    src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot??#iefix") format("embedded-opentype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4c6503c9-859b-4d3b-a1d5-2d42e1222415.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/36c182c6-ef98-4021-9b0d-d63122c2bbf5.svg#36c182c6-ef98-4021-9b0d-d63122c2bbf5") format("svg");
}

/* Kwazimodo temporary studio fonts */

@font-face {
    font-display: block;
    font-family:"Helvetica Neue Ultralight";
    src:url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/072d8516-5d40-44bc-b694-65b3c8bd8fa5.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/072d8516-5d40-44bc-b694-65b3c8bd8fa5.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/723fd1ed-5aad-454d-af88-3711c5caf1c7.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2a81d94e-d365-4f5f-9957-7e96414d6a72.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/6be38bc3-96b3-47e3-a9ef-e1a184b54d64.svg#6be38bc3-96b3-47e3-a9ef-e1a184b54d64") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Helvetica Neue Italic";
    font-style: italic;
    src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/58a5cbff-d570-4c18-a5e3-60868dc07ae8.eot?#iefix") format("embedded-opentype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2c056da8-4920-4e20-8c69-8a6b315458a9.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2381d918-136d-444f-8391-db0cba6da388.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d0697971-6f58-4597-942e-8beabd1adc87.svg#d0697971-6f58-4597-942e-8beabd1adc87") format("svg");
}

@font-face {
    font-display: block;
    font-family:"Helvetica Neue Light";
    src:url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/88fcd49a-13c7-4d0c-86b1-ad1e258bd75d.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/88fcd49a-13c7-4d0c-86b1-ad1e258bd75d.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9a2e4855-380f-477f-950e-d98e8db54eac.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/fa82d0ee-4fbd-4cc9-bf9f-226ad1fcbae2.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/48d599a6-92b5-4d43-a4ac-8959f6971853.svg#48d599a6-92b5-4d43-a4ac-8959f6971853") format("svg");
}

@font-face {
    font-display: block;
    font-family:"Helvetica Neue Bold";
    src:url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/db853e0e-929b-4272-b420-c946c954cf3a.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/db853e0e-929b-4272-b420-c946c954cf3a.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4a9c62ab-b359-4081-8383-a0d1cdebd111.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/db5f9ba6-05a4-433a-9461-0a6f257a0c3a.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/25e09910-ffc3-4fc4-b0d1-db9a95dface8.svg#25e09910-ffc3-4fc4-b0d1-db9a95dface8") format("svg");
}

@font-face {
    font-display: block;
    font-family:"Helvetica Neue Black";
    src:url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c8ec8ade-129c-47df-86b4-f9f1cd69ac15.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c8ec8ade-129c-47df-86b4-f9f1cd69ac15.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/6d5b06b3-f8db-4de6-aa46-2a6de9569b51.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/86438896-04f9-4558-a21a-e7a6d6061591.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c486d95f-f220-435d-8a83-5d9497f04c49.svg#c486d95f-f220-435d-8a83-5d9497f04c49") format("svg");
}

/* New Studio fonts */

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW01-UltLt";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/c0c7b087-4dbd-4c75-8acc-8f4444b2ee1d.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/c0c7b087-4dbd-4c75-8acc-8f4444b2ee1d.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e206f16e-f3ae-45eb-b7a9-c0a42ed7537f.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f4d1bafa-befd-4f3f-ba25-7c0f77c6651e.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/428bc0de-414b-4e68-8f07-4f1a8d3f3aa3.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/a7ab0375-c1d6-4096-8f03-dd2e2d9112f3.svg#a7ab0375-c1d6-4096-8f03-dd2e2d9112f3") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW01-Thin";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/d63f77e2-a23d-496f-bc30-c61c7a689aa6.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/d63f77e2-a23d-496f-bc30-c61c7a689aa6.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/4ff9f3fa-9221-4fc5-97e6-93572b6efa24.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/ca8d25cd-e264-4404-b271-4afc45c779c4.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e8f8260f-dbee-4daa-81a4-0e415715c569.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/307413a4-d77e-4111-8ba5-e3b7d1e7c160.svg#307413a4-d77e-4111-8ba5-e3b7d1e7c160") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW02-UltLt";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/9b3ab352-2ece-4ce7-9be9-69a63ed1d1b4.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/9b3ab352-2ece-4ce7-9be9-69a63ed1d1b4.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e89ceb0a-1747-42a6-bc07-2483a9cf0ce6.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f0b0a1aa-fa3e-4260-a531-014d80d2e73c.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/83c1f195-edf6-493a-aa90-da79c5940a8b.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/8fa7b506-fdc4-452e-8476-0f86510ce434.svg#8fa7b506-fdc4-452e-8476-0f86510ce434") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW02-Thin";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/5acb67e9-77f3-4dc1-a7fd-7a75a9c43813.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/5acb67e9-77f3-4dc1-a7fd-7a75a9c43813.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/18fbcb9a-96e4-4820-8f60-312aadce6d35.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e9722702-4fb8-436a-9342-c5f4f5c3a75d.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/0cadd069-b191-4897-b28a-a7df7a06a582.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/c5621261-cb38-4826-afd2-5408346b6117.svg#c5621261-cb38-4826-afd2-5408346b6117") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW01-45Ligh";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/0be5590f-8353-4ef3-ada1-43ac380859f8.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/82a4e1c2-7ffd-4c58-86fa-4010a6723c8e.svg#82a4e1c2-7ffd-4c58-86fa-4010a6723c8e") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW01-55Roma";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW01-65Medi";
    font-weight: bold;
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f95f9fc2-ffda-431c-9d6a-2c3668f5b20b.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f95f9fc2-ffda-431c-9d6a-2c3668f5b20b.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/5b1fbd62-45dc-4433-a7df-a2b24a146411.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/050b1948-f226-4d20-a65a-15d8ed031222.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/beb88be6-2a60-46fe-914f-6892b3ae46ce.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e5567978-93ef-4de1-804d-1f0e3654a014.svg#e5567978-93ef-4de1-804d-1f0e3654a014") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW01-75Bold";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/419a308d-b777-4f84-9235-2caf4a98ec23.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e4d5b881-6835-45b7-8766-3e5b9e7cab8b.svg#e4d5b881-6835-45b7-8766-3e5b9e7cab8b") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW01-95Blac";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/6492b5a6-2267-40e3-8ede-fd8b730790b1.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/6492b5a6-2267-40e3-8ede-fd8b730790b1.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/bd8486cc-4614-412d-ba1d-15e1a7c9fada.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/a7bdf5a9-752e-4e69-b17c-b7e62575b07a.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e1f8b69f-5d23-44bc-88bf-94778cc978a3.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/22a406f9-eeb2-41eb-ab5e-242ec26f3a88.svg#22a406f9-eeb2-41eb-ab5e-242ec26f3a88") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW02-45Ligh";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/cbd2d238-27b7-4a2a-86f0-e3aa7eed84d7.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/cbd2d238-27b7-4a2a-86f0-e3aa7eed84d7.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f20b86ad-a3dc-4459-92b7-456b456d015a.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/ca003289-5ee3-45c2-94ad-36c743c35fc1.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/7b96672b-19aa-43a4-99af-e572f0fb14e4.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/935314ed-e71e-4128-a648-d81beb5bb29b.svg#935314ed-e71e-4128-a648-d81beb5bb29b") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW02-55Roma";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/eead97e6-2e84-44a8-a166-fbbc667a656e.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/eead97e6-2e84-44a8-a166-fbbc667a656e.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f513faaa-733d-4a87-99ae-d8030f380e3e.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/16ab3c15-5199-4bbf-8269-3ed613e90772.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/41f31931-4ac9-43bf-9298-a5e799a15f21.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f5a20599-4bed-4de8-8b6e-a70ced3e8768.svg#f5a20599-4bed-4de8-8b6e-a70ced3e8768") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW02-65Medi";
    font-weight: bold;
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/12223951-cdd3-4695-baf6-ad6f37ad0080.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/12223951-cdd3-4695-baf6-ad6f37ad0080.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e535ec76-8fc8-4985-94e6-9246a2c6b245.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/67da9da8-4b53-4407-9184-abce69bfc8b5.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/50c055be-3621-4f89-8028-46c57c000b5d.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/9a147a6c-367d-4f79-b937-8f16dbd39293.svg#9a147a6c-367d-4f79-b937-8f16dbd39293") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW02-75Bold";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f9872f03-b366-499d-9b0e-ce508b0a3539.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f9872f03-b366-499d-9b0e-ce508b0a3539.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f350f87a-2ea2-408b-98bb-580827d1a634.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/0552ce48-950c-471f-b843-1afac814d259.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/df2262d6-48cc-478c-aed1-5ce7421b4cf4.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/76aecb4d-a0cb-423c-80b9-ea42c5125a5d.svg#76aecb4d-a0cb-423c-80b9-ea42c5125a5d") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW02-95Blac";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e0430ea1-3b50-4eb5-bd74-4d2cb1d4f88e.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/e0430ea1-3b50-4eb5-bd74-4d2cb1d4f88e.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/573af139-48fd-430d-a41e-c0b8265dc1ee.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/32707dc2-b313-4e90-94fb-f8671b0758ac.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/1de7f38b-fe7a-4bf8-b25b-6fd702cf8d09.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/edc05712-6ad9-4d9f-96cc-7143b5715286.svg#edc05712-6ad9-4d9f-96cc-7143b5715286") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW10-45Ligh";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/2023e7d1-9f0a-4cac-8e80-22321db918dd.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/2023e7d1-9f0a-4cac-8e80-22321db918dd.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/198fd78b-3655-4768-89c4-31caf65ea363.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/49631ce8-9201-47a8-8874-45371417c35f.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/7c8b66a8-a481-4bfd-91b0-d190b68ecb3e.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/9ac3d424-8521-46cf-8878-7324762db7b4.svg#9ac3d424-8521-46cf-8878-7324762db7b4") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW10-65Medi";
    font-weight: bold;
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/06fc373b-1eb1-4f23-bd82-71b38836ae99.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/06fc373b-1eb1-4f23-bd82-71b38836ae99.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/5393f1cf-e069-4466-bb37-f26f99fb4cf7.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/26b8484e-52e3-44ac-b958-865809934ebb.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/f1bb5bea-e76e-4842-9dea-78c029884622.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/055d4d0d-d7ef-4f2b-bf6b-b1c89eb63f47.svg#055d4d0d-d7ef-4f2b-bf6b-b1c89eb63f47") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW10-75Bold";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/3aeaeb1d-1dea-4e9b-9e83-52adc5a87209.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/3aeaeb1d-1dea-4e9b-9e83-52adc5a87209.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/d9122e8d-bf26-4f1c-bab9-c06599397b59.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/db0cec12-a947-40fe-a115-c5ce22ee79f6.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/7d5fadf4-1c20-48ef-9d53-ed17dece64db.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/024411ef-26bf-4536-aea4-ec953846eda7.svg#024411ef-26bf-4536-aea4-ec953846eda7") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW10-95Blac";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/7b0677ad-5980-4978-a282-c466c307e2b2.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/7b0677ad-5980-4978-a282-c466c307e2b2.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/af1fce28-d2e4-49d4-992b-52775f356a0f.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/3e72cddd-a14a-4d07-a597-60d0c65d4a4b.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/2deb5962-c7a7-45ee-8db3-7515ffb46efc.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/bca95cd5-822b-4d16-a51b-d3be57fd5bdd.svg#bca95cd5-822b-4d16-a51b-d3be57fd5bdd") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW10-35Thin";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/2a129142-7c50-4728-9227-1eebf2d9f16d.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/2a129142-7c50-4728-9227-1eebf2d9f16d.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/2192a26c-de1c-4c50-88d1-e5136033c15a.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/0212d203-ce3f-42e2-862a-edf988621728.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/c48fd17b-25da-4fc6-92e5-50a069fb141e.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/552c43a3-6cc7-47b9-8b81-398b961b5167.svg#552c43a3-6cc7-47b9-8b81-398b961b5167") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW10-25UltL";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/be0973b8-9515-4a9f-9e74-ec00fdace91f.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/be0973b8-9515-4a9f-9e74-ec00fdace91f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/691f57f2-18ae-438d-ae65-6285c1fa13b0.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/830aeb20-cda4-4b30-9f05-7e5321980567.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/43a1309d-3e52-4cd5-bafd-2e574c7f3551.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/13705861-a091-45b4-baad-67bb5fccb2b1.svg#13705861-a091-45b4-baad-67bb5fccb2b1") format("svg");
}

@font-face {
    font-display: block;
    font-family:"HelveticaNeueW10-55Roma";
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/8b3b0133-222d-4ffc-9d6e-565dd879690c.eot?#iefix");
    src:url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/8b3b0133-222d-4ffc-9d6e-565dd879690c.eot?#iefix") format("eot"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/1a14dcac-7c9e-471c-8039-33c730f871f2.woff2") format("woff2"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/558ed534-0c60-42cf-8b94-d0a16eb70d37.woff") format("woff"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/c5638ad1-cf7f-4f3b-b944-c3c51c8a3971.ttf") format("truetype"),url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Helvetica/v2/6513e804-e03e-41a1-8385-d5e0971f2009.svg#6513e804-e03e-41a1-8385-d5e0971f2009") format("svg");
}


/*
This CSS resource incorporates links to font software which is
the valuable copyrighted property of Monotype Imaging and/or
its suppliers. You may not attempt to copy, install, redistribute, convert,
modify or reverse engineer this font software. Please contact Monotype Imaging
with any questions regarding Web Fonts:  http://webfonts.fonts.com
*/

/* END fonts helvetica */`)
