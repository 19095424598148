import { BaseDataItem } from '@wix/thunderbolt-becky-types'
import _ from 'lodash'
import { WixReferenceAnalysis } from '@wix/document-services-types'
import { createDataRef } from '../nodeFactory/dataNodeUtils'

export const createRefResolver = (
	refData: WixReferenceAnalysis,
	excludedPathsByDataItemType: { [dataItemType: string]: Array<string> } = {}
) => (dataItem: BaseDataItem, mapName: string, namespace = 'data') => {
	if (!refData[mapName] || !(dataItem.type in refData[mapName])) {
		return null
	}

	const refs = refData[mapName][dataItem.type]
	return refs.reduce((acc, { path, referencedMap }) => {
		const referencedId = _.get(dataItem, path)
		if (!referencedId || excludedPathsByDataItemType[dataItem.type]?.includes(path.join('.'))) {
			return acc
		}

		const value = Array.isArray(referencedId)
			? referencedId.map((id) => createDataRef(namespace, referencedMap, id))
			: createDataRef(namespace, referencedMap, referencedId)

		_.set(acc, path, value)

		return acc
	}, {})
}
