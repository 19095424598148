import type { CompsMeasuresCssFeature } from './compsMeasuresCss.types'
import { compsMeasuresCssDomApplier } from './compsMeasuresCssDomApplier'
import { compsMeasures } from './compsMeasuresCssCompNode'
import { placeholder } from './placeholderCompNode'

export const compsMeasuresCssFeature: CompsMeasuresCssFeature = {
	experimentName: 'specs.thunderbolt.compsMeasuresCss_catharsis',
	renderableNodes: { compsMeasures, placeholder },
	intermediateNodes: {},
	dataMaps: ['component_properties', 'document_data'],
	domApplier: compsMeasuresCssDomApplier,
}
