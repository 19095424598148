import { parseFontFaces } from './fontsParser'

export const googleFonts = parseFontFaces(`/* cyrillic */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 400;
src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZyzwprpvBS1izr_vOEDuSfU5cP1V3r.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 400;
src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZyzwprpvBS1izr_vOECOSfU5cP1V3r.woff2") format('woff2');
unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 400;
src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZyzwprpvBS1izr_vOEBeSfU5cP1V3r.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 400;
src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZyzwprpvBS1izr_vOEBOSfU5cP1V3r.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 400;
src: local('Amatic SC Regular'), local('AmaticSC-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZyzwprpvBS1izr_vOECuSfU5cP1Q.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 700;
src: local('Amatic SC Bold'), local('AmaticSC-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZ3zwprpvBS1izr_vOMscGKerUC_2fi-Vl4.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 700;
src: local('Amatic SC Bold'), local('AmaticSC-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZ3zwprpvBS1izr_vOMscGKfLUC_2fi-Vl4.woff2") format('woff2');
unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 700;
src: local('Amatic SC Bold'), local('AmaticSC-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZ3zwprpvBS1izr_vOMscGKcbUC_2fi-Vl4.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 700;
src: local('Amatic SC Bold'), local('AmaticSC-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZ3zwprpvBS1izr_vOMscGKcLUC_2fi-Vl4.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Amatic SC';
font-style: normal;
font-weight: 700;
src: local('Amatic SC Bold'), local('AmaticSC-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Amatic_Sc/v13/TUZ3zwprpvBS1izr_vOMscGKfrUC_2fi-Q.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Anton';
font-style: normal;
font-weight: 400;
src: local('Anton Regular'), local('Anton-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Anton/v11/1Ptgg87LROyAm3K8-C8CSKlvPfE.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Anton';
font-style: normal;
font-weight: 400;
src: local('Anton Regular'), local('Anton-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Anton/v11/1Ptgg87LROyAm3K9-C8CSKlvPfE.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Anton';
font-style: normal;
font-weight: 400;
src: local('Anton Regular'), local('Anton-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Anton/v11/1Ptgg87LROyAm3Kz-C8CSKlv.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: italic;
font-weight: 400;
src: local('Barlow Italic'), local('Barlow-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHrv4kjgoGqM7E_Cfs0wH8DnzcjTrA.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: italic;
font-weight: 400;
src: local('Barlow Italic'), local('Barlow-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHrv4kjgoGqM7E_Cfs1wH8DnzcjTrA.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: italic;
font-weight: 400;
src: local('Barlow Italic'), local('Barlow-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHrv4kjgoGqM7E_Cfs7wH8Dnzcj.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: italic;
font-weight: 700;
src: local('Barlow Bold Italic'), local('Barlow-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHsv4kjgoGqM7E_CfOA5WohvToJdLm8BvE.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: italic;
font-weight: 700;
src: local('Barlow Bold Italic'), local('Barlow-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHsv4kjgoGqM7E_CfOA5WogvToJdLm8BvE.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: italic;
font-weight: 700;
src: local('Barlow Bold Italic'), local('Barlow-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHsv4kjgoGqM7E_CfOA5WouvToJdLm8.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: normal;
font-weight: 400;
src: local('Barlow Regular'), local('Barlow-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHpv4kjgoGqM7E_A8s5ynghnQci.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: normal;
font-weight: 400;
src: local('Barlow Regular'), local('Barlow-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHpv4kjgoGqM7E_Ass5ynghnQci.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: normal;
font-weight: 400;
src: local('Barlow Regular'), local('Barlow-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHpv4kjgoGqM7E_DMs5ynghnQ.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: normal;
font-weight: 700;
src: local('Barlow Bold'), local('Barlow-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHqv4kjgoGqM7E3t-4s6Fostz0rdom9.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: normal;
font-weight: 700;
src: local('Barlow Bold'), local('Barlow-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHqv4kjgoGqM7E3t-4s6Vostz0rdom9.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Barlow';
font-style: normal;
font-weight: 700;
src: local('Barlow Bold'), local('Barlow-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow/v4/7cHqv4kjgoGqM7E3t-4s51ostz0rdg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Basic';
font-style: normal;
font-weight: 400;
src: local('Basic Regular'), local('Basic-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Basic/v9/xfu_0WLxV2_XKTN-6FH3yg7ChL4.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Basic';
font-style: normal;
font-weight: 400;
src: local('Basic Regular'), local('Basic-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Basic/v9/xfu_0WLxV2_XKTNw6FH3yg7C.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: italic;
font-weight: 400;
src: local('Caudex Italic'), local('Caudex-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDS311QOP6BJUr4yMKPtbosEJimIMs.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: italic;
font-weight: 400;
src: local('Caudex Italic'), local('Caudex-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDS311QOP6BJUr4yMKAtbosEJimIMs.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: italic;
font-weight: 400;
src: local('Caudex Italic'), local('Caudex-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDS311QOP6BJUr4yMKNtbosEJimIMs.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: italic;
font-weight: 400;
src: local('Caudex Italic'), local('Caudex-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDS311QOP6BJUr4yMKDtbosEJim.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: italic;
font-weight: 700;
src: local('Caudex Bold Italic'), local('Caudex-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDV311QOP6BJUr4yMo4kK8NMpWMGsLAjdA.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: italic;
font-weight: 700;
src: local('Caudex Bold Italic'), local('Caudex-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDV311QOP6BJUr4yMo4kK8CMpWMGsLAjdA.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: italic;
font-weight: 700;
src: local('Caudex Bold Italic'), local('Caudex-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDV311QOP6BJUr4yMo4kK8PMpWMGsLAjdA.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: italic;
font-weight: 700;
src: local('Caudex Bold Italic'), local('Caudex-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDV311QOP6BJUr4yMo4kK8BMpWMGsLA.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: normal;
font-weight: 400;
src: local('Caudex'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDQ311QOP6BJUr4wfKBv70OEqin.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: normal;
font-weight: 400;
src: local('Caudex'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDQ311QOP6BJUr4zvKBv70OEqin.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: normal;
font-weight: 400;
src: local('Caudex'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDQ311QOP6BJUr4w_KBv70OEqin.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: normal;
font-weight: 400;
src: local('Caudex'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDQ311QOP6BJUr4zfKBv70OEg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: normal;
font-weight: 700;
src: local('Caudex Bold'), local('Caudex-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDT311QOP6BJUrwdteUnp8DOJKuGPLB.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: normal;
font-weight: 700;
src: local('Caudex Bold'), local('Caudex-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDT311QOP6BJUrwdteUkZ8DOJKuGPLB.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: normal;
font-weight: 700;
src: local('Caudex Bold'), local('Caudex-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDT311QOP6BJUrwdteUnJ8DOJKuGPLB.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Caudex';
font-style: normal;
font-weight: 700;
src: local('Caudex Bold'), local('Caudex-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caudex/v9/esDT311QOP6BJUrwdteUkp8DOJKuGA.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Chelsea Market';
font-style: normal;
font-weight: 400;
src: local('Chelsea Market'), local('ChelseaMarket-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Chelsea_Market/v7/BCawqZsHqfr89WNP_IApC8tzKChsJg8MKVWl4lw.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Chelsea Market';
font-style: normal;
font-weight: 400;
src: local('Chelsea Market'), local('ChelseaMarket-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Chelsea_Market/v7/BCawqZsHqfr89WNP_IApC8tzKChiJg8MKVWl.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Cinzel';
font-style: normal;
font-weight: 400;
src: local('Cinzel Regular'), local('Cinzel-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cinzel/v9/8vIJ7ww63mVu7gt7-GT7PkRXM8Xx.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Cinzel';
font-style: normal;
font-weight: 400;
src: local('Cinzel Regular'), local('Cinzel-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cinzel/v9/8vIJ7ww63mVu7gt79mT7PkRXMw.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Cinzel';
font-style: normal;
font-weight: 700;
src: local('Cinzel Bold'), local('Cinzel-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cinzel/v9/8vIK7ww63mVu7gtzTUHuHWZaGf_4-ial.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Cinzel';
font-style: normal;
font-weight: 700;
src: local('Cinzel Bold'), local('Cinzel-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cinzel/v9/8vIK7ww63mVu7gtzTUHuE2ZaGf_4-g.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Cookie';
font-style: normal;
font-weight: 400;
src: local('Cookie-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cookie/v11/syky-y18lb0tSbf9kgqS1NDNpg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Corben';
font-style: normal;
font-weight: 400;
src: local('Corben Regular'), local('Corben-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Corben/v13/LYjDdGzzklQtCMpNqQNFh1gnVOfe.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Corben';
font-style: normal;
font-weight: 400;
src: local('Corben Regular'), local('Corben-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Corben/v13/LYjDdGzzklQtCMpNpwNFh1gnVA.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Corben';
font-style: normal;
font-weight: 700;
src: local('Corben'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Corben/v13/LYjAdGzzklQtCMpFHCZQpHoqft3XWKyz.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Corben';
font-style: normal;
font-weight: 700;
src: local('Corben'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Corben/v13/LYjAdGzzklQtCMpFHCZQqnoqft3XWA.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 400;
src: local('Cormorant Garamond Italic'), local('CormorantGaramond-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrEtFmSqn7B6DxjY.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 400;
src: local('Cormorant Garamond Italic'), local('CormorantGaramond-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrEtMmSqn7B6DxjY.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 400;
src: local('Cormorant Garamond Italic'), local('CormorantGaramond-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrEtHmSqn7B6DxjY.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 400;
src: local('Cormorant Garamond Italic'), local('CormorantGaramond-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrEtGmSqn7B6DxjY.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 400;
src: local('Cormorant Garamond Italic'), local('CormorantGaramond-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrEtImSqn7B6D.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 700;
src: local('Cormorant Garamond Bold Italic'), local('CormorantGaramond-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvD-HzhOp_D95AnI.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 700;
src: local('Cormorant Garamond Bold Italic'), local('CormorantGaramond-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvD-OzhOp_D95AnI.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 700;
src: local('Cormorant Garamond Bold Italic'), local('CormorantGaramond-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvD-FzhOp_D95AnI.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 700;
src: local('Cormorant Garamond Bold Italic'), local('CormorantGaramond-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvD-EzhOp_D95AnI.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: italic;
font-weight: 700;
src: local('Cormorant Garamond Bold Italic'), local('CormorantGaramond-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvD-KzhOp_D95.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 400;
src: local('Cormorant Garamond Regular'), local('CormorantGaramond-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3bmX5slCNuHLi8bLeY9MK7whWMhyjYpHtKky2F7i6C.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 400;
src: local('Cormorant Garamond Regular'), local('CormorantGaramond-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3bmX5slCNuHLi8bLeY9MK7whWMhyjYrXtKky2F7i6C.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 400;
src: local('Cormorant Garamond Regular'), local('CormorantGaramond-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3bmX5slCNuHLi8bLeY9MK7whWMhyjYpntKky2F7i6C.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 400;
src: local('Cormorant Garamond Regular'), local('CormorantGaramond-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3bmX5slCNuHLi8bLeY9MK7whWMhyjYp3tKky2F7i6C.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 400;
src: local('Cormorant Garamond Regular'), local('CormorantGaramond-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3bmX5slCNuHLi8bLeY9MK7whWMhyjYqXtKky2F7g.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 700;
src: local('Cormorant Garamond Bold'), local('CormorantGaramond-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5fsw-IxBSL_g94.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 700;
src: local('Cormorant Garamond Bold'), local('CormorantGaramond-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5fug-IxBSL_g94.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 700;
src: local('Cormorant Garamond Bold'), local('CormorantGaramond-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5fsQ-IxBSL_g94.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 700;
src: local('Cormorant Garamond Bold'), local('CormorantGaramond-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5fsA-IxBSL_g94.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Cormorant Garamond';
font-style: normal;
font-weight: 700;
src: local('Cormorant Garamond Bold'), local('CormorantGaramond-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorant_Garamond/v7/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5fvg-IxBSL_g.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Damion';
font-style: normal;
font-weight: 400;
src: local('Damion'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Damion/v9/hv-XlzJ3KEUe_YZkamw2EzJwVw.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Dancing Script';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancing_Script/v13/If2RXTr6YS-zF4S-kcSWSVi_szLviuEHiC4Wl-8.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Dancing Script';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancing_Script/v13/If2RXTr6YS-zF4S-kcSWSVi_szLuiuEHiC4Wl-8.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Dancing Script';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancing_Script/v13/If2RXTr6YS-zF4S-kcSWSVi_szLgiuEHiC4W.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Dancing Script';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancing_Script/v13/If2RXTr6YS-zF4S-kcSWSVi_szLviuEHiC4Wl-8.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Dancing Script';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancing_Script/v13/If2RXTr6YS-zF4S-kcSWSVi_szLuiuEHiC4Wl-8.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Dancing Script';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancing_Script/v13/If2RXTr6YS-zF4S-kcSWSVi_szLgiuEHiC4W.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDY1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweD81ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDc1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDg1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDQ1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDU1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDs1ZyHKpWg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDY1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweD81ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDc1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDg1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDQ1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDU1ZyHKpWiGIg.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: italic;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGWmQSNjdsmc35JDF1K5GRweDs1ZyHKpWg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR4SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GRxSDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR5SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR2SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR6SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR7SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR1SDk_YAPI.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR4SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GRxSDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR5SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR2SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR6SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR7SDk_YAPIlWk.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'EB Garamond';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Eb_Garamond/v12/SlGUmQSNjdsmc35JDF1K5GR1SDk_YAPI.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Enriqueta';
font-style: normal;
font-weight: 400;
src: local('Enriqueta Regular'), local('Enriqueta-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Enriqueta/v9/goksH6L7AUFrRvV44HVjQkqisv5Io53K.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Enriqueta';
font-style: normal;
font-weight: 400;
src: local('Enriqueta Regular'), local('Enriqueta-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Enriqueta/v9/goksH6L7AUFrRvV44HVjTEqisv5Iow.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Enriqueta';
font-style: normal;
font-weight: 700;
src: local('Enriqueta Bold'), local('Enriqueta-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Enriqueta/v9/gokpH6L7AUFrRvV44HVr92-3kdxFiafDFtAi.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Enriqueta';
font-style: normal;
font-weight: 700;
src: local('Enriqueta Bold'), local('Enriqueta-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Enriqueta/v9/gokpH6L7AUFrRvV44HVr92-3n9xFiafDFg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Forum';
font-style: normal;
font-weight: 400;
src: local('Forum'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Forum/v10/6aey4Ky-Vb8Ew8IcOpI43XnSBTM.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Forum';
font-style: normal;
font-weight: 400;
src: local('Forum'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Forum/v10/6aey4Ky-Vb8Ew8IVOpI43XnSBTM.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Forum';
font-style: normal;
font-weight: 400;
src: local('Forum'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Forum/v10/6aey4Ky-Vb8Ew8IfOpI43XnSBTM.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Forum';
font-style: normal;
font-weight: 400;
src: local('Forum'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Forum/v10/6aey4Ky-Vb8Ew8IROpI43XnS.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Fredericka the Great';
font-style: normal;
font-weight: 400;
src: local('Fredericka the Great'), local('FrederickatheGreat'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fredericka_The_Great/v9/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV--StxbcHcQ2KVFw.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Fredericka the Great';
font-style: normal;
font-weight: 400;
src: local('Fredericka the Great'), local('FrederickatheGreat'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fredericka_The_Great/v9/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV--SjxbcHcQ2K.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Jockey One';
font-style: normal;
font-weight: 400;
src: local('Jockey One'), local('JockeyOne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jockey_One/v9/HTxpL2g2KjCFj4x8WI6AnI_xHKGn8xbL3g.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Jockey One';
font-style: normal;
font-weight: 400;
src: local('Jockey One'), local('JockeyOne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jockey_One/v9/HTxpL2g2KjCFj4x8WI6AnIHxHKGn8xY.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Josefin Slab';
font-style: italic;
font-weight: 400;
src: local('Josefin Slab Italic'), local('JosefinSlab-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Josefin_Slab/v10/lW-nwjwOK3Ps5GSJlNNkMalnrz6tDs_KPAMW.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Josefin Slab';
font-style: italic;
font-weight: 700;
src: local('Josefin Slab Bold Italic'), local('JosefinSlab-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Josefin_Slab/v10/lW-kwjwOK3Ps5GSJlNNkMalnrzYWK9rnHg487Znv.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Josefin Slab';
font-style: normal;
font-weight: 400;
src: local('Josefin Slab Regular'), local('JosefinSlab-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Josefin_Slab/v10/lW-5wjwOK3Ps5GSJlNNkMalnqg6vBMjoPg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Josefin Slab';
font-style: normal;
font-weight: 700;
src: local('Josefin Slab Bold'), local('JosefinSlab-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Josefin_Slab/v10/lW-mwjwOK3Ps5GSJlNNkMalvESu6KerlFAke7w.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXclJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXcBJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXchJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXcdJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXctJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXcpJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXcRJUQZA3Jw.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXclJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXcBJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXchJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXcdJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXctJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXcpJUQZA3JzsTQ.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Jura';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Jura/v13/z7NbdRfiaC4VXcRJUQZA3Jw.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Kelly Slab';
font-style: normal;
font-weight: 400;
src: local('Kelly Slab'), local('KellySlab-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kelly_Slab/v10/-W_7XJX0Rz3cxUnJC5t6fkALfr8nfiIJ4w.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Kelly Slab';
font-style: normal;
font-weight: 400;
src: local('Kelly Slab'), local('KellySlab-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kelly_Slab/v10/-W_7XJX0Rz3cxUnJC5t6fkoLfr8nfiIJ4w.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Kelly Slab';
font-style: normal;
font-weight: 400;
src: local('Kelly Slab'), local('KellySlab-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kelly_Slab/v10/-W_7XJX0Rz3cxUnJC5t6fkQLfr8nfiI.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Lato';
font-style: italic;
font-weight: 400;
src: local('Lato Italic'), local('Lato-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato/v16/S6u8w4BMUTPHjxsAUi-qNiXg7eU0.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Lato';
font-style: italic;
font-weight: 400;
src: local('Lato Italic'), local('Lato-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato/v16/S6u8w4BMUTPHjxsAXC-qNiXg7Q.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Lato';
font-style: italic;
font-weight: 700;
src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato/v16/S6u_w4BMUTPHjxsI5wq_FQftx9897sxZ.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Lato';
font-style: italic;
font-weight: 700;
src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato/v16/S6u_w4BMUTPHjxsI5wq_Gwftx9897g.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Lato';
font-style: normal;
font-weight: 400;
src: local('Lato Regular'), local('Lato-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato/v16/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Lato';
font-style: normal;
font-weight: 400;
src: local('Lato Regular'), local('Lato-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Lato';
font-style: normal;
font-weight: 700;
src: local('Lato Bold'), local('Lato-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato/v16/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Lato';
font-style: normal;
font-weight: 700;
src: local('Lato Bold'), local('Lato-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Libre Baskerville';
font-style: italic;
font-weight: 400;
src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Libre_Baskerville/v7/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcWx8QDO-WyrubOA.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Libre Baskerville';
font-style: italic;
font-weight: 400;
src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Libre_Baskerville/v7/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcWxEQDO-Wyrs.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Libre Baskerville';
font-style: normal;
font-weight: 400;
src: local('Libre Baskerville'), local('LibreBaskerville-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Libre_Baskerville/v7/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNXaxMaC82U-ro.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Libre Baskerville';
font-style: normal;
font-weight: 400;
src: local('Libre Baskerville'), local('LibreBaskerville-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Libre_Baskerville/v7/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNZaxMaC82U.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Libre Baskerville';
font-style: normal;
font-weight: 700;
src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Libre_Baskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY5KcC-wLOjAUw.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Libre Baskerville';
font-style: normal;
font-weight: 700;
src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Libre_Baskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY3KcC-wLOj.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Lobster';
font-style: normal;
font-weight: 400;
src: local('Lobster Regular'), local('Lobster-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lobster/v22/neILzCirqoswsqX9zo-mM4MwWJXNqA.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Lobster';
font-style: normal;
font-weight: 400;
src: local('Lobster Regular'), local('Lobster-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lobster/v22/neILzCirqoswsqX9zoamM4MwWJXNqA.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Lobster';
font-style: normal;
font-weight: 400;
src: local('Lobster Regular'), local('Lobster-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lobster/v22/neILzCirqoswsqX9zo2mM4MwWJXNqA.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Lobster';
font-style: normal;
font-weight: 400;
src: local('Lobster Regular'), local('Lobster-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lobster/v22/neILzCirqoswsqX9zoymM4MwWJXNqA.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Lobster';
font-style: normal;
font-weight: 400;
src: local('Lobster Regular'), local('Lobster-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lobster/v22/neILzCirqoswsqX9zoKmM4MwWJU.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Marck Script';
font-style: normal;
font-weight: 400;
src: local('Marck Script'), local('MarckScript-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Marck_Script/v10/nwpTtK2oNgBA3Or78gapdwuyzCg_SsDV7E98.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Marck Script';
font-style: normal;
font-weight: 400;
src: local('Marck Script'), local('MarckScript-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Marck_Script/v10/nwpTtK2oNgBA3Or78gapdwuyxig_SsDV7E98.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Marck Script';
font-style: normal;
font-weight: 400;
src: local('Marck Script'), local('MarckScript-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Marck_Script/v10/nwpTtK2oNgBA3Or78gapdwuyyCg_SsDV7A.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Monoton';
font-style: normal;
font-weight: 400;
src: local('Monoton'), local('Monoton-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Monoton/v9/5h1aiZUrOngCibe4TkHLQka4BU4.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 400;
src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 400;
src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 400;
src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 400;
src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 400;
src: local('Montserrat Italic'), local('Montserrat-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 700;
src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8fZwjimrq1Q_.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 700;
src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz-PZwjimrq1Q_.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 700;
src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8_Zwjimrq1Q_.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 700;
src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8vZwjimrq1Q_.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: italic;
font-weight: 700;
src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz_PZwjimrqw.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
src: local('Montserrat Regular'), local('Montserrat-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gTD_vx3rCubqg.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3g3D_vx3rCubqg.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gbD_vx3rCubqg.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD_vx3rCubqg.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
src: local('Montserrat Bold'), local('Montserrat-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Mr De Haviland';
font-style: normal;
font-weight: 400;
src: local('Mr De Haviland Regular'), local('MrDeHaviland-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mr_De_Haviland/v8/OpNVnooIhJj96FdB73296ksbOg3L60P3NilAZTs.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Mr De Haviland';
font-style: normal;
font-weight: 400;
src: local('Mr De Haviland Regular'), local('MrDeHaviland-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mr_De_Haviland/v8/OpNVnooIhJj96FdB73296ksbOg3F60P3NilA.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Niconne';
font-style: normal;
font-weight: 400;
src: local('Niconne'), local('Niconne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Niconne/v9/w8gaH2QvRug1_rTfnQKn2XwNqOuyBg.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Niconne';
font-style: normal;
font-weight: 400;
src: local('Niconne'), local('Niconne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Niconne/v9/w8gaH2QvRug1_rTfnQyn2XwNqOs.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: italic;
font-weight: 400;
src: local('Noticia Text Italic'), local('NoticiaText-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJodNDF2Yv9qppOePKYRP12YwtVn07_pjjsQdA.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: italic;
font-weight: 400;
src: local('Noticia Text Italic'), local('NoticiaText-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJodNDF2Yv9qppOePKYRP12YwtUn07_pjjsQdA.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: italic;
font-weight: 400;
src: local('Noticia Text Italic'), local('NoticiaText-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJodNDF2Yv9qppOePKYRP12Ywtan07_pjjs.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: italic;
font-weight: 700;
src: local('Noticia Text Bold Italic'), local('NoticiaText-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJrdNDF2Yv9qppOePKYRP12YwPhulvdhDXGe9nyfeU.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: italic;
font-weight: 700;
src: local('Noticia Text Bold Italic'), local('NoticiaText-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJrdNDF2Yv9qppOePKYRP12YwPhulvchDXGe9nyfeU.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: italic;
font-weight: 700;
src: local('Noticia Text Bold Italic'), local('NoticiaText-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJrdNDF2Yv9qppOePKYRP12YwPhulvShDXGe9ny.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: normal;
font-weight: 400;
src: local('Noticia Text'), local('NoticiaText-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJ2dNDF2Yv9qppOePKYRP12aTtYlUndpAjt.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: normal;
font-weight: 400;
src: local('Noticia Text'), local('NoticiaText-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJ2dNDF2Yv9qppOePKYRP12aDtYlUndpAjt.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: normal;
font-weight: 400;
src: local('Noticia Text'), local('NoticiaText-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJ2dNDF2Yv9qppOePKYRP12ZjtYlUndpA.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: normal;
font-weight: 700;
src: local('Noticia Text Bold'), local('NoticiaText-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJpdNDF2Yv9qppOePKYRP1-3R5Nt2vQjjLkeenz.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: normal;
font-weight: 700;
src: local('Noticia Text Bold'), local('NoticiaText-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJpdNDF2Yv9qppOePKYRP1-3R5NtmvQjjLkeenz.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Noticia Text';
font-style: normal;
font-weight: 700;
src: local('Noticia Text Bold'), local('NoticiaText-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Noticia_Text/v9/VuJpdNDF2Yv9qppOePKYRP1-3R5NuGvQjjLkeQ.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 300;
src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDujMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 300;
src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuHMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 300;
src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDunMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 300;
src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDubMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 300;
src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDurMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 300;
src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuvMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 300;
src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuXMR7eS2Ao.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 700;
src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDujMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 700;
src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuHMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 700;
src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDunMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 700;
src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDubMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 700;
src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDurMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 700;
src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuvMR7eS2AopSg.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Open Sans Condensed';
font-style: normal;
font-weight: 700;
src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Open_Sans_Condensed/v14/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuXMR7eS2Ao.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752FD8Gl-1PK62t.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752HT8Gl-1PK62t.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752Fj8Gl-1PK62t.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752Fz8Gl-1PK62t.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 400;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752FD8Gl-1PK62t.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752HT8Gl-1PK62t.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752Fj8Gl-1PK62t.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752Fz8Gl-1PK62t.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Oswald';
font-style: normal;
font-weight: 700;
src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald/v29/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Overlock';
font-style: italic;
font-weight: 400;
src: local('Overlock Italic'), local('Overlock-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Overlock/v9/Z9XTDmdMWRiN1_T9Z7Tc2OCsk5OB_CrqjQ.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Overlock';
font-style: italic;
font-weight: 400;
src: local('Overlock Italic'), local('Overlock-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Overlock/v9/Z9XTDmdMWRiN1_T9Z7Tc2O6sk5OB_Co.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Overlock';
font-style: italic;
font-weight: 700;
src: local('Overlock Bold Italic'), local('Overlock-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Overlock/v9/Z9XQDmdMWRiN1_T9Z7Tc0FWJhrCj8QDQhNpHvg.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Overlock';
font-style: italic;
font-weight: 700;
src: local('Overlock Bold Italic'), local('Overlock-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Overlock/v9/Z9XQDmdMWRiN1_T9Z7Tc0FWJhr6j8QDQhNo.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Overlock';
font-style: normal;
font-weight: 400;
src: local('Overlock Regular'), local('Overlock-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Overlock/v9/Z9XVDmdMWRiN1_T9Z7TX6OymlLGDzCs.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Overlock';
font-style: normal;
font-weight: 400;
src: local('Overlock Regular'), local('Overlock-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Overlock/v9/Z9XVDmdMWRiN1_T9Z7TZ6OymlLGD.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Overlock';
font-style: normal;
font-weight: 700;
src: local('Overlock Bold'), local('Overlock-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Overlock/v9/Z9XSDmdMWRiN1_T9Z7xizfmFtryp9iLStNs.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Overlock';
font-style: normal;
font-weight: 700;
src: local('Overlock Bold'), local('Overlock-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Overlock/v9/Z9XSDmdMWRiN1_T9Z7xizfmLtryp9iLS.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Patrick Hand';
font-style: normal;
font-weight: 400;
src: local('Patrick Hand'), local('PatrickHand-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Patrick_Hand/v13/LDI1apSQOAYtSuYWp8ZhfYe8UcLLubg58xGL.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Patrick Hand';
font-style: normal;
font-weight: 400;
src: local('Patrick Hand'), local('PatrickHand-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Patrick_Hand/v13/LDI1apSQOAYtSuYWp8ZhfYe8UMLLubg58xGL.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Patrick Hand';
font-style: normal;
font-weight: 400;
src: local('Patrick Hand'), local('PatrickHand-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Patrick_Hand/v13/LDI1apSQOAYtSuYWp8ZhfYe8XsLLubg58w.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 400;
src: local('Play Regular'), local('Play-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6aez4K2oVqwIvtg2H70Q13HqPA.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 400;
src: local('Play Regular'), local('Play-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6aez4K2oVqwIvtE2H70Q13HqPA.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 400;
src: local('Play Regular'), local('Play-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6aez4K2oVqwIvtY2H70Q13HqPA.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 400;
src: local('Play Regular'), local('Play-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6aez4K2oVqwIvto2H70Q13HqPA.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 400;
src: local('Play Regular'), local('Play-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6aez4K2oVqwIvts2H70Q13HqPA.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 400;
src: local('Play Regular'), local('Play-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6aez4K2oVqwIvtU2H70Q13E.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 700;
src: local('Play Bold'), local('Play-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6ae84K2oVqwItm4TCp0y2lvQNTIQoQ.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 700;
src: local('Play Bold'), local('Play-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6ae84K2oVqwItm4TCpQy2lvQNTIQoQ.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 700;
src: local('Play Bold'), local('Play-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6ae84K2oVqwItm4TCpMy2lvQNTIQoQ.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 700;
src: local('Play Bold'), local('Play-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6ae84K2oVqwItm4TCp8y2lvQNTIQoQ.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 700;
src: local('Play Bold'), local('Play-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6ae84K2oVqwItm4TCp4y2lvQNTIQoQ.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Play';
font-style: normal;
font-weight: 700;
src: local('Play Bold'), local('Play-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Play/v11/6ae84K2oVqwItm4TCpAy2lvQNTI.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: italic;
font-weight: 400;
src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFkD-vYSZviVYUb_rj3ij__anPXDTnohkk7yRZrPJ-M.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: italic;
font-weight: 400;
src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFkD-vYSZviVYUb_rj3ij__anPXDTnojUk7yRZrPJ-M.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: italic;
font-weight: 400;
src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFkD-vYSZviVYUb_rj3ij__anPXDTnojEk7yRZrPJ-M.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: italic;
font-weight: 400;
src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFkD-vYSZviVYUb_rj3ij__anPXDTnogkk7yRZrPA.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: italic;
font-weight: 700;
src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFnD-vYSZviVYUb_rj3ij__anPXDTngOWwu4DRmFqWF_ljR.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: italic;
font-weight: 700;
src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFnD-vYSZviVYUb_rj3ij__anPXDTngOWwu6zRmFqWF_ljR.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: italic;
font-weight: 700;
src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFnD-vYSZviVYUb_rj3ij__anPXDTngOWwu6jRmFqWF_ljR.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: italic;
font-weight: 700;
src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFnD-vYSZviVYUb_rj3ij__anPXDTngOWwu5DRmFqWF_g.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: normal;
font-weight: 400;
src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFiD-vYSZviVYUb_rj3ij__anPXDTjYgEM86xRbPQ.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: normal;
font-weight: 400;
src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFiD-vYSZviVYUb_rj3ij__anPXDTPYgEM86xRbPQ.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: normal;
font-weight: 400;
src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFiD-vYSZviVYUb_rj3ij__anPXDTLYgEM86xRbPQ.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: normal;
font-weight: 400;
src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFiD-vYSZviVYUb_rj3ij__anPXDTzYgEM86xQ.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFlD-vYSZviVYUb_rj3ij__anPXBYf9lWoe5j5hNKe1_w.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFlD-vYSZviVYUb_rj3ij__anPXBYf9lWEe5j5hNKe1_w.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFlD-vYSZviVYUb_rj3ij__anPXBYf9lWAe5j5hNKe1_w.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfair_Display/v18/nuFlD-vYSZviVYUb_rj3ij__anPXBYf9lW4e5j5hNKc.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: italic;
font-weight: 400;
src: local('Poppins Italic'), local('Poppins-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiGyp8kv8JHgFVrJJLucXtAOvWDSHFF.woff2") format('woff2');
unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: italic;
font-weight: 400;
src: local('Poppins Italic'), local('Poppins-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiGyp8kv8JHgFVrJJLufntAOvWDSHFF.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: italic;
font-weight: 400;
src: local('Poppins Italic'), local('Poppins-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiGyp8kv8JHgFVrJJLucHtAOvWDSA.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: italic;
font-weight: 700;
src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiDyp8kv8JHgFVrJJLmy15VFteOYktMqlap.woff2") format('woff2');
unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: italic;
font-weight: 700;
src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiDyp8kv8JHgFVrJJLmy15VGdeOYktMqlap.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: italic;
font-weight: 700;
src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiDyp8kv8JHgFVrJJLmy15VF9eOYktMqg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
src: local('Poppins Regular'), local('Poppins-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format('woff2');
unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
src: local('Poppins Regular'), local('Poppins-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
src: local('Poppins Regular'), local('Poppins-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
src: local('Poppins Bold'), local('Poppins-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2") format('woff2');
unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
src: local('Poppins Bold'), local('Poppins-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
src: local('Poppins Bold'), local('Poppins-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins/v9/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Questrial';
font-style: normal;
font-weight: 400;
src: local('Questrial'), local('Questrial-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Questrial/v9/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 400;
src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xFIzIXKMnyrYk.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 400;
src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xMIzIXKMnyrYk.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 400;
src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xEIzIXKMnyrYk.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 400;
src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xLIzIXKMnyrYk.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 400;
src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xHIzIXKMnyrYk.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 400;
src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xGIzIXKMnyrYk.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 400;
src: local('Roboto Italic'), local('Roboto-Italic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOkCnqEu92Fr1Mu51xIIzIXKMny.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 700;
src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic3CsTYl4BOQ3o.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 700;
src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic-CsTYl4BOQ3o.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 700;
src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic2CsTYl4BOQ3o.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 700;
src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic5CsTYl4BOQ3o.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 700;
src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic1CsTYl4BOQ3o.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 700;
src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic0CsTYl4BOQ3o.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: italic;
font-weight: 700;
src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOjCnqEu92Fr1Mu51TzBic6CsTYl4BO.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: local('Roboto'), local('Roboto-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2") format('woff2');
unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2") format('woff2');
unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2") format('woff2');
unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2") format('woff2');
unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2") format('woff2');
unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
src: local('Roboto Bold'), local('Roboto-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
font-display: block;
font-family: 'Rozha One';
font-style: normal;
font-weight: 400;
src: local('Rozha One Regular'), local('RozhaOne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rozha_One/v7/AlZy_zVFtYP12Zncg2kRc335fh4DolH3.woff2") format('woff2');
unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Rozha One';
font-style: normal;
font-weight: 400;
src: local('Rozha One Regular'), local('RozhaOne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rozha_One/v7/AlZy_zVFtYP12Zncg2kRfH35fh4DolH3.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Rozha One';
font-style: normal;
font-weight: 400;
src: local('Rozha One Regular'), local('RozhaOne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rozha_One/v7/AlZy_zVFtYP12Zncg2kRcn35fh4Dog.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Sacramento';
font-style: normal;
font-weight: 400;
src: local('Sacramento'), local('Sacramento-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sacramento/v7/buEzpo6gcdjy0EiZMBUG4CMf_f5Iai0Ycw.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Sacramento';
font-style: normal;
font-weight: 400;
src: local('Sacramento'), local('Sacramento-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sacramento/v7/buEzpo6gcdjy0EiZMBUG4C0f_f5Iai0.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Sail';
font-style: normal;
font-weight: 400;
src: local('Sail'), local('Sail-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sail/v10/DPEjYwiBxwYJJB3JARQDfv3jsQ.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Sail';
font-style: normal;
font-weight: 400;
src: local('Sail'), local('Sail-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sail/v10/DPEjYwiBxwYJJBPJARQDfv0.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Sarina';
font-style: normal;
font-weight: 400;
src: local('Sarina'), local('Sarina-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarina/v8/-F6wfjF3ITQwasLRJ0rVjCNAxhAL.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Sarina';
font-style: normal;
font-weight: 400;
src: local('Sarina'), local('Sarina-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarina/v8/-F6wfjF3ITQwasLRKUrVjCNAxg.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Signika';
font-style: normal;
font-weight: 400;
src: local('Signika Regular'), local('Signika-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Signika/v10/vEFR2_JTCgwQ5ejvG18mBlprZ0gk0w.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Signika';
font-style: normal;
font-weight: 400;
src: local('Signika Regular'), local('Signika-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Signika/v10/vEFR2_JTCgwQ5ejvG1EmBlprZ0g.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Signika';
font-style: normal;
font-weight: 700;
src: local('Signika Bold'), local('Signika-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Signika/v10/vEFU2_JTCgwQ5ejvE-oDE3lJamIe2tKnXg.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Signika';
font-style: normal;
font-weight: 700;
src: local('Signika Bold'), local('Signika-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Signika/v10/vEFU2_JTCgwQ5ejvE-oDE3dJamIe2tI.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Spinnaker';
font-style: normal;
font-weight: 400;
src: local('Spinnaker'), local('Spinnaker-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Spinnaker/v11/w8gYH2oyX-I0_rvR6HmX1XYKiumCB-Eo.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Spinnaker';
font-style: normal;
font-weight: 400;
src: local('Spinnaker'), local('Spinnaker-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Spinnaker/v11/w8gYH2oyX-I0_rvR6HmX23YKiumCBw.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
font-display: block;
font-family: 'Suez One';
font-style: normal;
font-weight: 400;
src: local('Suez One'), local('SuezOne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Suez_One/v4/taiJGmd_EZ6rqscQgOFMmouC-50xUK4.woff2") format('woff2');
unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Suez One';
font-style: normal;
font-weight: 400;
src: local('Suez One'), local('SuezOne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Suez_One/v4/taiJGmd_EZ6rqscQgOFAmouC-50xUK4.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Suez One';
font-style: normal;
font-weight: 400;
src: local('Suez One'), local('SuezOne-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Suez_One/v4/taiJGmd_EZ6rqscQgOFOmouC-50x.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Work Sans';
font-style: normal;
font-weight: 400;
src: local('Work Sans'), local('WorkSans-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Work_Sans/v5/QGYsz_wNahGAdqQ43Rh_cqDptfpA4cD3.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Work Sans';
font-style: normal;
font-weight: 400;
src: local('Work Sans'), local('WorkSans-Regular'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Work_Sans/v5/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
font-display: block;
font-family: 'Work Sans';
font-style: normal;
font-weight: 700;
src: local('Work Sans Bold'), local('WorkSans-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Work_Sans/v5/QGYpz_wNahGAdqQ43Rh3x4X8lthNy_r-K7mV.woff2") format('woff2');
unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
font-display: block;
font-family: 'Work Sans';
font-style: normal;
font-weight: 700;
src: local('Work Sans Bold'), local('WorkSans-Bold'), url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Work_Sans/v5/QGYpz_wNahGAdqQ43Rh3x4X8mNhNy_r-Kw.woff2") format('woff2');
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7azYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7YjYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7aDYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7ZjYYiAzcPA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7azYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7YjYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7aDYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7ZjYYiAzcPA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7azYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7YjYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7aDYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7ZjYYiAzcPA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7azYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7YjYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7aDYYiAzcPDKo.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Caveat/v10/Wnz6HAc5bAfYB2Q7ZjYYiAzcPA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiKpxOQR7mhDIbg.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiLFxOQR7mhDIbg.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiLBxOQR7mhDIbg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiL5xOQR7mhA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULD1ZlzryZw-MEA.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULCZZlzryZw-MEA.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULCdZlzryZw-MEA.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULClZlzryZw8.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqcuLx7PiZ5qhE.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqHuLx7PiZ5qhE.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqGuLx7PiZ5qhE.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqIuLx7PiZ5.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalCHCtTkBjwVw4.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalZHCtTkBjwVw4.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalYHCtTkBjwVw4.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalWHCtTkBjw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiLFxOQR7mhDIbg.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiLBxOQR7mhDIbg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa36Uj3zpmBOgbNpOqNiL5xOQR7mhA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULD1ZlzryZw-MEA.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULCZZlzryZw-MEA.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULCdZlzryZw-MEA.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fahkwang';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa06Uj3zpmBOgbNpOqNgAVULClZlzryZw8.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqcuLx7PiZ5qhE.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqHuLx7PiZ5qhE.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqGuLx7PiZ5qhE.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noax6Uj3zpmBOgbNpOqIuLx7PiZ5.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalCHCtTkBjwVw4.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalZHCtTkBjwVw4.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalYHCtTkBjwVw4.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fahkwang';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fahkwang/v6/Noa26Uj3zpmBOgbNpOIznalWHCtTkBjw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvIiQublWIIkfg.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvMiQublWIIkfg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mv0iQublWII.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvIiQublWIIkfg.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvMiQublWIIkfg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mv0iQublWII.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c0qv8oRcTnaIM.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c1qv8oRcTnaIM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c7qv8oRcTn.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c0qv8oRcTnaIM.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c1qv8oRcTnaIM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c7qv8oRcTn.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvIiQublWIIkfg.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvMiQublWIIkfg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mv0iQublWII.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvIiQublWIIkfg.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mvMiQublWIIkfg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUs8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9mv0iQublWII.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c0qv8oRcTnaIM.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c1qv8oRcTnaIM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c7qv8oRcTn.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c0qv8oRcTnaIM.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c1qv8oRcTnaIM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Fraunces/v10/6NUu8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib14c7qv8oRcTn.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GSYax7VC4ot_qNB4nYpBdaKUUF4J3TRBYAWYo.woff2") format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GSYax7VC4ot_qNB4nYpBdaKUUK4J3TRBYAWYo.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GSYax7VC4ot_qNB4nYpBdaKUUE4J3TRBYA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xYj_ZhsqY4NemP8.woff2") format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xYjwZhsqY4NemP8.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xYj-ZhsqY4Ne.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GSYax7VC4ot_qNB4nYpBdaKUUF4J3TRBYAWYo.woff2") format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GSYax7VC4ot_qNB4nYpBdaKUUK4J3TRBYAWYo.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GSYax7VC4ot_qNB4nYpBdaKUUE4J3TRBYA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xYj_ZhsqY4NemP8.woff2") format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xYjwZhsqY4NemP8.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inknut Antiqua';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Inknut_Antiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xYj-ZhsqY4Ne.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Marmelad';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Marmelad/v10/Qw3eZQdSHj_jK2e-8uFIEecuCFR9RHw.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Marmelad';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Marmelad/v10/Qw3eZQdSHj_jK2e-8uFCEecuCFR9RHw.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Marmelad';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Marmelad/v10/Qw3eZQdSHj_jK2e-8uFMEecuCFR9.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Marmelad';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Marmelad/v10/Qw3eZQdSHj_jK2e-8uFIEecuCFR9RHw.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Marmelad';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Marmelad/v10/Qw3eZQdSHj_jK2e-8uFCEecuCFR9RHw.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Marmelad';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Marmelad/v10/Qw3eZQdSHj_jK2e-8uFMEecuCFR9.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Nobile';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JRjflSeaOVl1iGXJ3aULFvbGOC5dI.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nobile';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JRjflSeaOVl1iGXJ3UULFvbGOC.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Nobile';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JWjflSeaOVl1iGXJVvdaRMTm6o39ucNvc.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nobile';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JWjflSeaOVl1iGXJVvdaRCTm6o39uc.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Nobile';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JTjflSeaOVl1iGV63WWrZNblOD.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nobile';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JTjflSeaOVl1iGWa3WWrZNbg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Nobile';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JQjflSeaOVl1iO4ojDeZRARGmK3eud.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nobile';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JQjflSeaOVl1iO4ojDd5RARGmK3Q.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Nobile';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JRjflSeaOVl1iGXJ3aULFvbGOC5dI.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nobile';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JRjflSeaOVl1iGXJ3UULFvbGOC.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Nobile';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JWjflSeaOVl1iGXJVvdaRMTm6o39ucNvc.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nobile';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JWjflSeaOVl1iGXJVvdaRCTm6o39uc.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Nobile';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JTjflSeaOVl1iGV63WWrZNblOD.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nobile';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JTjflSeaOVl1iGWa3WWrZNbg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Nobile';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JQjflSeaOVl1iO4ojDeZRARGmK3eud.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Nobile';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Nobile/v12/m8JQjflSeaOVl1iO4ojDd5RARGmK3Q.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNYuDyP7bh.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNYuDyP7bh.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNYuDyP7bh.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNYuDyP7bh.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNYuDyP7bh.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNYuDyP7bh.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNYuDyP7bh.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNYuDyP7bh.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb54C_k3HqUtEw.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb94C_k3HqUtEw.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPbF4C_k3HqU.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb54C_k3HqUtEw.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb94C_k3HqUtEw.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPbF4C_k3HqU.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb54C_k3HqUtEw.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb94C_k3HqUtEw.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPbF4C_k3HqU.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb54C_k3HqUtEw.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPb94C_k3HqUtEw.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Space_Grotesk/v6/V8mDoQDjQSkFtoMM3T6r8E7mPbF4C_k3HqU.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm25L9G78HEZnMg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm2BL9G78HEY.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm25L9G78HEZnMg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm2BL9G78HEY.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm25L9G78HEZnMg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm2BL9G78HEY.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm25L9G78HEZnMg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Syne/v3/8vIH7w4qzmVxm2BL9G78HEY.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKX-Go6G5tXcraQKxaAcI5DPFpLGw-new.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKX-Go6G5tXcraQKw2AcI5DPFpLGw-new.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKX-Go6G5tXcraQKwyAcI5DPFpLGw-new.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKX-Go6G5tXcraQKwKAcI5DPFo-new.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKS-Go6G5tXcraQI7mlZbdhMXBxEu_GOQ-new.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKS-Go6G5tXcraQI7mlZaxhMXBxEu_GOQ-new.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKS-Go6G5tXcraQI7mlZa1hMXBxEu_GOQ-new.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKS-Go6G5tXcraQI7mlZaNhMXBxEu8-new.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKZ-Go6G5tXcraBGwCKd6xBDFs-new.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKZ-Go6G5tXcraaGwCKd6xBDFs-new.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKZ-Go6G5tXcrabGwCKd6xBDFs-new.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKZ-Go6G5tXcraVGwCKd6xB-new.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKU-Go6G5tXcr4uPhWzVaFrNlJzIu4-new.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKU-Go6G5tXcr4uPhWoVaFrNlJzIu4-new.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKU-Go6G5tXcr4uPhWpVaFrNlJzIu4-new.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kanit/v7/nKKU-Go6G5tXcr4uPhWnVaFrNlJz-new.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_9XJnvUD7dzB2KZeoLTkYBeZ0lTiM.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_9XJnvUD7dzB2KZeoQTkYBeZ0lTiM.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_9XJnvUD7dzB2KZeoRTkYBeZ0lTiM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_9XJnvUD7dzB2KZeofTkYBeZ0l.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_6XJnvUD7dzB2KZeKka1M4W5APdCox2gU.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_6XJnvUD7dzB2KZeKka1MjW5APdCox2gU.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_6XJnvUD7dzB2KZeKka1MiW5APdCox2gU.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_6XJnvUD7dzB2KZeKka1MsW5APdCox.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W__XJnvUD7dzB2KdNodREEje60k.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W__XJnvUD7dzB2Kb9odREEje60k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W__XJnvUD7dzB2KbtodREEje60k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W__XJnvUD7dzB2KYNodREEjew.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_8XJnvUD7dzB2C2_8IfWMuUZctdhow.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_8XJnvUD7dzB2C2_8IZmMuUZctdhow.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_8XJnvUD7dzB2C2_8IZ2MuUZctdhow.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Prompt/v5/-W_8XJnvUD7dzB2C2_8IaWMuUZctdg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mitr/v6/pxiLypw5ucZF-Sg4Mbr8f1t9EQ.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mitr/v6/pxiLypw5ucZF-TM4Mbr8f1t9EQ.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mitr/v6/pxiLypw5ucZF-TI4Mbr8f1t9EQ.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mitr/v6/pxiLypw5ucZF-Tw4Mbr8f1s.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mitr/v6/pxiEypw5ucZF8YcdJIPecnFHGPezSQ.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mitr/v6/pxiEypw5ucZF8YcdJJjecnFHGPezSQ.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mitr/v6/pxiEypw5ucZF8YcdJJnecnFHGPezSQ.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Mitr';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Mitr/v6/pxiEypw5ucZF8YcdJJfecnFHGPc.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVhJx26TKEr37c9aBB5iXwJxArepzhN.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVhJx26TKEr37c9aBB5knwJxArepzhN.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVhJx26TKEr37c9aBB5k3wJxArepzhN.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVhJx26TKEr37c9aBB5nXwJxArepw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVkJx26TKEr37c9aBBxJlkc_SjTjQJElti5.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVkJx26TKEr37c9aBBxJlkc5ijTjQJElti5.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVkJx26TKEr37c9aBBxJlkc5yjTjQJElti5.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sarabun';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVkJx26TKEr37c9aBBxJlkc6SjTjQJElg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVjJx26TKEr37c9aAFJn3YO5gjupg.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVjJx26TKEr37c9aBpJn3YO5gjupg.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVjJx26TKEr37c9aBtJn3YO5gjupg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVjJx26TKEr37c9aBVJn3YO5gg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVmJx26TKEr37c9YK5sik8s6yLUrwB0lw.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVmJx26TKEr37c9YK5silQs6yLUrwB0lw.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVmJx26TKEr37c9YK5silUs6yLUrwB0lw.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sarabun/v8/DtVmJx26TKEr37c9YK5silss6yLUrwA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aRnpF2V0EjdZU6tLMS7JNKIjk.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aRnpF2V0EjdZUhtLMS7JNKIjk.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aRnpF2V0EjdZUgtLMS7JNKIjk.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aRnpF2V0EjdZUutLMS7JNK.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7acnpF2V0EjdZ2VkaYrzp5gGDAbnCA.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7acnpF2V0EjdZ2VkaYwzp5gGDAbnCA.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7acnpF2V0EjdZ2VkaYxzp5gGDAbnCA.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7acnpF2V0EjdZ2VkaY_zp5gGDAb.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aTnpF2V0EjZKUsvrQw7qNL.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aTnpF2V0Ejf6UsvrQw7qNL.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aTnpF2V0EjfqUsvrQw7qNL.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aTnpF2V0EjcKUsvrQw7g.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aenpF2V0Ery4A5h5Y9xJlCGgAa.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aenpF2V0Ery4A5nJY9xJlCGgAa.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aenpF2V0Ery4A5nZY9xJlCGgAa.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/K2d/v4/J7aenpF2V0Ery4A5k5Y9xJlCGg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Pridi';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Pridi/v6/2sDQZG5JnZLfkcWJqWgJbU28O7w.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Pridi';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Pridi/v6/2sDQZG5JnZLfkcWSqWgJbU28O7w.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Pridi';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Pridi/v6/2sDQZG5JnZLfkcWTqWgJbU28O7w.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Pridi';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Pridi/v6/2sDQZG5JnZLfkcWdqWgJbU28.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Pridi';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Pridi/v6/2sDdZG5JnZLfkc0mjH0wT0CWAbUdXCg.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Pridi';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Pridi/v6/2sDdZG5JnZLfkc0mjH0rT0CWAbUdXCg.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Pridi';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Pridi/v6/2sDdZG5JnZLfkc0mjH0qT0CWAbUdXCg.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Pridi';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Pridi/v6/2sDdZG5JnZLfkc0mjH0kT0CWAbUd.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3EqXNgp8wxdOdOn44Yzo5kyOv3guIY.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3EqXNgp8wxdOdOn44Y1Y5kyOv3guIY.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3EqXNgp8wxdOdOn44Y1I5kyOv3guIY.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3EqXNgp8wxdOdOn44Y2o5kyOv3gg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3BqXNgp8wxdOdOn44QYatx8cn6qNgR53NU.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3BqXNgp8wxdOdOn44QYatx6sn6qNgR53NU.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3BqXNgp8wxdOdOn44QYatx68n6qNgR53NU.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Trirong';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3BqXNgp8wxdOdOn44QYatx5cn6qNgR5w.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3GqXNgp8wxdOdOn58o2IRj6unHgw.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3GqXNgp8wxdOdOn4Qo2IRj6unHgw.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3GqXNgp8wxdOdOn4Uo2IRj6unHgw.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3GqXNgp8wxdOdOn4so2IRj6uk.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3DqXNgp8wxdOdOlzANzb1B58P9itoh5g.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3DqXNgp8wxdOdOlzANzaZB58P9itoh5g.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3DqXNgp8wxdOdOlzANzadB58P9itoh5g.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Trirong';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Trirong/v6/7r3DqXNgp8wxdOdOlzANzalB58P9ito.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Maitree';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Maitree/v5/MjQGmil5tffhpBrknsqsfbuAsL9Y8Q.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Maitree';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Maitree/v5/MjQGmil5tffhpBrkntGsfbuAsL9Y8Q.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Maitree';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Maitree/v5/MjQGmil5tffhpBrkntCsfbuAsL9Y8Q.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Maitree';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Maitree/v5/MjQGmil5tffhpBrknt6sfbuAsL8.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Maitree';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Maitree/v5/MjQDmil5tffhpBrklmWJaIKivZVi-OBfAw.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Maitree';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Maitree/v5/MjQDmil5tffhpBrklmWJaJmivZVi-OBfAw.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Maitree';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Maitree/v5/MjQDmil5tffhpBrklmWJaJiivZVi-OBfAw.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Maitree';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Maitree/v5/MjQDmil5tffhpBrklmWJaJaivZVi-OA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Sriracha';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sriracha/v5/0nkrC9D4IuYBgWcI9NbLTwHAovb1sQM.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sriracha';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sriracha/v5/0nkrC9D4IuYBgWcI9NbQTwHAovb1sQM.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sriracha';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sriracha/v5/0nkrC9D4IuYBgWcI9NbRTwHAovb1sQM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sriracha';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Sriracha/v5/0nkrC9D4IuYBgWcI9NbfTwHAovb1.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kodchasan';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cX3aUPOAJv9sG4I-DJWjUGTocWG1-oGmZE.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kodchasan';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cX3aUPOAJv9sG4I-DJWjUGIocWG1-oGmZE.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kodchasan';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cX3aUPOAJv9sG4I-DJWjUGJocWG1-oGmZE.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kodchasan';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cX3aUPOAJv9sG4I-DJWjUGHocWG1-oG.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kodchasan';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cXqaUPOAJv9sG4I-DJWjUk8hNC_9ecso5gM4JI.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kodchasan';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cXqaUPOAJv9sG4I-DJWjUk8hNCk9ecso5gM4JI.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kodchasan';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cXqaUPOAJv9sG4I-DJWjUk8hNCl9ecso5gM4JI.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kodchasan';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cXqaUPOAJv9sG4I-DJWjUk8hNCr9ecso5gM.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kodchasan';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cXxaUPOAJv9sG4I-DJWnHGFq8Kk1doH.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kodchasan';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cXxaUPOAJv9sG4I-DJWh3GFq8Kk1doH.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kodchasan';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cXxaUPOAJv9sG4I-DJWhnGFq8Kk1doH.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kodchasan';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cXxaUPOAJv9sG4I-DJWiHGFq8Kk1Q.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Kodchasan';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cX0aUPOAJv9sG4I-DJeM1SQkuCp_-AOoagN.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kodchasan';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cX0aUPOAJv9sG4I-DJeM1SQieCp_-AOoagN.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kodchasan';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cX0aUPOAJv9sG4I-DJeM1SQiOCp_-AOoagN.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kodchasan';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Kodchasan/v6/1cX0aUPOAJv9sG4I-DJeM1SQhuCp_-AOoQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIrapSCOBt_aeQQ7ftydoa8W_Lds78b8SGKufI.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIrapSCOBt_aeQQ7ftydoa8W_LGs78b8SGKufI.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIrapSCOBt_aeQQ7ftydoa8W_LHs78b8SGKufI.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIrapSCOBt_aeQQ7ftydoa8W_LJs78b8SGK.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIoapSCOBt_aeQQ7ftydoa8W_pylqoi0yygg_vbd-E.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIoapSCOBt_aeQQ7ftydoa8W_pylqo50yygg_vbd-E.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIoapSCOBt_aeQQ7ftydoa8W_pylqo40yygg_vbd-E.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIoapSCOBt_aeQQ7ftydoa8W_pylqo20yygg_vb.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDI1apSCOBt_aeQQ7ftydoa8SsLLubg58xGL.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDI1apSCOBt_aeQQ7ftydoa8UcLLubg58xGL.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDI1apSCOBt_aeQQ7ftydoa8UMLLubg58xGL.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDI1apSCOBt_aeQQ7ftydoa8XsLLubg58w.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa05efegJo02SuCgcva.woff2") format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa05efem5o02SuCgcva.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa05efempo02SuCgcva.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 700;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Bai_Jamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa05efelJo02SuCgQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Varela_Round/v13/w8gdH283Tvk__Lua32TysjIfpcuPLdshZhVB.woff2") format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* vietnamese */
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Varela_Round/v13/w8gdH283Tvk__Lua32TysjIfqMuPLdshZhVB.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Varela_Round/v13/w8gdH283Tvk__Lua32TysjIfqcuPLdshZhVB.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Varela_Round/v13/w8gdH283Tvk__Lua32TysjIfp8uPLdshZg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Secular One';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Secular_One/v5/8QINdiTajsj_87rMuMdKyqDgOOhZL4pL06U.woff2") format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Secular One';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Secular_One/v5/8QINdiTajsj_87rMuMdKyqDsOOhZL4pL06U.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Secular One';
  font-style: normal;
  font-weight: 400;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Secular_One/v5/8QINdiTajsj_87rMuMdKyqDiOOhZL4pL.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4QIFqL_KWxWMT.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4SYFqL_KWxWMT.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4QoFqL_KWxWMT.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4Q4FqL_KWxWMT.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4TYFqL_KWxQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4QIFqL_KWxWMT.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4SYFqL_KWxWMT.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4QoFqL_KWxWMT.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4Q4FqL_KWxWMT.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptsg8zYS_SKggPNyCg4TYFqL_KWxQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyCAIT4ttDfCmxA.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyCkIT4ttDfCmxA.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyCIIT4ttDfCmxA.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyCMIT4ttDfCmxA.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyCAIT4ttDfCmxA.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyCkIT4ttDfCmxA.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyCIIT4ttDfCmxA.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyCMIT4ttDfCmxA.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway/v26/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto Thin*/
@font-face {
    font-display: block;
    font-family: "Roboto-Thin";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/e7MeVAyvogMqFwwl61PKhPesZW2xOQ-xsNqO47m55DA.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/idLYXfFa1c7oAPILDl4z0fesZW2xOQ-xsNqO47m55DA.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/BhNUF0UvSiDyKi5GMZK0cPesZW2xOQ-xsNqO47m55DA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/roboto-thin-Svg.svg") format("svg");
}
/* Roboto Thin*/
@font-face {
    font-display: block;
    font-family: "Roboto-Thin";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/fIKu7GwZTy_12XzG_jt8eA.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/Xyjz-jNkfiYuJf8UC3Lizw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/5M21SdFLkD52QavfmHs6cA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/roboto-thin-700-Svg.svg") format("svg");
}
/* Roboto Thin*/
@font-face {
    font-display: block;
    font-family: "Roboto-Thin";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/dzxs_VxZUhdM2mEBkNa8svk_vArhqVIZ0nv9q090hN8.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/dzxs_VxZUhdM2mEBkNa8shsxEYwM7FgeyaSgU71cLG0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/dzxs_VxZUhdM2mEBkNa8si3USBnSvpkopQaUR-2r7iU.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/roboto-thin-italic-Svg.svg") format("svg");
}
/* Roboto Thin*/
@font-face {
    font-display: block;
    font-family: "Roboto-Thin";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/vSzulfKSK0LLjjfeaxcREvesZW2xOQ-xsNqO47m55DA.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/dFWsweFqlD8ExfyN7Gh_GPesZW2xOQ-xsNqO47m55DA.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/OKegwhKhBNN-dhuHI27Hy_esZW2xOQ-xsNqO47m55DA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-thin/v18/roboto-thin-700-italic-Svg.svg") format("svg");
}
/* Roboto Bold*/
@font-face {
    font-display: block;
    font-family: "Roboto-Bold";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/97uahxiqZRoncBaCEI3aW1tXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/97uahxiqZRoncBaCEI3aWz8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/97uahxiqZRoncBaCEI3aW6CWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/roboto-bold-Svg.svg") format("svg");
}
/* Roboto Bold*/
@font-face {
    font-display: block;
    font-family: "Roboto-Bold";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/9_7S_tWeGDh5Pq3u05RVkltXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/9_7S_tWeGDh5Pq3u05RVkj8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/9_7S_tWeGDh5Pq3u05RVkqCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/roboto-bold-700-Svg.svg") format("svg");
}
/* Roboto Bold*/
@font-face {
    font-display: block;
    font-family: "Roboto-Bold";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/t6Nd4cfPRhZP44Q5QAjcC6g5eI2G47JWe0-AuFtD150.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/t6Nd4cfPRhZP44Q5QAjcC9Ih4imgI8P11RFo6YPCPC0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/t6Nd4cfPRhZP44Q5QAjcC102b4v3fUxqf9CZJ1qUoIA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/roboto-bold-italic-Svg.svg") format("svg");
}
/* Roboto Bold*/
@font-face {
    font-display: block;
    font-family: "Roboto-Bold";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/bmC0pGMXrhphrZJmniIZpag5eI2G47JWe0-AuFtD150.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/bmC0pGMXrhphrZJmniIZpdIh4imgI8P11RFo6YPCPC0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/bmC0pGMXrhphrZJmniIZpV02b4v3fUxqf9CZJ1qUoIA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Roboto-bold/v18/roboto-bold-700-italic-Svg.svg") format("svg");
}
/* WorkSans-ExtraLight*/
@font-face {
    font-display: block;
    font-family: "WorkSans-ExtraLight";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-extralight/v3/u_mYNr_qYP37m7vgvmIYZxUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-extralight/v3/u_mYNr_qYP37m7vgvmIYZxa1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-extralight/v3/u_mYNr_qYP37m7vgvmIYZ9qQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-extralight/v3/worksans-extralight-Svg.svg") format("svg");
}
/* WorkSans-ExtraLight*/
@font-face {
    font-display: block;
    font-family: "WorkSans-ExtraLight";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-extralight/v3/FD_Udbezj8EHXbdsqLUplxUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-extralight/v3/FD_Udbezj8EHXbdsqLUplxa1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-extralight/v3/FD_Udbezj8EHXbdsqLUpl9qQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-extralight/v3/worksans-extralight-700-Svg.svg") format("svg");
}
/* WorkSans-SemiBold*/
@font-face {
    font-display: block;
    font-family: "WorkSans-SemiBold";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-semibold/v3/z9rX03Xuz9ZNHTMg1_ghGRUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-semibold/v3/z9rX03Xuz9ZNHTMg1_ghGRa1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-semibold/v3/z9rX03Xuz9ZNHTMg1_ghGdqQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-semibold/v3/worksans-semibold-Svg.svg") format("svg");
}
/* WorkSans-SemiBold*/
@font-face {
    font-display: block;
    font-family: "WorkSans-SemiBold";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-semibold/v3/4udXuXg54JlPEP5iKO5AmRUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-semibold/v3/4udXuXg54JlPEP5iKO5AmRa1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-semibold/v3/4udXuXg54JlPEP5iKO5AmdqQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Worksans-semibold/v3/worksans-semibold-700-Svg.svg") format("svg");
}
/* Poppins-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Poppins-ExtraLight";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/h3r77AwDsldr1E_2g4qqGPk_vArhqVIZ0nv9q090hN8.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/h3r77AwDsldr1E_2g4qqGBsxEYwM7FgeyaSgU71cLG0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/h3r77AwDsldr1E_2g4qqGC3USBnSvpkopQaUR-2r7iU.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/poppins-extralight-Svg.svg") format("svg");
}
/* Poppins-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Poppins-ExtraLight";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/rijG6I_IOXJjsH07UEo2mw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/p0A1C4_gK5NzKtuGSwNurQ.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/rATt6MpBkxjRr3sy5fMEDg.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/poppins-extralight-700-Svg.svg") format("svg");
}
/* Poppins-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Poppins-ExtraLight";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/-GlaWpWcSgdVagNuOGuFKRUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/-GlaWpWcSgdVagNuOGuFKRa1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/-GlaWpWcSgdVagNuOGuFKdqQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/poppins-extralight-italic-Svg.svg") format("svg");
}
/* Poppins-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Poppins-ExtraLight";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/Fm41upUVp7KTKUZhL0PfQVtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/Fm41upUVp7KTKUZhL0PfQT8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/Fm41upUVp7KTKUZhL0PfQaCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-extralight/v5/poppins-extralight-700-italic-Svg.svg") format("svg");
}
/* Poppins-SemiBold */
@font-face {
    font-display: block;
    font-family: "Poppins-SemiBold";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/9VWMTeb5jtXkNoTv949Npfk_vArhqVIZ0nv9q090hN8.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/9VWMTeb5jtXkNoTv949NpRsxEYwM7FgeyaSgU71cLG0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/9VWMTeb5jtXkNoTv949NpS3USBnSvpkopQaUR-2r7iU.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/poppins-semibold-Svg.svg") format("svg");
}
/* Poppins-SemiBold */
@font-face {
    font-display: block;
    font-family: "Poppins-SemiBold";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/aDjpMND83pDErGXlVEr-Sfk_vArhqVIZ0nv9q090hN8.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/aDjpMND83pDErGXlVEr-SRsxEYwM7FgeyaSgU71cLG0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/aDjpMND83pDErGXlVEr-SS3USBnSvpkopQaUR-2r7iU.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/poppins-semibold-700-Svg.svg") format("svg");
}
/* Poppins-SemiBold */
@font-face {
    font-display: block;
    font-family: "Poppins-SemiBold";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/RbebACOccNN-5ixkDIVLjRUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/RbebACOccNN-5ixkDIVLjRa1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/RbebACOccNN-5ixkDIVLjdqQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/poppins-semibold-italic-Svg.svg") format("svg");
}
/* Poppins-SemiBold */
@font-face {
    font-display: block;
    font-family: "Poppins-SemiBold";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/c4FPK8_hIFKoX59qcGwdChUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/c4FPK8_hIFKoX59qcGwdCha1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/c4FPK8_hIFKoX59qcGwdCtqQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Poppins-semibold/v5/poppins-semibold-700-italic-Svg.svg") format("svg");
}
/* Barlow-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Barlow-ExtraLight";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/51v0xj5VPw1cLYHNhfd8NFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/51v0xj5VPw1cLYHNhfd8ND8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/51v0xj5VPw1cLYHNhfd8NKCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/barlow-extralight-Svg.svg") format("svg");
}
/* Barlow-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Barlow-ExtraLight";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/2woyxyDnPU0v4IiqYU9D1g.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/-HJgNsTwx9qXGSxqew62RQ.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/qoExc9IJQUjYXhlVZNNLgg.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/barlow-extralight-700-Svg.svg") format("svg");
}
/* Barlow-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Barlow-ExtraLight";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/14AxwKgJhKIO-YYUP_KtZag5eI2G47JWe0-AuFtD150.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/14AxwKgJhKIO-YYUP_KtZdIh4imgI8P11RFo6YPCPC0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/14AxwKgJhKIO-YYUP_KtZV02b4v3fUxqf9CZJ1qUoIA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/barlow-extralight-italic-Svg.svg") format("svg");
}
/* Barlow-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Barlow-ExtraLight";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/cdbGxfKO8gdkBd5U5TuXqPesZW2xOQ-xsNqO47m55DA.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/W1XpMGU0WrpbCawEdG1FM_esZW2xOQ-xsNqO47m55DA.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/_lIpJP17FZmSeklpAeOdnvesZW2xOQ-xsNqO47m55DA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-extralight/v1/barlow-extralight-700-italic-Svg.svg") format("svg");
}
/* Barlow-Medium */
@font-face {
    font-display: block;
    font-family: "Barlow-Medium";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/ZqlneECqpsd9SXlmAsD2E1tXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/ZqlneECqpsd9SXlmAsD2Ez8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/ZqlneECqpsd9SXlmAsD2E6CWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/barlow-medium-Svg.svg") format("svg");
}
/* Barlow-Medium */
@font-face {
    font-display: block;
    font-family: "Barlow-Medium";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/yS165lxqGuDghyUMXeu6xVtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/yS165lxqGuDghyUMXeu6xT8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/yS165lxqGuDghyUMXeu6xaCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/barlow-medium-700-Svg.svg") format("svg");
}
/* Barlow-Medium */
@font-face {
    font-display: block;
    font-family: "Barlow-Medium";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/xJLokI-F3wr7NRWXgS0pZ6g5eI2G47JWe0-AuFtD150.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/xJLokI-F3wr7NRWXgS0pZ9Ih4imgI8P11RFo6YPCPC0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/xJLokI-F3wr7NRWXgS0pZ102b4v3fUxqf9CZJ1qUoIA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/barlow-medium-italic-Svg.svg") format("svg");
}
/* Barlow-Medium */
@font-face {
    font-display: block;
    font-family: "Barlow-Medium";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/hw7DQwyFvE7wFOFzpow4xqg5eI2G47JWe0-AuFtD150.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/hw7DQwyFvE7wFOFzpow4xtIh4imgI8P11RFo6YPCPC0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/hw7DQwyFvE7wFOFzpow4xl02b4v3fUxqf9CZJ1qUoIA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Barlow-medium/v1/barlow-medium-700-italic-Svg.svg") format("svg");
}
/* Oswald-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Oswald-ExtraLight";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-extralight/v16/GwZ_PiN1Aind9Eyjp868E1tXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-extralight/v16/GwZ_PiN1Aind9Eyjp868Ez8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-extralight/v16/GwZ_PiN1Aind9Eyjp868E6CWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-extralight/v16/oswald-extralight-Svg.svg") format("svg");
}
/* Oswald-ExtraLight */
@font-face {
    font-display: block;
    font-family: "Oswald-ExtraLight";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-extralight/v16/RqRF4AQrkUh3ft98NHH2mA.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-extralight/v16/PyqsDANUgLi2UsdO-d4iZQ.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-extralight/v16/AWM5wXtMJeRP-AcRTgT4qQ.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-extralight/v16/oswald-extralight-700-Svg.svg") format("svg");
}
/* Oswald-Medium */
@font-face {
    font-display: block;
    font-family: "Oswald-Medium";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/cgaIrkaP9Empe8_PwXbajFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/cgaIrkaP9Empe8_PwXbajD8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/cgaIrkaP9Empe8_PwXbajKCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/oswald-medium-Svg.svg") format("svg");
}
/* Oswald-Medium */
@font-face {
    font-display: block;
    font-family: "Oswald-Medium";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/dI-qzxlKVQA6TUC5RKSb31tXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/dI-qzxlKVQA6TUC5RKSb3z8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/dI-qzxlKVQA6TUC5RKSb36CWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Oswald-medium/v16/oswald-medium-700-Svg.svg") format("svg");
}
/* CormorantGaramond-Light */
@font-face {
    font-display: block;
    font-family: "CormorantGaramond-Light";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVSNMxVe3WGf96EDbCaLCBKE.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVergGQquJ_f3dxTxEJk8ZKM.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVXYC8UqYVZ_Us7w6eA7MdZE.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/cormorantgaramond-light-Svg.svg") format("svg");
}
/* CormorantGaramond-Light */
@font-face {
    font-display: block;
    font-family: "CormorantGaramond-Light";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVXP87xhFzkXvitf5EbJwljk.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVebEnH4R5m1MLXJyCi0BC78.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/iEjm9hVxcattz37Y8gZwVXXouEby_DX2rsmMI51GE6g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/cormorantgaramond-light-700-Svg.svg") format("svg");
}
/* CormorantGaramond-Light */
@font-face {
    font-display: block;
    font-family: "CormorantGaramond-Light";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAhjqQayVfgmnRFwqYqN-Dis.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAhNmD6bOwmI0fiYv_Ehe03s.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAoUBHI3ylZGW9V5Fst4kWps.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/cormorantgaramond-light-italic-Svg.svg") format("svg");
}
/* CormorantGaramond-Light */
@font-face {
    font-display: block;
    font-family: "CormorantGaramond-Light";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAjp2K1CgsixPpkXulytJk5A.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAhX141arjC0UgpdoDjjeeVk.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/zuqx3k1yUEl3Eavo-ZPEAs_ggsKlhsDnft5n268BUmY.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-light/v5/cormorantgaramond-light-700-italic-Svg.svg") format("svg");
}
/* CormorantGaramond-SemiBold */
@font-face {
    font-display: block;
    font-family: "CormorantGaramond-SemiBold";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVVBiiiFZ1SMKhjDurTuPCI4.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVbaDr2DD9WOmTsY4M3S93hU.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVeXPWxx9SjLSy6MMhsXoUuc.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/cormorantgaramond-semibold-Svg.svg") format("svg");
}
/* CormorantGaramond-SemiBold */
@font-face {
    font-display: block;
    font-family: "CormorantGaramond-SemiBold";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVVYUpUlN7yzNHgIMH66hSOI.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVQIBIRsdTZvmhTwexVJEOCE.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/iEjm9hVxcattz37Y8gZwVaDtWBNc4GTxi9CQqfNpXFo.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/cormorantgaramond-semibold-700-Svg.svg") format("svg");
}
/* CormorantGaramond-SemiBold */
@font-face {
    font-display: block;
    font-family: "CormorantGaramond-SemiBold";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAoNfVaeyxI1fRb3LCiKLt24.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAggc7XrJygm306pFqSI3kLU.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAtfqxAW0UHKApQgkrKaDcls.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/cormorantgaramond-semibold-italic-Svg.svg") format("svg");
}
/* CormorantGaramond-SemiBold */
@font-face {
    font-display: block;
    font-family: "CormorantGaramond-SemiBold";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAoWXH9gdibkBmfnjU2pcZcs.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAsgX3acpz6D8mJOI1MynZxQ.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/zuqx3k1yUEl3Eavo-ZPEAr5MZRoaqvZ37XiNNIvX6C0.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Cormorantgaramond-semibold/v5/cormorantgaramond-semibold-700-italic-Svg.svg") format("svg");
}
/* PlayfairDisplay-Bold */
@font-face {
    font-display: block;
    font-family: "PlayfairDisplay-Bold";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/UC3ZEjagJi85gF9qFaBgIIWMvkC5IXg8PD2cMeMDjBI.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/UC3ZEjagJi85gF9qFaBgIMITpqSvb0EhPNqvdm-qG4s.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/UC3ZEjagJi85gF9qFaBgIETnlckwlsa9ycyidjAp5Kc.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/playfairdisplay-bold-Svg.svg") format("svg");
}
/* PlayfairDisplay-Bold */
@font-face {
    font-display: block;
    font-family: "PlayfairDisplay-Bold";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/UC3ZEjagJi85gF9qFaBgILxv9TIgpWQaRKdG-_MdlP0.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/UC3ZEjagJi85gF9qFaBgIIidMZaDCgb76Cj_Fd30HHc.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/UC3ZEjagJi85gF9qFaBgIBczLBIbQ3AJzFR3-m2VmLg.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/playfairdisplay-bold-700-Svg.svg") format("svg");
}
/* PlayfairDisplay-Bold */
@font-face {
    font-display: block;
    font-family: "PlayfairDisplay-Bold";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/n7G4PqJvFP2Kubl0VBLDEPizZYmr4BUkAcTxjCN2kLE.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/n7G4PqJvFP2Kubl0VBLDENxHfcsPlDajrhlFKgOPuYg.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/n7G4PqJvFP2Kubl0VBLDELo34k-OXaeZF4ilAYxgJ0c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/playfairdisplay-bold-italic-Svg.svg") format("svg");
}
/* PlayfairDisplay-Bold */
@font-face {
    font-display: block;
    font-family: "PlayfairDisplay-Bold";
    font-weight: 700;
    font-style: italic;
    src:  url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/n7G4PqJvFP2Kubl0VBLDEA9QP145tN5qB9RQEnC5ftI.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/n7G4PqJvFP2Kubl0VBLDEBMHjY5M6rp_NSn2hjKJhfc.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/n7G4PqJvFP2Kubl0VBLDEEwFowWjhcb6pv3Dv1OTigc.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Playfairdisplay-bold/v13/playfairdisplay-bold-700-italic-Svg.svg") format("svg");
}
/* DancingScript-Regular */
@font-face {
    font-display: block;
    font-family: "DancingScript-Regular";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancingscript-regular/v9/DK0eTGXiZjN6yA8zAEyM2RN-0beyHaEC1kqeqPFpWrs.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancingscript-regular/v9/DK0eTGXiZjN6yA8zAEyM2UtCETOCmz2wCdel46UOhAM.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancingscript-regular/v9/DK0eTGXiZjN6yA8zAEyM2fog-Cy6dhy5Xgu82688fSg.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancingscript-regular/v9/dancingscript-regular-Svg.svg") format("svg");
}
/* DancingScript-Regular */
@font-face {
    font-display: block;
    font-family: "DancingScript-Regular";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancingscript-regular/v9/KGBfwabt0ZRLA5W1ywjowZR92E8gBbe58j0pHY_YhTY.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancingscript-regular/v9/KGBfwabt0ZRLA5W1ywjowW_WCyVccUAWDspcx_4UXqg.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancingscript-regular/v9/KGBfwabt0ZRLA5W1ywjowWfbPar0qrg-I_8uTXkQ0Sc.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Dancingscript-regular/v9/dancingscript-regular-700-Svg.svg") format("svg");
}
/* Raleway-SemiBold */
@font-face {
    font-display: block;
    font-family: "Raleway-SemiBold";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/STBOO2waD2LpX45SXYjQBfk_vArhqVIZ0nv9q090hN8.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/STBOO2waD2LpX45SXYjQBRsxEYwM7FgeyaSgU71cLG0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/STBOO2waD2LpX45SXYjQBS3USBnSvpkopQaUR-2r7iU.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/raleway-semibold-Svg.svg") format("svg");
}
/* Raleway-SemiBold */
@font-face {
    font-display: block;
    font-family: "Raleway-SemiBold";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/WmVKXVcOuffP_qmCpFuyzfk_vArhqVIZ0nv9q090hN8.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/WmVKXVcOuffP_qmCpFuyzRsxEYwM7FgeyaSgU71cLG0.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/WmVKXVcOuffP_qmCpFuyzS3USBnSvpkopQaUR-2r7iU.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/raleway-semibold-700-Svg.svg") format("svg");
}
/* Raleway-SemiBold */
@font-face {
    font-display: block;
    font-family: "Raleway-SemiBold";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/OY22yoG8EJ3IN_muVWm29BUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/OY22yoG8EJ3IN_muVWm29Ba1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/OY22yoG8EJ3IN_muVWm29NqQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/raleway-semibold-italic-Svg.svg") format("svg");
}
/* Raleway-SemiBold */
@font-face {
    font-display: block;
    font-family: "Raleway-SemiBold";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/lFxvRPuGFG5ktd7P0WRwKhUOjZSKWg4xBWp_C_qQx0o.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/lFxvRPuGFG5ktd7P0WRwKha1RVmPjeKy21_GQJaLlJI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/lFxvRPuGFG5ktd7P0WRwKtqQynqKV_9Plp7mupa0S4g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Raleway-semibold/v12/raleway-semibold-700-italic-Svg.svg") format("svg");
}
/* Lato-Light */
@font-face {
    font-display: block;
    font-family: "Lato-Light";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/2hXzmNaFRuKTSBR9nRGO-A.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/kU6VHbqMAZhaN_nXCmLQsQ.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/BTu4SsVveqk58cdYjlaM9g.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/lato-light-Svg.svg") format("svg");
}
/* Lato-Light */
@font-face {
    font-display: block;
    font-family: "Lato-Light";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/7nLfsQCzhQW_PwpkrwroYw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/I1Pn3gihk5vyP0Yw5GqKsQ.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/zpv3sOKAbMf4wff105oLjw.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/lato-light-700-Svg.svg") format("svg");
}
/* Lato-Light */
@font-face {
    font-display: block;
    font-family: "Lato-Light";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/XNVd6tsqi9wmKNvnh5HNEFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/XNVd6tsqi9wmKNvnh5HNED8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/XNVd6tsqi9wmKNvnh5HNEKCWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/lato-light-italic-Svg.svg") format("svg");
}
/* Lato-Light */
@font-face {
    font-display: block;
    font-family: "Lato-Light";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/AcvTq8Q0lyKKNxRlL28Rn1tXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/AcvTq8Q0lyKKNxRlL28Rnz8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/AcvTq8Q0lyKKNxRlL28Rn6CWcynf_cDxXwCLxiixG1c.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Lato-light/v14/lato-light-700-italic-Svg.svg") format("svg");
}
/* Heebo light  */
@font-face{
    font-family:"Heebo-Light";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-light/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1E1yysd0mm.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-light/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1E1yysd0mg.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-light/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1E1yysd0mj.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-light/v15/heebo-light-Svg.svg") format("svg");
}
/* Heebo Regular */
@font-face{
    /* Heebo Regular */
    font-family:"Heebo-Light";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-light/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-light/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mg.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-light/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mj.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-light/v15/heebo-light-700-Svg.svg") format("svg");
}
/* Heebo medium */
@font-face{
    font-family:"Heebo-Medium";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-medium/v15/NGSpv5_NC0k9P_v6xZUCbLRAHxK1Euyysd0mm.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-medium/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1Euyysd0mg.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-medium/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1Euyysd0mj.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-medium/v15/heebo-medium-Svg.svg") format("svg");
}
/* Heebo Bold */
@font-face{
    font-family:"Heebo-Medium";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-medium/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1Ebiusd0mm.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-medium/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1E1yysd0mg.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-medium/v15/NGSpv5_NC0k9P_v6ZUCbLRAHxK1Ebiusd0mj.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Heebo-medium/v15/heebo-medium-700-Svg.svg") format("svg");
}
/* Rubik-Light */
@font-face{
    font-family:"Rubik-Light";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1VQ.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1Uw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/rubik-light-Svg.svg") format("svg");
}
/* Rubik-Light */
@font-face{
    font-family:"Rubik-Light";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0U1.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0Uz.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0Uw.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/rubik-light-italic-Svg.svg") format("svg");
}
/* Rubik regular */
@font-face{
    font-family:"Rubik-Light";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1VQ.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1Uw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/rubik-light-700-Svg.svg") format("svg");
}
/* Rubik regular */
@font-face{
    font-family:"Rubik-Light";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0U1.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3U3f4Q.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0Uw.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-light/v14/rubik-light-italic-Svg.svg") format("svg");
}
/* Rubik Mediun */
@font-face{
    font-family:"Rubik-Medium";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1VQ.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1Uw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/rubik-medium-Svg.svg") format("svg");
}
/* Rubik Mediun */
@font-face{
    font-family:"Rubik-Medium";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0U1.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0Uz.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0Uw.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/rubik-medium-italic-Svg.svg") format("svg");
}
/* Rubik Bold  */
@font-face{
    font-family:"Rubik-Medium";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1VQ.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1Uw.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UA.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/rubik-medium-700-Svg.svg") format("svg");
}
/* Rubik Bold  */
@font-face{
    font-family:"Rubik-Medium";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEU1.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUz.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUw.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Rubik-medium/v14/rubik-medium-italic-Svg.svg") format("svg");
}
/* Assistant-Light  */
@font-face{
    font-family:"Assistant-Light";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-light/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtrhnEGQ.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-light/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtrhnEGI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-light/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtrhnEGE.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-light/v8/assistant-light-Svg.svg") format("svg");
}
/* Assistant-Regular */
@font-face{
    font-family:"Assistant-Light";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-light/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnEGQ.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-light/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnEGI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-light/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnEGE.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-light/v8/assistant-light-700-Svg.svg") format("svg");
}
/* Assistant-SemiBold */
@font-face{
    font-family:"Assistant-SemiBold";
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-semibold/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtjhgEGQ.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-semibold/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtjhgEGI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-semibold/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtjhgEGE.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-semibold/v8/assistant-semibold-Svg.svg") format("svg");
}
/* Assistant-Bold */
@font-face{
    font-family:"Assistant-SemiBold";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-semibold/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtmZgEGQ.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-semibold/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtmZgEGI.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-semibold/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtmZgEGE.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Assistant-semibold/v8/assistant-semibold-700-Svg.svg") format("svg");
}
/* Karantina-Regular */
@font-face{
    font-family:"Karantina-Light";
    src:  url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Karantina-light/v1/buExpo24ccnh31GVMABxXCgv_fRP.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Karantina-light/v1/buExpo24ccnh31GVMABxXCgv_fRJ.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Karantina-light/v1/buExpo24ccnh31GVMABxXCgv_fRK.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Karantina-light/v1/karantina-light-Svg.svg") format("svg");
}
/* Karantina-Regular */
@font-face{
    font-family:"Karantina-Light";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Karantina-light/v1/buE0po24ccnh31GVMAB59Qo6.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Karantina-light/v1/buE0po24ccnh31GVMAB59Qo8.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Karantina-light/v1/buE0po24ccnh31GVMAB59Qo_.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/dist/viewer/user-site-fonts/fonts/Karantina-light/v1/karantina-light-700-Svg.svg") format("svg");
}`)
