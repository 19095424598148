export function getTagName(htmlOpenTag: string) {
	const htmlTagNameMatch = /<(\w*)/.exec(htmlOpenTag)
	return htmlTagNameMatch ? htmlTagNameMatch[1] : 'span'
}

export function getTagAttributes(htmlOpenTag: string) {
	const attributesObject: Record<string, string | undefined> = {}
	const findAttribute = /\s+((?<propName>\w*)(=(["']?)(?<propValue>((?!\4).)*)\4)?)/gim
	let currAttr = findAttribute.exec(htmlOpenTag)

	while (currAttr !== null) {
		const { propName, propValue } = currAttr.groups as { propName: string; propValue: string }
		attributesObject[propName] = propValue
		currAttr = findAttribute.exec(htmlOpenTag)
	}

	return attributesObject
}

export function toHtmlOpenTag(tagName: string, attributes: Record<string, string | undefined>) {
	const attributesStr = Object.entries(attributes)
		.map(([key, value]) => (value !== undefined ? `${key}="${value}"` : key))
		.join(' ')

	return `<${tagName} ${attributesStr}>`
}

export function getInlineStyle(inlineStyles: string) {
	return inlineStyles
		.replace(/&amp;/g, '&')
		.replace(/&quot;/g, '"')
		.split(';')
		.reduce<Record<string, string>>((styles, attr) => {
			const trimmedAttr = attr.trim()

			if (!trimmedAttr) {
				return styles
			}

			const [name, value] = trimmedAttr.split(':')
			if (name && value) {
				styles[name.trim()] = value.trim()
			}

			return styles
		}, {})
}

export function toInlineStyle(inlineStyles: Record<string, string>) {
	return Object.entries(inlineStyles)
		.map(([key, value]) => `${key}:${value}`)
		.join(';')
		.replace(/"/g, '&quot;')
}
