import { Component, Style } from '@wix/thunderbolt-becky-types'
import { getStyleCssVars } from '@wix/thunderbolt-becky-root'
import { createCssCompNode } from '../cssCompNode'
import { FeatureRefs } from '../cssFeatures.types'
import { mapValuesValues } from '../../utils/mapValuesValues'
import { mapValues } from '../../utils/mapValues'

const COMP_TYPES_WITH_SPECIFIC_VARS = new Set([
	'TPASection',
	'TPAMultiSection',
	'TPAWidget',
	'TPAGluedWidget',
	'tpaWidgetNative',
	'SlideShowSlide',
])

const styleToCssVars = (style: Style) => {
	if (!style?.style?.properties || 'st-css' in style.style.properties) {
		return undefined
	}
	return getStyleCssVars(style)
}

export const compTheme = createCssCompNode('styleBased', 'compTheme', {
	getDependencies: (component: Component, refs: FeatureRefs<'styleBased'>) =>
		COMP_TYPES_WITH_SPECIFIC_VARS.has(component.componentType) ? null : refs.compStyles(component),
	toViewItem: (__, compStyles) => {
		if (!compStyles) {
			return null
		}

		if (compStyles.type === 'classic') {
			return { type: 'classic' as const, css: mapValues(compStyles.css, styleToCssVars) }
		}

		return { type: 'variants' as const, css: mapValuesValues(compStyles.css, styleToCssVars) }
	},
})
