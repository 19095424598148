import { MegaStore } from '../stores/megaStore'
import { getTemplateIdAndItemContext } from './repeatersAPI'
import type { CatharsisArgs } from '../types/catharsis.types'

export const getInvalidationHandler = (megaStore: MegaStore): CatharsisArgs['onInvalidation'] => (path, get) => {
	const [namespace, aspect, id] = path
	const [compId, context] = getTemplateIdAndItemContext(id)

	// data namespace is not reflected to the store because it is used only to supply references to component nodes
	if (namespace !== 'data') {
		const aspectStore = megaStore.getChildStore(aspect)
		const contextStore = context ? aspectStore.getChildStore(context) : aspectStore
		contextStore.updateById(compId, get(path))
	}
}
