// TODO : export commmon logic
import { compLayouts } from '../responsiveLayout/compLayoutsCompNode'
import { LayoutRuntimeCssFeature } from './layoutRuntime.types'
import { layoutRuntime } from './layoutRuntimeCompNode'
import { layoutRuntimeDomApplier } from './layoutRuntimeDomApplier'
import { layoutVariants } from '../responsiveLayout/layoutVariantsCompNode'

/**
 * This is an editor only feature that is supposed to reduce the time editors take to understand the current
 * value of items when cascading logic is applied, instead of composing all the layouts from different variants
 * this feature is used with an API that queries the component for the applied CSS.
 */
export const layoutRuntimeApiCssFeature: LayoutRuntimeCssFeature = {
	experimentName: 'specs.thunderbolt.layout_runtime',
	renderableNodes: { layoutRuntime, layoutVariants },
	intermediateNodes: { compLayouts },
	dataMaps: ['layout_data', 'variants_data'],
	domApplier: layoutRuntimeDomApplier,
}
