import type { StylableEditor } from '@wix/stylable-panel-drivers'
import { initDSCarmiInstances } from 'thunderbolt-viewer-manager'
import type { DSCarmiFactoryParams, DSRendererModel, Manifest, ViewerAPI } from 'thunderbolt-viewer-manager-types'
import { createFeaturesLoader } from '@wix/thunderbolt-features'
import { ComponentLibraries } from '@wix/thunderbolt-components-loader'
import { Container, Identifier } from '@wix/thunderbolt-ioc'
import { featuresLoaders } from 'ds-features-loaders'
import type { Environment } from '@wix/thunderbolt-environment'
import { initThunderboltDS } from './initThunderboltDs'
import { BrowserWindow, FetchFn, ILogger, LOADING_PHASES } from '@wix/thunderbolt-symbols'
import type { BeckyModel, ComponentsNativeMappers } from '@wix/thunderbolt-becky-root'
import { createComponentsPreviewRegistryCSR } from '@wix/thunderbolt-components-registry/preview'
import { ClientRenderResponse } from 'feature-react-renderer'
import { FetchApi, isExperimentOpen } from '@wix/thunderbolt-commons'
import { DSViewerApiFactoryParams } from './dsClientRunner'
import { applyPolyfillsIfNeeded, initWixCustomElementsRegistry } from './customElementsUtils'
import { createStylableFactory } from './createStylableFactory'
import { pagesModelsRegistrar } from './pagesModelsRegistrar'
import { DSCarmi } from 'thunderbolt-ds-carmi-root'
import { getEditorCatharsis } from '@wix/thunderbolt-catharsis-deployable'
import { createDomReadyPromise } from 'feature-thunderbolt-initializer'
import Div from './div'

export type DsApiFactoryEnv = {
	createComponentsPreviewRegistryPromise: Promise<{
		createComponentsPreviewRegistryCSR: typeof createComponentsPreviewRegistryCSR
	}>
	viewerManagerModulePromise: Promise<{
		initDSCarmiInstances: typeof initDSCarmiInstances
	}>
	createStylableFactoryPromise: Promise<{
		createStylableFactory: typeof createStylableFactory
	}>
	logger: ILogger
	componentLibraries?: ComponentLibraries
	container: Container
	window: NonNullable<BrowserWindow> & {
		viewerSource: string
		viewerBase: string
		manifest: Manifest
	}
	isViewerFragment: boolean
}

export type DsApis = {
	getViewerAPI: () => ViewerAPI
	getModule: <T>(identifier: Identifier) => T
	render: (target: HTMLElement, rootCompId?: string) => Promise<ClientRenderResponse>
	appDidMount: () => void
	appWillUnmount: () => void
	initCustomElements: () => Promise<void>
	dsCarmi?: DSCarmi
}

const toDSCarmiInstanceFactoryParams = (
	{
		runningExperiments,
		dataFixerExperiments,
		platformAppsExperiments,
		languageCode,
		isResponsive,
		siteOwnerCoBranding,
		useSandboxInHTMLComp,
		serviceTopology,
		mediaAuthToken,
		userId,
	}: DSViewerApiFactoryParams,
	clientWorkerUrl: string,
	fetchFn: FetchFn,
	externalBaseUrl: string,
	url: string,
	queryParams: string,
	cookie: string,
	componentsMappers: ComponentsNativeMappers,
	stylableFactoryPromise: Promise<() => StylableEditor>,
	logger: ILogger
): DSCarmiFactoryParams => ({
	dsRendererModel: {
		runningExperiments: runningExperiments as DSRendererModel['runningExperiments'],
		dataFixerExperiments,
		siteOwnerCoBranding,
		platformAppsExperiments: platformAppsExperiments as DSRendererModel['platformAppsExperiments'],
		languageCode,
		siteMetaData: {
			adaptiveMobileOn: false,
			isResponsive,
		},
		useSandboxInHTMLComp,
		mediaAuthToken,
		userId,
	},
	dsServiceTopology: serviceTopology,
	clientWorkerUrl,
	componentsMappers,
	fetchFn,
	externalBaseUrl,
	url,
	queryParams,
	cookie,
	stylableFactoryPromise,
	logger,
})

const getClientWorkerUrl = async (viewerBase: string, fetchFn: FetchFn) => {
	const workerManifestFileName =
		process.env.NODE_ENV === 'development' ? 'manifest-worker.json' : 'ds-manifest-worker.min.json'
	const workerManifestUrl = `${viewerBase}/${workerManifestFileName}`
	const workerManifest = await fetchFn(workerManifestUrl).then((res) => res.json())
	return workerManifest['clientWorker.js']
}

export const getDsApis = async (
	params: DSViewerApiFactoryParams,
	{
		componentLibraries,
		container,
		window,
		logger,
		createComponentsPreviewRegistryPromise,
		viewerManagerModulePromise,
		createStylableFactoryPromise,
		isViewerFragment,
	}: DsApiFactoryEnv
): Promise<DsApis> => {
	const { fetchFunction, serviceTopology, wixBiSession, runningExperiments } = params

	const {
		location: { href: url, search: queryParams },
		viewerBase,
		fetch,
		document: { cookie },
	} = window
	const fetchFn = fetch || (fetchFunction as FetchFn)

	logger.phaseStarted(LOADING_PHASES.APPLY_POLYFILLS)
	await applyPolyfillsIfNeeded()
	logger.phaseEnded(LOADING_PHASES.APPLY_POLYFILLS)

	const wixCustomElementsPromise = initWixCustomElementsRegistry(isViewerFragment ? window.parent : window)

	logger.phaseStarted(LOADING_PHASES.WAIT_FOR_IMPORTS)
	const [
		{ createComponentsPreviewRegistryCSR: createComponentsPreviewRegistry },
		{ createStylableFactory: stylableFactory },
	] = await Promise.all([createComponentsPreviewRegistryPromise, createStylableFactoryPromise])
	logger.phaseEnded(LOADING_PHASES.WAIT_FOR_IMPORTS)

	const componentsRegistryPromise = createComponentsPreviewRegistry({
		serviceTopology,
		url,
		experimentalMobileLibrary: isExperimentOpen(runningExperiments['specs.mobile-elements.useRegistry']),
		useNewStatics: isExperimentOpen(runningExperiments['specs.editor-elements.useNewStatics']),
		runAndReport: (metric, fn) => {
			return logger.runAsyncAndReport(fn, metric)
		},
	})

	const stylableFactoryPromise = componentsRegistryPromise
		.then((registry) => registry.getStylableMetadataURLs())
		.then((componentsStylableMetadataUrls: Array<string>) => {
			const { staticServerFallbackUrl, staticMediaUrl } = serviceTopology
			return stylableFactory({
				componentsStylableMetadataUrls,
				staticServerFallbackUrl,
				staticMediaUrl,
			})
		})

	componentLibraries =
		componentLibraries ||
		componentsRegistryPromise.then((registry) => Promise.all([registry.getComponentsRegistrarAPI()]))
	logger.phaseStarted(LOADING_PHASES.INIT_DS_CARMI)

	const { catharsis, catharsisMegaStore, usedDataMaps } = getEditorCatharsis((exp) =>
		isExperimentOpen(runningExperiments[exp])
	)

	const monitorLoadPhase = async (phaseName: string, promiseFn: () => Promise<any>): Promise<any> => {
		logger.phaseStarted(phaseName)
		const result = await promiseFn()
		logger.phaseEnded(phaseName)
		return result
	}

	const [wixCustomElements, { dsCarmi }] = await Promise.all([
		wixCustomElementsPromise,
		(async () => {
			// TODO: Maybe externalBaseUrl can be removed from here altogether.
			const externalBaseUrl = (() => {
				const urlObj = new URL(url)
				return `${urlObj.origin}${urlObj.pathname}`
			})()

			const [
				externalComponentsMappers,
				clientWorkerUrl,
				viewerManagerModule,
				{ mergeMappers },
			] = await Promise.all([
				monitorLoadPhase(LOADING_PHASES.GET_COMPONENTS_MAPPERS, () =>
					componentsRegistryPromise.then((registry) => registry.getComponentsMappers())
				),
				monitorLoadPhase(LOADING_PHASES.GET_CLIENT_WORKER, () => getClientWorkerUrl(viewerBase, fetchFn)),
				viewerManagerModulePromise,
				import('@wix/thunderbolt-components/src/mappers'),
			])

			const componentsMappers: ComponentsNativeMappers = mergeMappers(
				externalComponentsMappers,
				(name: keyof BeckyModel['experiments']) => isExperimentOpen(runningExperiments[name])
			)

			return viewerManagerModule.initDSCarmiInstances(
				toDSCarmiInstanceFactoryParams(
					params,
					clientWorkerUrl,
					fetchFn,
					externalBaseUrl,
					url,
					queryParams,
					cookie,
					componentsMappers,
					stylableFactoryPromise,
					logger
				),
				pagesModelsRegistrar,
				{ catharsis, usedDataMaps }
			)
		})(),
	])
	logger.phaseEnded(LOADING_PHASES.INIT_DS_CARMI)

	const viewerModel = dsCarmi.viewerModel
	const { experiments, requestUrl } = viewerModel

	const environment: Omit<Environment, 'browserWindow'> & { browserWindow: DsApiFactoryEnv['window'] } = {
		waitForDomReady: createDomReadyPromise,
		// @ts-ignore
		wixBiSession,
		logger,
		// @ts-ignore
		biReporter: {
			reportPageNavigation: () => {},
			reportPageNavigationDone: () => {},
		},
		fetchApi: FetchApi(requestUrl, fetchFn),
		viewerModel,
		// @ts-ignore
		specificEnvFeaturesLoaders: createFeaturesLoader(featuresLoaders, { experiments, logger }),
		componentLibraries,
		experiments,
		browserWindow: window,
		BaseComponent: Div,
	}

	logger.phaseStarted(LOADING_PHASES.INIT_DS_CONTAINER)
	const dsAPis = await initThunderboltDS({
		dsCarmi,
		pagesModelsRegistrar,
		environment,
		container,
		wixCustomElements,
		catharsisMegaStore,
		catharsis,
		isViewerFragment,
	})
	logger.phaseEnded(LOADING_PHASES.INIT_DS_CONTAINER)

	return dsAPis
}
