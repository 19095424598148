import { ProviderCreator } from '@wix/thunderbolt-ioc'
import type { ViewerAPI, ViewerAPIProvider, IViewerManagerImplementor } from 'thunderbolt-viewer-manager-types'
import { ViewerManagerApiImplementor } from 'thunderbolt-viewer-manager-types'
import { createDefaultViewerAPI } from './defaultViewerAPI'

export const ViewerManagerProvider: ProviderCreator<ViewerAPIProvider> = (container) => {
	const defaultApis = createDefaultViewerAPI()

	return async () => ({
		getViewerAPI() {
			const allImplementors = container.getAll<
				IViewerManagerImplementor<keyof ViewerAPI, keyof ViewerAPI[keyof ViewerAPI]>
			>(ViewerManagerApiImplementor)

			return allImplementors.reduce<ViewerAPI>(
				(apis, implementor) =>
					Object.keys(implementor).reduce(
						(acc, namespace) => ({
							...acc,
							[namespace]: {
								...acc[namespace as keyof ViewerAPI],
								...implementor[namespace as keyof ViewerAPI],
							},
						}),
						apis
					),
				defaultApis as ViewerAPI
			)
		},
	})
}
