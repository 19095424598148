import { BaseDataItem, DataMapName } from '@wix/thunderbolt-becky-types'
import _ from 'lodash'
import { IDocumentDataStore, DocumentDataStore, LanguageParams } from './DocumentDataStore'

interface IDataStore {
	updateLanguage: IDocumentDataStore['updateLanguage']
	updateData: (type: DataMapName, id: string, dataItem: BaseDataItem | undefined) => BaseDataItem | undefined
	updateTranslationData: IDocumentDataStore['updateTranslationData']
	getData: <T extends BaseDataItem = BaseDataItem>(type: DataMapName, id: string) => T | undefined
}

export class DataStore implements IDataStore {
	private readonly documentDataStore: IDocumentDataStore = new DocumentDataStore()
	private readonly rawModel: Record<string, any> = {}

	updateLanguage(params: Partial<LanguageParams>) {
		return this.documentDataStore.updateLanguage(params)
	}

	updateData(mapName: string, id: string, dataItem: BaseDataItem | undefined) {
		if (mapName === 'document_data') {
			return this.documentDataStore.updateData(id, dataItem)
		}
		_.set(this.rawModel, [mapName, id], dataItem)
		return dataItem
	}

	updateTranslationData(language: string, id: string, dataItem: BaseDataItem | undefined) {
		return this.documentDataStore.updateTranslationData(language, id, dataItem)
	}

	getData<T extends BaseDataItem = BaseDataItem>(mapName: string, id: string): T | undefined {
		if (mapName === 'document_data') {
			return this.documentDataStore.getData<T>(id)
		}
		return _.get(this.rawModel, [mapName, id]) as T
	}
}
