import { SingleLayoutData as SingleLayoutDataType } from '@wix/thunderbolt-becky-types'
import { createDataNode } from '../nodeFactory/dataNodeUtils'
import { getSingleLayoutWithVariablesRefs } from '@wix/thunderbolt-becky-root'

export const SingleLayoutData = createDataNode<SingleLayoutDataType, null>({
	getDependencies: () => null,
	toViewItem: (item) => {
		return getSingleLayoutWithVariablesRefs(item)
	},
})
