import type { UnresolvedBreakpointsData } from '@wix/thunderbolt-becky-types'
import { createDataNode } from '../nodeFactory/dataNodeUtils'

export const BreakpointsData = createDataNode({
	getDependencies: () => null,
	toViewItem: (dataItem: UnresolvedBreakpointsData, deps: any) => {
		if (dataItem.values.length === 0) {
			return undefined
		}
		return { ...dataItem, ...deps }
	},
})
