import { Style, ColorMap, ThemeFontColorMap, ThemeFontMap } from '@wix/thunderbolt-becky-types'
import { skinsInfo, SkinDefaultsData } from './skinsDefaultProperties'
import { deprecatedSkinsFallbacks } from './deprecatedSkinsFallbacks'
import { fontObjectToString, getFontClass, parseFontStr } from '../fonts'
import { color } from '@wix/thunderbolt-commons'
import { fixOriginalFont } from '../fonts/fontFixer'
import { getTagName, getTagAttributes, getInlineStyle, toInlineStyle, toHtmlOpenTag } from '../elementUtils'
import { isUndefined } from 'lodash'
import { componentTypeToSkins } from './compsValidSkins'

const addPx = (value: string): string => (/\d$/.test(value) ? `${value}px` : value)
const fixBorder = (borderValue: string | number): string =>
	String(borderValue)
		.split(' ')
		.map((propValue) => `${parseInt(propValue, 10)}px`)
		.join(' ')
const isThemeFont = (value: string) => /^font_\d+$/.test(value)

const applyPropertiesOverrides = (
	value: string,
	themeFontMap: ThemeFontMap,
	propertiesOverride: { [id: string]: string }
) => {
	const themeFontValue = themeFontMap[value]
	const fontObject = parseFontStr(themeFontValue)
	Object.keys(propertiesOverride).forEach((key) => {
		const overrideValue = propertiesOverride[key]
		if (key === 'fontSize') {
			fontObject.size = parseInt(overrideValue, 10)
		} else if (key === 'fontFamily') {
			fontObject.family = [overrideValue]
		} else if (key === 'fontWeight') {
			fontObject.weight = overrideValue === 'bold' ? '700' : 'normal'
		} else if (key === 'fontStyle') {
			fontObject.style = overrideValue
		}
	})
	const fontString = fontObjectToString(fontObject)
	return fixOriginalFont(fontString)
}

const getProperties = (
	styleProperties: Style['style']['properties'],
	defaultProperties: { types: Record<string, string>; defaults: Record<string, string> },
	themeFontMap?: ThemeFontMap,
	stylePropertiesOverride?: Style['style']['propertiesOverride']
): Record<string, string> => {
	const styleWithDefaults = {
		...defaultProperties.defaults,
		...styleProperties,
	}

	const getMappedAlphaParamValue = (colorParamKey: string): string | null => {
		// color has own alpha value, ignore mapped param
		if (styleWithDefaults[`alpha-${colorParamKey}`]) {
			return null
		}

		const maybeMappedParamKey = defaultProperties.defaults[colorParamKey]
		const isMappedParam = maybeMappedParamKey && styleWithDefaults.hasOwnProperty(maybeMappedParamKey)

		return isMappedParam ? styleWithDefaults[`alpha-${maybeMappedParamKey}`] : null
	}

	const fixThemeColorValue = (colorVal: string) => {
		const match = colorVal.match(/^{(?<themeColor>color_\d+)}$/)
		const themeColor = match?.groups!.themeColor
		return themeColor ? themeColor : colorVal
	}

	const fixDuplicateRgbValue = (colorVal: string) => {
		const match = colorVal.match(/rgb\(rgb\((?<param>\d+,\d+,\d+)\)/)
		const rgbValue = match?.groups!.param
		return rgbValue ? `rgb(${rgbValue})` : colorVal
	}

	return Object.entries(styleWithDefaults).reduce<Record<string, string>>((acc, [key, currentValue]) => {
		let value = currentValue
		const applyOverrides = (themeFont: string) => {
			if (themeFontMap && stylePropertiesOverride && stylePropertiesOverride[key]) {
				return applyPropertiesOverrides(themeFont, themeFontMap, stylePropertiesOverride[key])
			}
			return themeFont
		}

		while (styleWithDefaults[value] && value !== styleWithDefaults[value]) {
			value = styleWithDefaults[value]
		}

		const type = defaultProperties.types[key]
		const fixedKey = key[0] === '$' ? key.slice(1) : key
		switch (type) {
			case 'URL':
				break
			case 'SIZE':
				acc[fixedKey] = addPx(value)
				break
			case 'BORDER':
				acc[fixedKey] = styleProperties && styleProperties[key] ? fixBorder(value) : addPx(value)
				break
			case 'FONT':
				acc[fixedKey] = isThemeFont(value) ? applyOverrides(value) : fixOriginalFont(value)
				break
			case 'COLOR':
				acc[fixedKey] = fixDuplicateRgbValue(fixThemeColorValue(value))
				const mappedAlpha = getMappedAlphaParamValue(key)
				if (mappedAlpha) {
					acc[`alpha-${fixedKey}`] = mappedAlpha
				}
				break
			default:
				acc[fixedKey] = value
		}

		return acc
	}, {})
}

const last = (array: Array<string>) => array[array.length - 1]

export const fixStyleDataItem = (style: Style, componentClassName: string, themeFontMap?: ThemeFontMap): Style => {
	if (!style.skin || style.skin.startsWith('svgshape.')) {
		return style
	}
	let skin = skinsInfo[style.skin] ? style.skin : last(style.skin.split('.'))
	if (deprecatedSkinsFallbacks[skin]) {
		skin = deprecatedSkinsFallbacks[skin]
	}
	if (componentTypeToSkins[componentClassName]) {
		skin =
			componentTypeToSkins[componentClassName].validSkins[skin] ||
			componentTypeToSkins[componentClassName].defaultSkin
	}
	const defaults = skinsInfo[skin] || { types: {}, defaults: {} }
	return {
		...style,
		skin,
		style: {
			...style.style,
			properties: getProperties(style.style?.properties, defaults, themeFontMap, style.style?.propertiesOverride),
		},
	}
}

const shouldIgnoreProp = (prop: string) => {
	const propsToIgnore = ['f0', 'f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f8', 'f9', 'f10']
	return propsToIgnore.includes(prop)
}
const handleThemeColor = (
	acc: Record<string, string | number>,
	prop: string,
	value: string,
	properties: { [id: string]: string }
) => {
	acc[`--${prop}`] = `var(--${value})`
	if (isUndefined(properties[`alpha-${prop}`])) {
		acc[`--alpha-${prop}`] = 1
	}
}
export const getStyleCssVars = (style: Style): Record<string, string | number> => {
	const {
		skin,
		style: { properties },
	} = style
	const skinParamsTypes = skinsInfo[skin] ? skinsInfo[skin].types : {}
	return Object.entries(properties).reduce<Record<string, string | number>>((acc, [prop, value]) => {
		// Check if the original or fixed (i.e. without the $ prefix) key has a type
		const paramType = skinParamsTypes[prop] || skinParamsTypes[`$${prop}`]
		if (shouldIgnoreProp(prop)) {
			return acc
		} else if (prop.startsWith('alpha') || paramType === 'ALPHA') {
			// we need to check if there is the color property for alpha property
			const colorParamValue = properties[`${prop.replace('alpha-', '')}`]
			if (colorParamValue === 'transparent') {
				// and set alpha to 0 when the color is transparent
				acc[`--${prop}`] = 0
			} else {
				acc[`--${prop}`] = parseFloat(value)
			}
		} else if (color.isThemeColor(value)) {
			handleThemeColor(acc, prop, value, properties)
		} else if (isThemeFont(value)) {
			acc[`--${prop}`] = `var(--${value})`
		} else if (paramType === 'COLOR' || (typeof value === 'string' && color.isHexValue(value))) {
			acc[`--${prop}`] = color.getSplitRgbValuesStringWithoutAlpha(value)!
			if (value === 'transparent') {
				// set alpha channel to 0 explicitly when the color is transparent
				acc[`--alpha-${prop}`] = 0
			} else if (isUndefined(properties[`alpha-${prop}`])) {
				acc[`--alpha-${prop}`] = color.getColorAlpha(value)!
			}
		} else if (typeof value === 'string' && value.startsWith('{') && value.endsWith('}')) {
			try {
				acc[`--${prop}`] = JSON.parse(value).value
			} catch (e) {
				const innerVal = value.slice(1, -1)
				// handle the case of {color_x} value
				if (color.isThemeColor(innerVal)) {
					handleThemeColor(acc, prop, innerVal, properties)
				} else {
					acc[`--${prop}`] = value
				}
			}
		} else if (prop.includes('boxShadowToggleOn')) {
			// TODO temp fix until we merge editor-elements
			if (value === 'false') {
				acc[`--${prop}`] = 'none'
			}
		} else if (paramType === 'BOX_SHADOW' && properties[`boxShadowToggleOn-${prop}`] === 'false') {
			// if boxShadowToggleOn is false, override the value for box-shadow with `none`, else, don't override the value.
			acc[`--${prop}`] = 'none'
		} else {
			acc[`--${prop}`] = value
		}

		return acc
	}, {})
}

export const getSkinParams = (skin: string): SkinDefaultsData => {
	return skinsInfo[skin]
}

export const correctInlineColorStyle = (
	html: string,
	overrideColor: string,
	brightness: number,
	colorMap: ColorMap,
	fontColorMap: ThemeFontColorMap
) => {
	return html.replace(/<[^/>]+>/gm, (htmlOpenTag) => {
		const tagName = getTagName(htmlOpenTag)
		const attributes = getTagAttributes(htmlOpenTag)
		const inlineStyles = attributes.style && getInlineStyle(attributes.style)
		const colorClass = attributes.class && color.getColorClass(attributes.class)
		const fontClass = attributes.class && getFontClass(attributes.class)

		let desktopColor: string | undefined

		if (inlineStyles && inlineStyles.color) {
			desktopColor = inlineStyles.color
		} else if (colorClass) {
			desktopColor = color.getFromColorMap(colorClass, colorMap)
		} else if (fontClass && fontColorMap[fontClass]) {
			desktopColor = color.getFromColorMap(fontColorMap[fontClass], colorMap)
		}

		if (!desktopColor) {
			return htmlOpenTag
		}

		const newInlineStyles = inlineStyles ? { ...inlineStyles } : {}

		const isOverrideColorValid = color.isValidColor(overrideColor)
		if (!overrideColor || !isOverrideColorValid) {
			newInlineStyles.color = `${color.brighten(desktopColor, brightness)}`
		} else {
			newInlineStyles.color = color.isRgbValues(overrideColor)
				? `rgba(${overrideColor})`
				: `${color.formatColor(color.getFromColorMap(overrideColor, colorMap), '1')}`
		}

		attributes.style = toInlineStyle(newInlineStyles)

		return toHtmlOpenTag(tagName, attributes)
	})
}
