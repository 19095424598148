import { TextTheme } from '@wix/thunderbolt-becky-types'
import { fontsMetaData } from '@wix/thunderbolt-commons'
import { getFontWithoutColor, parseFontStr } from './fontsParser'

type FontShorthandParts = {
	style: string
	variant: string
	weight: string
	size: string
	family: string
}

const originalFontStringToObject = (fontStr: string): FontShorthandParts => {
	const { style, variant, weight, size, lineHeight, family } = parseFontStr(fontStr)
	const fontFamily = family.join(',')

	return {
		style,
		variant,
		weight,
		size: `${size}px/${lineHeight}`,
		family: fontFamily ? fixFontFamily(fontFamily) : '',
	}
}

export function getFontFamilyWithFallbacks(fontFamily: string): string {
	if (!fontFamily) {
		return ''
	}

	const cleanFontName = fontFamily.replace(/\+/g, ' ').toLowerCase()
	const [highestPriorityFont] = cleanFontName.split(',')
	const metaData = fontsMetaData[highestPriorityFont]
	if (!metaData) {
		return fontFamily
	}
	return [highestPriorityFont, metaData.fallbacks, metaData.genericFamily].filter((t) => t).join(',')
}

const originalFontObjectToString = ({ style, variant, weight, size, family }: FontShorthandParts): string =>
	`${style} ${variant} ${weight} ${size} ${family}`.trim()

export const fixFontWithSpaceOrNotLatinChars = (font: string): string =>
	font.replace(/[^,]*[^\w,\d-][^,]*/g, (fontFamilyStr) => `'${fontFamilyStr.replace(/\+/g, ' ')}'`)

const fixFontFamily = (fontFamily: string): string =>
	fixFontWithSpaceOrNotLatinChars(getFontFamilyWithFallbacks(fontFamily))

export const fixOriginalFont = (font: string): string => {
	const fontWithoutColor = getFontWithoutColor(font)
	const parsedFont = originalFontStringToObject(fontWithoutColor)

	return originalFontObjectToString(parsedFont)
}

export const textThemeToOriginalFont = (textTheme: TextTheme, { withColor = false } = {}): string => {
	const DEFAULT_VALUE = 'normal'
	const { fontSize, fontFamily, lineHeight, fontStyle, fontWeight, fontVariant, color } = textTheme
	const fontSizeAndLineHeight = lineHeight ? `${fontSize}/${lineHeight}` : fontSize
	return [
		fontStyle || DEFAULT_VALUE,
		fontVariant || DEFAULT_VALUE,
		fontWeight || DEFAULT_VALUE,
		fontSizeAndLineHeight,
		fontFamily || '',
		withColor ? color : '',
	]
		.join(' ')
		.trim()
}
