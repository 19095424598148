export function getFontClass(className: string) {
	const findFontClass = /(font_\d+)/
	const fontClass = findFontClass.exec(className)
	return fontClass && fontClass[1]
}

export * from './fonts/fontFixer'
export * from './fonts/fontsParser'
export * from './fonts/getFontFacesCss'
export * from './fonts/getMobileScaledFont'
export * from './fonts/overrideFontSize'
export * from './fonts/getDesktopFontSize'
export * from './fonts/getStyledTextUsedFonts'
