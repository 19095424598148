import type { DomApplier } from '../cssFeatures.types'
import { EMPTY_OBJECT } from '../../utils/constants'
import { fromClassicCss } from '../../utils/fromClassicCss'

export const classicRotationDomApplier: DomApplier<'classicRotation'> = (
	_compId,
	idSelector, // idSelector is the same as `#${compId}` or '#[id^compId__]' if compId is a repeater
	_breakpointId,
	{ classicRotation }
) => {
	if (!classicRotation) {
		return EMPTY_OBJECT
	}

	return fromClassicCss(classicRotation.css, idSelector)
}
