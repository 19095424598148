import {
	Catharsis,
	CatharsisOps,
	getCatharsis,
	getInvalidationHandler,
	MegaStoreWithSubscriptions,
} from '@wix/thunderbolt-catharsis'
import {
	editorCssFeatures,
	componentCssFeaturesProvider,
	IsExperimentOpen,
} from '@wix/thunderbolt-catharsis-extensions'
import { defaultSchemas as schemas } from './defaultSchemas'

type EditorCatharsis = {
	catharsis: Catharsis
	catharsisMegaStore: MegaStoreWithSubscriptions | null
	usedDataMaps: Set<string>
}
type GetEditorCatharsis = (params: IsExperimentOpen) => EditorCatharsis

const CATHARSIS_NOOPS: CatharsisOps = {
	updateData: () => {},
	updateStructure: () => {},
	updateEnvironment: () => {},
	updateTranslation: () => {},
}

const NOOP_CATHARSIS: Catharsis = {
	transaction: (update) => update(CATHARSIS_NOOPS),
}

export const getEditorCatharsis: GetEditorCatharsis = (isExperimentOpen: IsExperimentOpen) => {
	const { componentNodes, usedDataMaps } = componentCssFeaturesProvider(editorCssFeatures, isExperimentOpen)

	if (usedDataMaps.size === 0) {
		return { catharsis: NOOP_CATHARSIS, catharsisMegaStore: null, usedDataMaps: new Set() }
	}

	const catharsisMegaStore = new MegaStoreWithSubscriptions()
	const onInvalidation = getInvalidationHandler(catharsisMegaStore)
	const catharsis = getCatharsis({ onInvalidation, extensions: { componentNodes, schemas } })
	return { catharsis, catharsisMegaStore, usedDataMaps }
}
