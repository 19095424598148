import produce from 'immer'
import { get, set } from 'lodash'
import { SingleLayoutData } from '@wix/thunderbolt-becky-types'

export { getVariableCss } from './responsive/variablesToCss'

const setIn = <T extends Record<string, any>>(obj: T, path: Array<string>, newValue: any): T =>
	produce(obj, (draft) => {
		if (get(draft, path)) {
			set(draft, path, newValue)
		}
	})

const removeSurroundingSlash = (str: string) => str.replace(/(^\/|\/$)/g, '')
const getVariableConnectionPathArr = (variableConnectionPath: string) =>
	removeSurroundingSlash(variableConnectionPath).split('/')

export const getSingleLayoutWithVariablesRefs = (item: SingleLayoutData): SingleLayoutData => {
	if (!item.variableConnections) {
		return item
	}

	let newItem: SingleLayoutData = item
	for (const variableConnection of item.variableConnections) {
		newItem = setIn<SingleLayoutData>(newItem, getVariableConnectionPathArr(variableConnection.path), {
			type: 'VariableReference',
			variableId: variableConnection.variableId,
		})
	}

	return newItem
}
