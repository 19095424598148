import { parseFontFaces } from './fontsParser'

export const languagesFonts = parseFontFaces(`
/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web fonts:  http://webfonts.fonts.com
*/

/* latin */
@font-face {
    font-display: block;
    font-family: "Helvetica-W01-Roman";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ea95b44a-eab7-4bd1-861c-e73535e7f652.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ea95b44a-eab7-4bd1-861c-e73535e7f652.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4021a3b9-f782-438b-aeb4-c008109a8b64.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/669f79ed-002c-4ff6-965c-9da453968504.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d17bc040-9e8b-4397-8356-8153f4a64edf.svg#d17bc040-9e8b-4397-8356-8153f4a64edf") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Helvetica-W01-Bold";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f70da45a-a05c-490c-ad62-7db4894b012a.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f70da45a-a05c-490c-ad62-7db4894b012a.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c5749443-93da-4592-b794-42f28d62ef72.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/73805f15-38e4-4fb7-8a08-d56bf29b483b.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/874bbc4a-0091-49f0-93ef-ea4e69c3cc7a.svg#874bbc4a-0091-49f0-93ef-ea4e69c3cc7a") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Braggadocio-W01";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f305266f-adfb-4e4f-9055-1d7328de8ce6.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f305266f-adfb-4e4f-9055-1d7328de8ce6.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/518e4577-eecc-4ecd-adb4-2ee21df35b20.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f46241ad-1f5d-4935-ad69-b0a78c2e191d.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b2a0a25d-e054-4c65-bffa-e5760b48dec3.svg#b2a0a25d-e054-4c65-bffa-e5760b48dec3") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Clarendon-W01-Medium-692107";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c6993450-d795-4fd3-b306-38481733894c.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c6993450-d795-4fd3-b306-38481733894c.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b6878f57-4d64-4d70-926d-fa4dec6173a5.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0a6b6eff-6b5d-46d4-b681-f356eef1e4c1.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/600b1038-76a8-43b4-a2f7-2a6eb0681f95.svg#600b1038-76a8-43b4-a2f7-2a6eb0681f95") format("svg");
}

@font-face {
    font-display: block;
    font-family: "DIN-Next-W01-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bc176270-17fa-4c78-a343-9fe52824e501.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3516f91d-ac48-42cd-acfe-1be691152cc4.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d1b1e866-a411-42ba-8f75-72bf28e23694.svg#d1b1e866-a411-42ba-8f75-72bf28e23694") format("svg");
}

@font-face {
    font-display: block;
    font-family: "SnellRoundhandW01-Scrip";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/fea0fb7b-884b-4567-a6dc-addb8e67baaa.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/fea0fb7b-884b-4567-a6dc-addb8e67baaa.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/efbfc170-aaf0-4472-91f4-dbb5bc2f4c59.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d974669d-978c-4bcf-8843-b2b7c366d097.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5b2427b5-3c1e-4b17-9f3c-720a332c9142.svg#5b2427b5-3c1e-4b17-9f3c-720a332c9142") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Stencil-W01-Bold";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cc642b17-a005-4f1e-86e8-baffa4647445.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cc642b17-a005-4f1e-86e8-baffa4647445.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a9eddc47-990d-47a3-be4e-c8cdec0090c6.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8d108476-7a62-4664-821f-03c8a522c030.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7823e34c-67dc-467a-bbfb-efbb5f6c90f0.svg#7823e34c-67dc-467a-bbfb-efbb5f6c90f0") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Helvetica-W01-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/717f8140-20c9-4892-9815-38b48f14ce2b.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/717f8140-20c9-4892-9815-38b48f14ce2b.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/03805817-4611-4dbc-8c65-0f73031c3973.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d5f9f72d-afb7-4c57-8348-b4bdac42edbb.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/05ad458f-263b-413f-b054-6001a987ff3e.svg#05ad458f-263b-413f-b054-6001a987ff3e") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Victoria-Titling-MT-W90";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2b6731dc-305d-4dcd-928e-805163e26288.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2b6731dc-305d-4dcd-928e-805163e26288.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/faceff42-b106-448b-b4cf-5b3a02ad61f1.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/82f103e4-7b1c-49af-862f-fe576da76996.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/46f6946a-4039-46e8-b001-be3e53068d46.svg#46f6946a-4039-46e8-b001-be3e53068d46") format("svg");
}

@font-face {
    font-display: block;
    font-family: "AmericanTypwrterITCW01--731025";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dff8aebe-deee-47a7-8575-b2f39c8473f8.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dff8aebe-deee-47a7-8575-b2f39c8473f8.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0c0f4d28-4c13-4e84-9a36-e63cd529ae86.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7f26a278-84b3-4587-bf07-c8cdf7e347a9.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/254ab931-e6d6-4307-9762-5914ded49f13.svg#254ab931-e6d6-4307-9762-5914ded49f13") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Soho-W01-Thin-Condensed";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b2d6b869-3f47-4c92-83d3-4546ffb860d0.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b2d6b869-3f47-4c92-83d3-4546ffb860d0.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f84b539d-ed34-4400-a139-c0f909af49aa.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9ef27401-09c0-434f-b0f0-784445b52ea2.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4ba3546a-32f7-4e7d-be64-8da01b23d46e.svg#4ba3546a-32f7-4e7d-be64-8da01b23d46e") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Pacifica-W00-Condensed";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e50a5bb1-8246-4412-8c27-4a18ba89a0fd.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e50a5bb1-8246-4412-8c27-4a18ba89a0fd.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6849614c-986c-45b1-a1a7-39c891759bb9.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8ccb835c-4668-432d-8d1d-099b48aafe4e.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/028040ec-b956-41d8-a07d-b4d3466b8ed8.svg#028040ec-b956-41d8-a07d-b4d3466b8ed8") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Avenida-W01";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a8138b05-e5ff-482f-a8f6-8be894e01fc3.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a8138b05-e5ff-482f-a8f6-8be894e01fc3.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/53f05821-c783-4593-bf20-c3d770f32863.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b7215bbe-7870-4733-9e81-28398fbed38b.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bc2def79-bd99-49b9-98b3-502e34cc5552.svg#bc2def79-bd99-49b9-98b3-502e34cc5552") format("svg");
}

@font-face {
    font-display: block;
    font-family: "ITC-Arecibo-W01-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/036d6c0b-d067-431a-ab39-be3b89b1322f.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/036d6c0b-d067-431a-ab39-be3b89b1322f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5d6cd606-b520-4335-96e1-755691d666e8.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/03d7d783-5b99-4340-b373-97c00246ec27.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a5fab48f-93a0-403a-b60e-bfdb0b69d973.svg#a5fab48f-93a0-403a-b60e-bfdb0b69d973") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Droid-Serif-W01-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/de5702ce-174b-4ee6-a608-6482d5d7eb71.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/de5702ce-174b-4ee6-a608-6482d5d7eb71.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/83ae2051-dcdd-4931-9946-8be747a40d00.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/63f35b58-a40f-4f53-bb3e-20396f202214.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/19db6ebc-2d1b-4835-9588-3fa45ff36f4e.svg#19db6ebc-2d1b-4835-9588-3fa45ff36f4e") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Museo-W01-700";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3b3d99a2-6b36-4912-a93e-29277020a5cf.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3b3d99a2-6b36-4912-a93e-29277020a5cf.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/28d74e9b-4ea9-4e3c-b265-c67a72c66856.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b432b4e1-014a-4ed8-865c-249744f856b0.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8e63fd1e-adc9-460e-9ef7-bbf98ee32a71.svg#8e63fd1e-adc9-460e-9ef7-bbf98ee32a71") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Museo-Slab-W01-100";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/01ab709f-22cf-4831-b24a-8cf4eb852687.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/01ab709f-22cf-4831-b24a-8cf4eb852687.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cacc0862-f146-4746-92b1-60e6114a66c4.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/54006f54-b61e-4103-abf8-b1d0294a2a9c.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e5841f2e-b306-4583-bfc3-a0e06742fedd.svg#e5841f2e-b306-4583-bfc3-a0e06742fedd") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Geotica-W01-Four-Open";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/91cc94e6-2c98-4b42-aaec-086abb6a9370.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/91cc94e6-2c98-4b42-aaec-086abb6a9370.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cc0b2292-9358-41ee-b3b9-429952586f69.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8558b493-da55-4e6f-b473-56d9c7dff2a4.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ee507e8d-069a-4cb6-b184-62b1f3ab0102.svg#ee507e8d-069a-4cb6-b184-62b1f3ab0102") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Marzo-W00-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/45563891-20ab-4087-b16c-b3cfc26faac1.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/45563891-20ab-4087-b16c-b3cfc26faac1.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e947b76a-edcf-4519-bc3d-c2da35865717.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c09cb36e-5e79-4033-b854-41e57fbf44fa.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f4245069-28b7-43b7-8a10-708b9f3c398b.svg#f4245069-28b7-43b7-8a10-708b9f3c398b") format("svg");
}

@font-face {
    font-display: block;
    font-family: "ReklameScriptW00-Medium";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5af7511a-dccc-450d-b2a2-bb8e3bb62540.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5af7511a-dccc-450d-b2a2-bb8e3bb62540.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/05b176f5-c622-4c35-af98-c0c056dd5b66.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9dcb5a3c-1c64-4c9c-a402-995bed762eb7.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/1e87d66a-d7f1-4869-8430-51662777be57.svg#1e87d66a-d7f1-4869-8430-51662777be57") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Nimbus-Sans-TW01Con";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2101adaa-6a98-4fa9-b085-3b04c3722637.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2101adaa-6a98-4fa9-b085-3b04c3722637.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8fb1090e-b4d0-4685-ac8f-3d0c29d60130.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5d5fc44e-e84b-48ca-a5a7-bed8bdbf79a1.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/24512b2a-e22d-4ebb-887c-a334d039433c.svg#24512b2a-e22d-4ebb-887c-a334d039433c") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Bodoni-W01-Poster";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ab0e3d15-2f64-49c1-8898-817a2235e719.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ab0e3d15-2f64-49c1-8898-817a2235e719.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4d1b9848-7ebd-472c-9d31-4af0aa7faaea.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/197b5ef7-65e6-4af6-9fd9-bc9fc63038c7.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a9bda117-c293-40b5-a2d8-9f923f32623c.svg#a9bda117-c293-40b5-a2d8-9f923f32623c") format("svg");
}

/* Mobile system fallbacks */

@font-face {
    font-display: block;
    font-family: "Comic-Sans-W01-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0d67e115-f220-4a6a-81c2-ae0035bda922.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0d67e115-f220-4a6a-81c2-ae0035bda922.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/234c98b8-36ae-45ab-8a55-77980708b2bc.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7ff2970c-1d51-47be-863d-b33afda8fb40.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f1afa7d8-abee-4268-9cf8-85d43150fdb1.svg#f1afa7d8-abee-4268-9cf8-85d43150fdb1") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Courier-PS-W01";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b2977c8d-4907-4cc3-b5ed-3dec9ca68102.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b2977c8d-4907-4cc3-b5ed-3dec9ca68102.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b059d02a-a222-4c63-9fd3-705eaeea1c16.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/52e3d484-7188-4c9e-964e-b7a75e9dfa2f.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c2182c36-8eb4-4a56-a0ff-dba7492ce96c.svg#c2182c36-8eb4-4a56-a0ff-dba7492ce96c") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Impact-W01-2010";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9ae7059b-dd17-4a4c-8872-5cb4dd551277.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9ae7059b-dd17-4a4c-8872-5cb4dd551277.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4cefdf47-0136-4169-9933-3225dbbec9d9.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f7450934-42f3-4193-befa-c825772a9454.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/25aa5449-ccc1-4f0e-ab3f-4cf3b959208f.svg#25aa5449-ccc1-4f0e-ab3f-4cf3b959208f") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Lucida-Console-W01";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/527cb305-deee-4810-b337-67756678c830.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/527cb305-deee-4810-b337-67756678c830.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/60f4a13f-3943-432a-bb51-b612e41239c5.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/38229089-ebec-4782-b8f2-304cfdcea8d8.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f855783c-1079-4396-a7b7-f7d9179145be.svg#f855783c-1079-4396-a7b7-f7d9179145be") format("svg");
}

@font-face {
    font-display: block;
    font-family:"Tahoma-W01-Regular";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/1b155b1c-e651-4a51-9d03-0ca480aeaf9f.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/1b155b1c-e651-4a51-9d03-0ca480aeaf9f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/20323430-24f4-4767-9d4d-060d1e89758a.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6e17e62d-30cb-4840-8e9d-328c6b62316e.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/04ab3894-45ce-42ef-aa11-e7c0cd7f7da9.svg#04ab3894-45ce-42ef-aa11-e7c0cd7f7da9") format("svg");
}

@font-face {
    font-display: block;
    font-family:"Arial-W01-Black";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bf85e414-1b16-4cd1-8ce8-dad15daa7daa.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bf85e414-1b16-4cd1-8ce8-dad15daa7daa.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c6f5bcd6-66fc-44af-be95-bb1f2b38d080.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7b5b436b-a511-402a-88d6-dbac700cee36.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a5adcd35-c36a-4b18-953d-47c029de4ef6.svg#a5adcd35-c36a-4b18-953d-47c029de4ef6") format("svg");
}

/* Greek and others */

@font-face {
    font-display: block;
    font-family:"Tahoma-W15--Regular";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f0dd334c-878c-442c-bda3-0dbd122e87f1.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f0dd334c-878c-442c-bda3-0dbd122e87f1.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ae844b11-5158-4caf-90b4-7ace49ac3440.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e63ef4f4-b7b9-4f13-8db5-d7f5cf89839f.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5ad2ed93-1ba0-4b2c-a421-22c5bcfb5b79.svg#5ad2ed93-1ba0-4b2c-a421-22c5bcfb5b79") format("svg");
}

@font-face {
    font-display: block;
    font-family:"Tahoma-W99-Regular";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c3ebf729-2608-4787-9e5a-248f329aface.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c3ebf729-2608-4787-9e5a-248f329aface.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d3bbaa1b-d5e3-431f-93a7-9cea63601bb6.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/78cb924f-227d-4ab9-83d5-f2b2e6656da5.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ca045297-34e1-462e-acc8-75ef196ada02.svg#ca045297-34e1-462e-acc8-75ef196ada02") format("svg");
}

/*******************/

@font-face {
    font-display: block;
    font-family: "Coquette-W00-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b8c1ddea-29ea-42ec-8a48-32a89e792d3b.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b8c1ddea-29ea-42ec-8a48-32a89e792d3b.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4e5374b3-a214-41e5-81f0-a34c9292da7e.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c20581c8-0023-4888-aeaa-9d32636dc17f.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e514ed32-1687-47d8-8b39-423fb7664d33.svg#e514ed32-1687-47d8-8b39-423fb7664d33") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Rosewood-W01-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bd86870e-0679-4116-aa13-96aa1d6c5944.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bd86870e-0679-4116-aa13-96aa1d6c5944.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4d9bc879-ab51-45da-bf37-c9710cd1cc32.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a5564fdb-3616-4f27-a4e4-d932b6ae5b4a.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f128ef28-daf3-477b-8027-0fd8bdad2b91.svg#f128ef28-daf3-477b-8027-0fd8bdad2b91") format("svg");
}

@font-face {
    font-display: block;
    font-family: "segoe_printregular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/segoe_print-webfont.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/segoe_print-webfont.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/segoe_print-webfont.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/segoe_print-webfont.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/segoe_print-webfont.svg#f128ef28-daf3-477b-8027-0fd8bdad2b91") format("svg");
}

@font-face {
    font-display: block;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-regular-webfont.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-regular-webfont.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-regular-webfont.ttf') format('truetype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-regular-webfont.svg#open_sansregular') format('svg');
}

@font-face {
    font-display: block;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bold-webfont.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bold-webfont.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bold-webfont.ttf') format('truetype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bold-webfont.svg#open_sansregular') format('svg');
}

@font-face {
    font-display: block;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-italic-webfont.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-italic-webfont.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-italic-webfont.ttf') format('truetype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-italic-webfont.svg#open_sansregular') format('svg');
}

@font-face {
    font-display: block;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bolditalic-webfont.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bolditalic-webfont.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bolditalic-webfont.ttf') format('truetype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-bolditalic-webfont.svg#open_sansregular') format('svg');
}

@font-face {
    font-display: block;
    font-family:"Avenir-LT-W01_35-Light1475496";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b0268c31-e450-4159-bfea-e0d20e2b5c0c.svg#b0268c31-e450-4159-bfea-e0d20e2b5c0c") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Avenir-LT-W01_85-Heavy1475544";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/20577853-40a7-4ada-a3fb-dd6e9392f401.svg#20577853-40a7-4ada-a3fb-dd6e9392f401") format("svg");
}
@font-face {
    font-display: block;
    font-family:"BaskervilleMTW01-SmBdIt";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0224f3fd-52d3-499a-ae2f-637a041f87f0.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0224f3fd-52d3-499a-ae2f-637a041f87f0.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c887df8e-b6c3-4c97-85b8-91cfdde77b07.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5c4d5432-75c4-4f6b-a6e7-8af4d54a33d1.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2ee46b89-b7cb-4bbe-9d60-b7ca4354b706.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9714d635-13b3-48b2-9315-5d0f72a69ab9.svg#9714d635-13b3-48b2-9315-5d0f72a69ab9") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Belinda-W00-Regular";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/63d38753-a8d9-4262-b844-3a007ad848b4.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/63d38753-a8d9-4262-b844-3a007ad848b4.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/242487aa-209a-4dbd-aca2-64a3c73a8946.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4381b252-d9f8-4201-bbf3-9440e21259e7.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/46c2c4f2-cd15-4b7b-a4b4-aa04dbbd1655.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/334f8999-3703-47f3-b147-96b6dc3bf4b2.svg#334f8999-3703-47f3-b147-96b6dc3bf4b2") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Brandon-Grot-W01-Light";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e00ba30a-0bf3-4c76-9392-8641fa237a92.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e00ba30a-0bf3-4c76-9392-8641fa237a92.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/69b40392-453a-438a-a121-a49e5fbc9213.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9362bca5-b362-4543-a051-2129e2def911.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/85060878-ca6c-43cc-ac31-7edccfdca71b.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/47f089a6-c8ce-46fa-b98f-03b8c0619d8a.svg#47f089a6-c8ce-46fa-b98f-03b8c0619d8a") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Bree-W01-Thin-Oblique";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4e33bf74-813a-4818-8313-6ea9039db056.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4e33bf74-813a-4818-8313-6ea9039db056.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ceb3b4a3-0083-44ae-95cb-e362f95cc91b.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4d716cea-5ba0-437a-b5a8-89ad159ea2be.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c458fc09-c8dd-4423-9767-e3e27082f155.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/85ffb31e-78ee-4e21-83d8-4313269135a9.svg#85ffb31e-78ee-4e21-83d8-4313269135a9") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Adobe-Caslon-W01-SmBd";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d2804130-67b0-4fcf-98fe-d781df92a56e.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d2804130-67b0-4fcf-98fe-d781df92a56e.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/becfadb1-eaca-4817-afbd-fe4d61e1f661.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6530bac7-21ac-4e52-a014-dce6a8d937ab.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b34e8a45-c92d-4402-89bc-43cc51c6b4e0.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0804bb4a-399c-4547-9fa8-0acf6268d8f6.svg#0804bb4a-399c-4547-9fa8-0acf6268d8f6") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Didot-W01-Italic";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9c2cfd19-472b-4ef5-ad73-43eee68dc43b.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9c2cfd19-472b-4ef5-ad73-43eee68dc43b.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/abe3d3a9-c990-459f-9407-54ac96cd2f00.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/09a4b57b-7400-4d30-b4ba-d6e303c57868.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a0cb08d5-975f-4c8d-bcdc-d771a014d92a.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/86d60a09-e8f8-4003-b688-0f8a2aba6532.svg#86d60a09-e8f8-4003-b688-0f8a2aba6532") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Futura-LT-W01-Light";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/26091050-06ef-4fd5-b199-21b27c0ed85e.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cca525a8-ad89-43ae-aced-bcb49fb271dc.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/88cc7a39-1834-4468-936a-f3c25b5d81a1.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/14d6bf5d-15f1-4794-b18e-c03fb9a5187e.svg#14d6bf5d-15f1-4794-b18e-c03fb9a5187e") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Futura-LT-W01-Book";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Kepler-W03-Light-SCd-Cp";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/fc00d5ae-6d96-4b5c-b68b-4f5bdb562c98.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/fc00d5ae-6d96-4b5c-b68b-4f5bdb562c98.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b2b1472c-55f2-478a-a9c9-9373214a27e5.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/20f7861b-3ff7-47f3-b0f5-1e0626824a63.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5c51e7a0-6ecc-46eb-a9ee-376f8c4073af.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/37bea6d0-2f14-4e48-a76f-fd85171dcf26.svg#37bea6d0-2f14-4e48-a76f-fd85171dcf26") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Lulo-Clean-W01-One-Bold";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0163ac22-50a7-406e-aa64-c62ee6fbf3d7.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0163ac22-50a7-406e-aa64-c62ee6fbf3d7.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/aee74cb3-c913-4b54-9722-6001c92325f2.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/80de9d5d-ab5f-40ce-911b-104e51e93d7c.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/1b46b05b-cfdd-4d82-8c2f-5c6cfba1fe60.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/be340f0b-a2d4-41df-acb1-4dc124330a88.svg#be340f0b-a2d4-41df-acb1-4dc124330a88") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Proxima-N-W01-Reg";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7e90123f-e4a7-4689-b41f-6bcfe331c00a.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7e90123f-e4a7-4689-b41f-6bcfe331c00a.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/64017d81-9430-4cba-8219-8f5cc28b923e.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e56ecb6d-da41-4bd9-982d-2d295bec9ab0.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2aff4f81-3e97-4a83-9e6c-45e33c024796.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ab9cd062-380f-4b53-b1a7-c0bec7402235.svg#ab9cd062-380f-4b53-b1a7-c0bec7402235") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Trend-Sans-W00-Four";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/392aa98f-a1bf-4dc4-9def-a5d27c73a0de.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/392aa98f-a1bf-4dc4-9def-a5d27c73a0de.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/29c66f1e-5243-4f34-8a19-47405f72954c.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c81cca03-5f1a-4252-9950-096e60e2bde9.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/569b48ce-f71b-4e2d-a80a-1920efbd7187.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f284409f-3669-43df-bfe1-f9f9ee1bbc07.svg#f284409f-3669-43df-bfe1-f9f9ee1bbc07") format("svg");
}

@font-face {
    font-display: block;
    font-family:"DINNeuzeitGroteskLTW01-_812426";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b41558bd-2862-46c0-abf7-536d2542fa26.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b41558bd-2862-46c0-abf7-536d2542fa26.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5cee8d6e-89ad-4d8c-a0ac-584d316b15ae.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/388ef902-2c31-4818-abb1-a40dcd81f6d6.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/29c60077-2614-4061-aa8d-5bcfdf7354bb.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/76250d27-b353-4f3b-90c6-0ff635fabaab.svg#76250d27-b353-4f3b-90c6-0ff635fabaab") format("svg");
}
@font-face {
    font-display: block;
    font-family:"Peaches-and-Cream-Regular-W00";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2250f930-9a6d-4486-a0eb-6b407c5d6c9b.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2250f930-9a6d-4486-a0eb-6b407c5d6c9b.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3c5beda8-45cc-4f76-abca-8eccfeb6220c.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b36b499f-d776-461c-bacb-fcbebe1cb9b4.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/896d3828-26ce-462a-9f70-62e0b5c90a70.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0dccbec2-a882-4dd9-8da0-3a035c808ce0.svg#0dccbec2-a882-4dd9-8da0-3a035c808ce0") format("svg");
}

/* latin-ext */

@font-face {
    font-display: block;
    font-family: "Helvetica-W02-Roman";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e4bd4516-4480-43df-aa6e-4e9b9029f53e.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e4bd4516-4480-43df-aa6e-4e9b9029f53e.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b56b944e-bbe0-4450-a241-de2125d3e682.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7da02f05-ae8b-43a1-aeb9-83b3c0527c06.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/66cac56e-d017-4544-9d0c-f7d978f0c5c2.svg#66cac56e-d017-4544-9d0c-f7d978f0c5c2") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Helvetica-W02-Bold";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8c0d8b0f-d7d6-4a72-a418-c2373e4cbf27.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8c0d8b0f-d7d6-4a72-a418-c2373e4cbf27.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/192dac76-a6d9-413d-bb74-22308f2e0cc5.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/47584448-98c4-436c-89b9-8d6fbeb2a776.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/375c70e5-6822-492b-8408-7cd350440af7.svg#375c70e5-6822-492b-8408-7cd350440af7") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Clarendon-W02-Medium-693834";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/be976e1d-6edc-4a1b-b50e-a6d326b5a02f.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/be976e1d-6edc-4a1b-b50e-a6d326b5a02f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/01681361-4a95-4651-a6c8-4005d0fc4a79.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/fdc26895-148e-4fa8-898d-5eb459dabecf.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d59136e2-c38a-4ad4-8dec-2d258c12fd80.svg#d59136e2-c38a-4ad4-8dec-2d258c12fd80") format("svg");
}

@font-face {
    font-display: block;
    font-family: "DIN-Next-W02-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/48e5a0e1-2d56-46e5-8fc4-3d6d5c973cbf.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/48e5a0e1-2d56-46e5-8fc4-3d6d5c973cbf.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/07d62b21-8d7a-4c36-be86-d32ab1089972.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c0050890-bbed-44b9-94df-2611d72dbb06.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9f774d17-c03a-418e-a375-34f3beecbc7a.svg#9f774d17-c03a-418e-a375-34f3beecbc7a") format("svg");
}

@font-face {
    font-display: block;
    font-family: "AmericanTypwrterITCW02--737091";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/04bcef92-4ec9-4491-9f06-433987df0eea.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/04bcef92-4ec9-4491-9f06-433987df0eea.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4e5713c6-f9bf-44d7-bc17-775b7c102f1c.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/80e40eb9-815f-4b42-9e99-297117a6ef52.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/25bb0c90-be7f-4dfc-bfff-5cd159d09832.svg#25bb0c90-be7f-4dfc-bfff-5cd159d09832") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Helvetica-W02-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ff80873b-6ac3-44f7-b029-1b4111beac76.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ff80873b-6ac3-44f7-b029-1b4111beac76.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/80c34ad2-27c2-4d99-90fa-985fd64ab81a.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b8cb02c2-5b58-48d8-9501-8d02869154c2.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/92c941ea-2b06-4b72-9165-17476d424d6c.svg#92c941ea-2b06-4b72-9165-17476d424d6c") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Soho-W02-Thin-Condensed";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/61d1e245-4016-4f23-ad7a-409a44cabe90.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/61d1e245-4016-4f23-ad7a-409a44cabe90.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/68eb3cfd-be6c-4f9e-8ca4-e13ce8d29329.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6db04d94-de0b-4bf0-bf94-113fd27e7440.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/84e110e9-fd29-4036-b9ad-27968a6ba793.svg#84e110e9-fd29-4036-b9ad-27968a6ba793") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Avenida-W02";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/131201ff-3951-4583-b4d9-2bc668927583.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/131201ff-3951-4583-b4d9-2bc668927583.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/582278da-0505-4fbe-9102-2b529c7c973a.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b82fbde2-1655-4728-96a9-90ef022c4590.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d325b64f-214b-4fc0-80d6-4c61621cd542.svg#d325b64f-214b-4fc0-80d6-4c61621cd542") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Droid-Serif-W02-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/872d17cf-70ac-4722-b8ef-0f1578125042.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/872d17cf-70ac-4722-b8ef-0f1578125042.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/764779cf-076d-427a-87b4-136ccc83fba0.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f32cc734-5673-41a1-bd6e-1900c78691f5.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f627e21b-c1a5-4e7a-b072-37391ee8dcef.svg#f627e21b-c1a5-4e7a-b072-37391ee8dcef") format("svg");
}

/* Mobile system fallbacks */

@font-face {
    font-display: block;
    font-family: "Comic-Sans-W02-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6a8b1499-f534-46fe-a0af-835bc83226cd.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6a8b1499-f534-46fe-a0af-835bc83226cd.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/301e2ea2-8153-453c-9051-0a729098e682.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cbe882c0-2ac0-4a28-8fe7-14e527942047.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ec680f09-6b07-442d-ab11-cea208cc138d.svg#ec680f09-6b07-442d-ab11-cea208cc138d") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Courier-PS-W02";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/440875eb-e437-41fc-8aae-b5658bbea3b7.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/440875eb-e437-41fc-8aae-b5658bbea3b7.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bcc470b9-5a9b-45e9-bf60-6daca06bc70e.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f9018056-f579-4c4c-95ea-9bd02b859724.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f6d0e775-b2fc-4bc8-8967-cd78486d451c.svg#f6d0e775-b2fc-4bc8-8967-cd78486d451c") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Impact-W02-2010";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/02fb45ce-d7cc-491c-a176-e2a883a632b2.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/02fb45ce-d7cc-491c-a176-e2a883a632b2.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/eb1185bb-8f9d-4855-83fa-d06f0efef677.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5386718d-bec6-41c5-b998-12dd1c859c53.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/01aba52b-d32b-41fc-a681-d7b4dfa43041.svg#01aba52b-d32b-41fc-a681-d7b4dfa43041") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Lucida-Console-W02";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5606db21-eb18-48fa-979b-63bdf28555c0.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5606db21-eb18-48fa-979b-63bdf28555c0.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e5b24ea-4345-4830-8c7d-0e7ef26b4e63.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7b175f51-ff6c-47d2-908c-ee8538c2880d.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bd1a6a52-e0c2-4a1b-b5a4-2d25ec2b5706.svg#bd1a6a52-e0c2-4a1b-b5a4-2d25ec2b5706") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Tahoma-W02-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bb223dcd-0a16-4e5a-b38b-4f2a29f2bcbb.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bb223dcd-0a16-4e5a-b38b-4f2a29f2bcbb.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/94e45703-fbd7-46e5-9fcd-228ae59d6266.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ef282b80-58de-4b03-a90f-c5703d54e3b7.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cb11dfc6-f3c4-4a58-83ac-df735ba9c428.svg#cb11dfc6-f3c4-4a58-83ac-df735ba9c428") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Rosewood-W08-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f432fcbc-ae52-4db1-a4b3-c7145e69b3b6.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f432fcbc-ae52-4db1-a4b3-c7145e69b3b6.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/62a23651-230c-4724-b2c0-087544ed1a27.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e46f32da-eb61-45e5-a1b8-49f3a5f782f8.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9e73fac6-b45b-4ac5-a601-9a0d70cd45b2.svg#9e73fac6-b45b-4ac5-a601-9a0d70cd45b2") format("svg");
}

@font-face {
    font-display: block;
    font-family:"Arial-W02-Black";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c06088a2-994d-44b4-9b38-55d2ae9e13a9.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c06088a2-994d-44b4-9b38-55d2ae9e13a9.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/41280d6d-9240-4d82-9e98-3ea1a1913501.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bc33f504-28ac-46ae-b258-d4fd1f599c47.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0faa6338-fe10-42e6-9346-2c6626ddcd73.svg#0faa6338-fe10-42e6-9346-2c6626ddcd73") format("svg");
}

/* cyrillic */
@font-face {
    font-display: block;
    font-family: "DIN-Next-W10-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3d009cd7-c8fe-40c0-93da-74f4ea8c530b.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3d009cd7-c8fe-40c0-93da-74f4ea8c530b.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a9e95a29-98a7-404a-90ee-1929ad09c696.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0a7663fd-eae8-4e50-a67a-225271f8cceb.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/58ae9be9-5d95-44b6-8b6c-e6da6a46822c.svg#58ae9be9-5d95-44b6-8b6c-e6da6a46822c") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Helvetica-LT-W10-Bold";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9fe262dc-5a55-4d75-91a4-aed76bd32190.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9fe262dc-5a55-4d75-91a4-aed76bd32190.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0a3939d0-3833-4db3-8b85-f64c2b3350d2.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/1b128d6d-126f-4c9c-8f87-3e7d30a1671c.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b791c850-fde1-48b3-adf0-8998d55b0866.svg#b791c850-fde1-48b3-adf0-8998d55b0866") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Helvetica-LT-W10-Roman";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/686a6a06-e711-4bd2-b393-8504a497bb3c.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/686a6a06-e711-4bd2-b393-8504a497bb3c.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6f8d1983-4d34-4fa4-9110-988f6c495757.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7903ee3f-e9ab-4bdc-b7d2-d232de2da580.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9c58e9ea-fdea-4b9c-b0f9-0a2157389ed0.svg#9c58e9ea-fdea-4b9c-b0f9-0a2157389ed0") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Bodoni-Poster-W10";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3f2ac2b4-0662-446d-8b8a-51738492ea04.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3f2ac2b4-0662-446d-8b8a-51738492ea04.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e04da7b7-ccbf-4cbf-b19a-947551d17de6.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cb3483cd-190a-4634-b345-d88f22f1e6f5.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/fea2ad42-3ce1-43c4-b571-39159cc109a6.svg#fea2ad42-3ce1-43c4-b571-39159cc109a6") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Droid-Serif-W10-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d039cfc8-41eb-46d5-ad1a-faf4f0d26222.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d039cfc8-41eb-46d5-ad1a-faf4f0d26222.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/571d67cb-de3d-41af-8c0a-06a53d490466.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/70f941f5-d702-4e7b-8b15-ee65e6b855b9.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e0ffd68e-55a8-4537-b2d1-c51865ac15ee.svg#e0ffd68e-55a8-4537-b2d1-c51865ac15ee") format("svg");
}

/* Mobile system fallbacks */

@font-face {
    font-display: block;
    font-family: "Comic-Sans-W10-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6ea78fbd-72da-406c-bd23-556297e62ebb.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6ea78fbd-72da-406c-bd23-556297e62ebb.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/73381861-eb6a-4f7c-8c14-cd34a714f943.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/93adf2cf-f54c-4a73-8ec7-43fe0b2c91a1.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a89d4dba-ce62-4aaa-8187-bea28a2b3a90.svg#a89d4dba-ce62-4aaa-8187-bea28a2b3a90") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Courier-PS-W10";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/efcef8d3-4e54-4965-a5f0-67f288d40c0a.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/efcef8d3-4e54-4965-a5f0-67f288d40c0a.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2593bfe2-2f34-4218-a1e2-fde3bdc686e1.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e84e67b7-d822-43f4-80a9-315fddffd712.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7bbc8148-b9e2-49ad-bd2a-b6a8ba78efa6.svg#7bbc8148-b9e2-49ad-bd2a-b6a8ba78efa6") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Impact-W10-2010";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2f6579cb-5ade-4b70-a96a-8fe9485fe73f.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2f6579cb-5ade-4b70-a96a-8fe9485fe73f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/1c7b5ef1-5b09-4473-8003-a974846653a7.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/805172a0-d718-48ac-9053-873641b3e236.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/1979f98e-b074-4385-81b0-772f28a00668.svg#1979f98e-b074-4385-81b0-772f28a00668") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Lucida-Console-W10-0";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e9678295-b67a-4b01-bfb4-a357fa5dd939.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e9678295-b67a-4b01-bfb4-a357fa5dd939.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2c0bffef-a666-4646-a4bc-7faf1fa689f5.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4696d714-e3c0-4351-9df8-2e4449d30c3d.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/936712ee-6903-4ab8-b6ad-99e7ca1721d0.svg#936712ee-6903-4ab8-b6ad-99e7ca1721d0") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Tahoma-W10-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/50c03b26-fd3d-4fa1-96d0-d88b72129c4f.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/50c03b26-fd3d-4fa1-96d0-d88b72129c4f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9ee00678-b6d7-4b4f-8448-70cfa267d36b.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6861fb60-a657-44e2-92fa-86bd26cd2657.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bf5f466c-6f51-449d-91b3-32f1f0c2b796.svg#bf5f466c-6f51-449d-91b3-32f1f0c2b796") format("svg");
}

@font-face {
    font-display: block;
    font-family:"Arial-W10-Black";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/26819459-0b68-486b-ae05-2ecdbd222feb.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/26819459-0b68-486b-ae05-2ecdbd222feb.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7cfb7eb0-2332-4048-a7f4-2c3fa389c3a3.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2edafc36-cb97-4b1a-8803-a7b2e6125929.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/00760470-0987-4c68-844d-564282fc8ff9.svg#00760470-0987-4c68-844d-564282fc8ff9") format("svg");
}

/* korean */
@font-face {
    font-display: block;
    font-family: "FBBlueGothicL";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/902109a4-ea36-40b3-a234-05747684a610.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/902109a4-ea36-40b3-a234-05747684a610.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/1a10c1c0-157a-4f57-96c1-1af2fc242e06.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/31b02a27-3c41-4593-bfbf-84702627c9fd.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e4aaa0ee-fcdd-4558-9d8e-be75c6b8c417.svg#e4aaa0ee-fcdd-4558-9d8e-be75c6b8c417") format("svg");
}

@font-face {
    font-display: block;
    font-family: "FBChamBlue";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3df084ed-47e4-4347-91c7-44d290c2c093.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3df084ed-47e4-4347-91c7-44d290c2c093.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/75c0e570-e4e0-4e86-a031-1ade01e5b3f5.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b9eacc4f-ef9b-4ff5-bf09-ffca8edc43e2.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/12333ad5-3ac0-4a7d-b109-6d8c8101515a.svg#12333ad5-3ac0-4a7d-b109-6d8c8101515a") format("svg");
}

@font-face {
    font-display: block;
    font-family: "FBGreen";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/89bbab6a-1291-4439-9384-bc7e36aae8e0.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/89bbab6a-1291-4439-9384-bc7e36aae8e0.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/399c1f00-ff31-4f87-868c-bcbfcabcdd51.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ad6cc8e5-052c-4d6c-ab7b-66b2f70edb5f.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0045c511-e366-4e81-bd42-131808ac750f.svg#0045c511-e366-4e81-bd42-131808ac750f") format("svg");
}

@font-face {
    font-display: block;
    font-family: "FBNeoGothic";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3323e5a2-cefa-4887-9de9-9fd287987664.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3323e5a2-cefa-4887-9de9-9fd287987664.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c52a9d59-984f-45b4-bfd7-6f6af54eb89f.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/58e6245d-754a-4a05-9bd2-25a655e31640.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/29c28905-cc56-4077-86f5-917ad8e34c1c.svg#29c28905-cc56-4077-86f5-917ad8e34c1c") format("svg");
}

@font-face {
    font-display: block;
    font-family: "FBPlum";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/837a8a7f-bb60-42ed-a5bc-c9368cc1ecba.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/837a8a7f-bb60-42ed-a5bc-c9368cc1ecba.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/101fd386-ed60-4ed9-8ac2-80d0492347ac.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5539addf-e60b-4fba-a356-cbab8abd16c6.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6329eea0-a953-45d7-9074-6f0594e27df7.svg#6329eea0-a953-45d7-9074-6f0594e27df7") format("svg");
}

@font-face {
    font-display: block;
    font-family: "NanumGothic-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/nanum-gothic-regular.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/nanum-gothic-regular.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/nanum-gothic-regular.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/nanum-gothic-regular.ttf") format("truetype");
}

@font-face {
    font-display: block;
    font-family: "BM-HANNA";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/bm-hanna.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/bm-hanna.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/bm-hanna.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/bm-hanna.ttf") format("truetype");
}

/* arabic */
@font-face {
    font-display: block;
    font-family: "AhmedLTW20-OutlineRegul";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3b019dda-5201-4a96-ab40-449f0785e578.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3b019dda-5201-4a96-ab40-449f0785e578.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bc9495bd-5edc-4c5b-be28-dfb45e27e688.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bcaca41c-2840-4aef-9fb4-f0c66589e9cd.ttf") format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b5000ada-6fa4-4aae-8c7e-6e6abda2be56.svg#b5000ada-6fa4-4aae-8c7e-6e6abda2be56") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Arian-LT-W20-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e151393-e605-418c-8050-fb734e7b64b3.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e151393-e605-418c-8050-fb734e7b64b3.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c977bad6-94c3-457c-9771-d8e0017a33c2.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a7bdf7c7-b425-4dae-b583-9f86ec510f9f.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/da80aad9-b3bc-417c-963f-b9033a872ec1.svg#da80aad9-b3bc-417c-963f-b9033a872ec1") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Arian-LT-W20-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/64ef878b-c690-447b-a020-f4491b2de821.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/64ef878b-c690-447b-a020-f4491b2de821.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c257a373-9919-458c-b7b2-83850775058d.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/afa67eb7-2358-4d1d-bdcd-da0436f5cfb2.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/57ba934e-c7af-4166-a22c-48eaf65f26bb.svg#57ba934e-c7af-4166-a22c-48eaf65f26bb") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Janna-LT-W20-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a9c47d30-0eca-434f-8082-ac141c4c97b3.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a9c47d30-0eca-434f-8082-ac141c4c97b3.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/26c24286-5aab-4747-81b9-54330e77fb14.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9c4c7fff-85b6-442f-9726-af5f49d49e53.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a24f53ee-e15e-4931-89a3-b6f17fbfcd72.svg#a24f53ee-e15e-4931-89a3-b6f17fbfcd72") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Kufi-LT-W20-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/774ebfa2-6ac0-48cf-8c15-1394d7bab265.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/774ebfa2-6ac0-48cf-8c15-1394d7bab265.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e0e311dc-5674-493c-8c19-f0a0a1422837.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/96829dee-2b11-4389-a3b6-35eabd423234.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e7b93a7e-e623-4628-809c-4ae5df08148b.svg#e7b93a7e-e623-4628-809c-4ae5df08148b") format("svg");
}

@font-face {
    font-display: block;
    font-family: "HelveticaNeueLTW20-Ligh";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ab7f002d-5f09-4a65-b7ad-9f01ec5bfaf0.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ab7f002d-5f09-4a65-b7ad-9f01ec5bfaf0.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b8ee7e47-48e4-4b5b-8a74-cf02708fb54a.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/074fa346-a8c5-4331-9d93-7a06123af067.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e953f49c-15a9-4394-97dd-a3f230e12e0b.svg#e953f49c-15a9-4394-97dd-a3f230e12e0b") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Midan-W20";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4d56c718-5282-4923-867f-763af2fa8575.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4d56c718-5282-4923-867f-763af2fa8575.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/be87d34b-77db-4286-87d9-d2964115c6c5.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/539016b8-1f78-4507-b542-c9e55b269ac6.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3b8c2857-0ab3-4ea6-90bc-9928bf53340c.svg#3b8c2857-0ab3-4ea6-90bc-9928bf53340c") format("svg");
}

@font-face {
    font-display: block;
    font-family: "TanseekModernW20-Light";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/73d94c9b-15bd-4af9-bda1-c5ee4ed1c409.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/73d94c9b-15bd-4af9-bda1-c5ee4ed1c409.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/688d77ff-8c0d-4baf-ac95-f45c034e1caf.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/177fb002-a619-4d25-8a79-af7d0e9a1ee6.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4fa09e39-beb9-46ca-bfcc-f9518ab4a9bd.svg#4fa09e39-beb9-46ca-bfcc-f9518ab4a9bd") format("svg");
}

@font-face {
    font-display: block;
    font-family: "DINNextLTW23-UltraLight";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b4a0535b-3a89-43bd-b3fb-b6619d0b0a09.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b4a0535b-3a89-43bd-b3fb-b6619d0b0a09.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/63b0b154-64e6-4846-be80-b601f3ce9b28.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f3f08f13-d46f-4589-90ac-70c0a21cd061.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/10870395-15e4-40b0-8f7e-5ffab7195224.svg#10870395-15e4-40b0-8f7e-5ffab7195224") format("svg");
}

@font-face {
    font-display: block;
    font-family: "ArabicTypesettingW23-Re";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/28b4ce0b-3a59-4f7c-ab3f-909d63dd0351.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/28b4ce0b-3a59-4f7c-ab3f-909d63dd0351.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/5a32e87e-0f32-4971-a43f-4ec453bc74ca.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/50f516bf-5611-4134-9556-2131aaea2b78.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8db4ca73-82e1-4259-afcd-c1f15b14f62b.svg#8db4ca73-82e1-4259-afcd-c1f15b14f62b") format("svg");
}

@font-face {
    font-display: block;
    font-family: "CoHeadlineW23-ArabicBol";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/925638f2-9ed0-4f9a-a78d-61d6eddd2b54.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/925638f2-9ed0-4f9a-a78d-61d6eddd2b54.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b15a6899-c706-46a9-8c2b-a80b62ba301b.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/36ad9b9b-5fb9-49e6-ad2d-1daafccea16a.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/05e393a0-71df-4e02-b8ba-6f68f2b23b92.svg#05e393a0-71df-4e02-b8ba-6f68f2b23b92") format("svg");
}

@font-face {
    font-display: block;
    font-family:'Amiri';
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/amiri-regular.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/amiri-regular.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/amiri-regular.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/amiri-regular.ttf') format('truetype');
}

@font-face {
    font-display: block;
    font-family:'Droid-Naskh';
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/droidnaskh-regular.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/droidnaskh-regular.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/droidnaskh-regular.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/droidnaskh-regular.ttf') format('truetype');
}

/* hebrew */
@font-face {
    font-display: block;
    font-family: "Adler-W26-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8fa9b915-180f-4b72-aee7-22fd066c52c6.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8fa9b915-180f-4b72-aee7-22fd066c52c6.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/fcb3f76f-a112-479e-ab7f-ab1c2be906c9.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ceda4e97-a631-4986-8cab-709e1775be33.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/353c7faf-0d58-475b-8caa-f1d863e0cf1d.svg#353c7faf-0d58-475b-8caa-f1d863e0cf1d") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Frank-Ruhl-W26-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/90882399-52f1-42a9-986b-c2c49d3fb409.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/90882399-52f1-42a9-986b-c2c49d3fb409.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0e834425-e268-4b38-b5a8-f24b8632d6ae.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cb5aceab-5dbf-4c09-b650-7c9d526cc117.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/850e45c9-2003-49fa-8e3f-c7dfd6579acc.svg#850e45c9-2003-49fa-8e3f-c7dfd6579acc") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Haim-Arukeem-W26-Medium";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6de8df0c-23af-49b9-9578-42db4c756d2d.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6de8df0c-23af-49b9-9578-42db4c756d2d.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f70c24b0-d6be-4d04-99cd-46efc41d00b4.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2dd64ac4-7c3a-47fb-acdb-063b811c93d5.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a7ab1444-5d94-4474-9d31-86df47d8d5c1.svg#a7ab1444-5d94-4474-9d31-86df47d8d5c1") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Miriam-W26-Medium";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/acfa87dd-5042-40e1-87e9-12e4a559269f.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/acfa87dd-5042-40e1-87e9-12e4a559269f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/21aeb0a3-3309-4415-818b-36f94e2a1a3a.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c5acaffd-7553-42ff-a693-8b9be795b4b3.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dd50d8fb-6769-469f-8bfa-9caed8e6df18.svg#dd50d8fb-6769-469f-8bfa-9caed8e6df18") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Nekudot-W26-Bold";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c0a57107-844c-4847-afc6-00f3cb3c4d5f.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c0a57107-844c-4847-afc6-00f3cb3c4d5f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c28b65cd-9544-42f1-9ffc-d6ffa544e6fb.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9aa74607-c9af-4c4b-9a3f-fc76488dca25.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/00381de4-f82a-4939-b8e1-1bbde51ac4ce.svg#00381de4-f82a-4939-b8e1-1bbde51ac4ce") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Gulash-W26-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7c672276-1d07-4ff2-8b1d-3245af0dc5cc.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7c672276-1d07-4ff2-8b1d-3245af0dc5cc.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/64f53eeb-1d5e-493c-aa3b-aa8e2c066320.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/7e32a874-81bc-4d38-87aa-ff626ce5a400.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/857d7efc-7a9c-457d-8aa5-44f0992e6441.svg#857d7efc-7a9c-457d-8aa5-44f0992e6441") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Shabazi-W26-Bold";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dc7f3c57-fb2d-4656-9224-ef9f1c88477e.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dc7f3c57-fb2d-4656-9224-ef9f1c88477e.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/09048cb1-f6a6-4b44-9d96-6d20013ef7e8.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/fec4486e-254a-4cb4-b1a0-859cf2190792.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b25da79b-ff9a-486e-bf4f-2893c47da939.svg#b25da79b-ff9a-486e-bf4f-2893c47da939") format("svg");
}

@font-face {
    font-display: block;
    font-family:"Chips-W26-Normal";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/aa157336-ffa1-476e-9a72-e9f516e87ca3.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/aa157336-ffa1-476e-9a72-e9f516e87ca3.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e526922d-4fe2-4e4d-834d-6b62ebd244da.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/03e7eb16-072f-4c53-b299-08535bff2421.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b21a0ec6-8efb-40de-99f1-20a11d482401.svg#b21a0ec6-8efb-40de-99f1-20a11d482401") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Alef-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/alef-regular.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/alef-regular.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/alef-regular.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/alef-regular.ttf") format("truetype");
}

@font-face {
    font-display: block;
    font-family: "OpenSansHebrewCondensed-Regular";
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-hebrew-condensed-regular.eot?#iefix");
    src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-hebrew-condensed-regular.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-hebrew-condensed-regular.woff") format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/opensans-hebrew-condensed-regular.ttf") format("truetype");
}

@font-face {
    font-display: block;
    font-family:'almoni-dl-aaa-300';
    font-weight:300; /*(light)*/
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-300.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-300.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-300.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-300.ttf') format('truetype');
}

@font-face {
    font-display: block;
    font-family:'almoni-dl-aaa-400';
    font-weight:400; /*(regular)*/
    font-style: normal;
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-400.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-400.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-400.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-400.ttf') format('truetype');
}

@font-face {
    font-display: block;
    font-family:'almoni-dl-aaa-700';
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-700.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-700.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-700.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/almoni-dl-aaa-700.ttf') format('truetype');
}

@font-face {
    font-display: block;
    font-family:'asimon-aaa-400';
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/asimon-aaa-400.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/asimon-aaa-400.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/asimon-aaa-400.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/asimon-aaa-400.ttf') format('truetype');
}

@font-face {
    font-display: block;
    font-family:'atlas-aaa-500';
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/atlas-aaa-500.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/atlas-aaa-500.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/atlas-aaa-500.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/atlas-aaa-500.ttf') format('truetype');
}

@font-face {
    font-display: block;
    font-family:'mixtape-aaa-400';
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/mixtape-aaa-400.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/mixtape-aaa-400.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/mixtape-aaa-400.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/mixtape-aaa-400.ttf') format('truetype');
}

@font-face {
    font-display: block;
    font-family:'museum-aaa-400';
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/museum-aaa-400.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/museum-aaa-400.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/museum-aaa-400.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/museum-aaa-400.ttf') format('truetype');
}

@font-face {
    font-display: block;
    font-family:'omes-aaa-400';
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/omes-aaa-400.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/omes-aaa-400.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/omes-aaa-400.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/omes-aaa-400.ttf') format('truetype');
}

@font-face {
    font-display: block;
    font-family:'MeodedPashut-oeregular';
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/meodedpashut_oeregular.eot');
    src: url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/meodedpashut_oeregular.eot?#iefix') format('embedded-opentype'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/meodedpashut_oeregular.woff') format('woff'),
    url('//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/open-source/meodedpashut_oeregular.ttf') format('truetype'),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/meodedpashut_oeregular.svg#meodedpashut_oeregular") format("svg");
}

@font-face {
    font-display: block;
    font-family: "August-Bold";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustBold/v1/augustbold-webfont.eot?#iefix");
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustBold/v1/augustbold-webfont.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustBold/v1/augustbold-webfont.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustBold/v1/augustbold-webfont.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustBold/v1/augustbold-webfont.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustBold/v1/augustbold-webfont.svg") format("svg");
}

@font-face {
    font-display: block;
    font-family: "August-Light";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustLight/v1/augustlight-webfont.eot?#iefix");
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustLight/v1/augustlight-webfont.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustLight/v1/augustlight-webfont.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustLight/v1/augustlight-webfont.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustLight/v1/augustlight-webfont.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustLight/v1/augustlight-webfont.svg") format("svg");
}

@font-face {
    font-display: block;
    font-family: "August-Medium";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustMedium/v1/augustmedium-webfont.eot?#iefix");
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustMedium/v1/augustmedium-webfont.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustMedium/v1/augustmedium-webfont.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustMedium/v1/augustmedium-webfont.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustMedium/v1/augustmedium-webfont.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/AugustMedium/v1/augustmedium-webfont.svg") format("svg");
}

@font-face {
    font-display: block;
    font-family: "Knedge-Bold";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/KnedgeBold/v1/knedgebold-webfont.eot?#iefix");
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/KnedgeBold/v1/knedgebold-webfont.eot?#iefix") format("eot"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/KnedgeBold/v1/knedgebold-webfont.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/KnedgeBold/v1/knedgebold-webfont.woff") format("woff"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/KnedgeBold/v1/knedgebold-webfont.ttf") format("truetype"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/KnedgeBold/v1/knedgebold-webfont.svg") format("svg");
}

/* Japanese fonts for vertical text QA */
@font-face {
    font-display: block;
    font-family: "TsukushiGothic";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/TsukushiGothic/v2/WIX-TsukuGoPr5-R.woff2") format("woff2")
}
@font-face {
    font-display: block;
    font-family: "TsukushiGothic";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/TsukushiGothic/v2/WIX-TsukuGoPr5-D.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "Rodin-Light";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/Rodin/v2/WIX-RodinProN-L.woff2") format("woff2")
}
/* mapped to rodin-medium*/
@font-face {
    font-display: block;
    font-family: "Rodin-Light";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/Rodin/v2/WIX-RodinProN-M.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "Rodin-Demi-Bold";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/Rodin/v2/WIX-RodinProN-DB.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "NewCezanne";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/NewCezanne/v2/WIX-NewCezanneProN-M.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "UDKakugoLarge";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/UDKakugoLarge/v2/WIX-UDKakugo_LargePr6N-M.woff2") format("woff2")
}
@font-face {
    font-display: block;
    font-family: "UDKakugoLarge";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/UDKakugoLarge/v2/WIX-UDKakugo_LargePr6N-DB.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "TsukushiMaruGothic";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/TsukushiMaruGothic/v2/WIX-TsukuARdGothicStd-M.woff2") format("woff2")
}
@font-face {
    font-display: block;
    font-family: "TsukushiMaruGothic";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/TsukushiMaruGothic/v2/WIX-TsukuARdGothicStd-B.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "Seurat";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/Seurat/v2/WIX-SeuratProN-M.woff2") format("woff2")
}
@font-face {
    font-display: block;
    font-family: "Seurat";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/Seurat/v2/WIX-SeuratProN-DB.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "TsukushiBMaruGothic";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/TsukushiBMaruGothic/v2/WIX-TsukuBRdGothicStd-M.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "UDMincho";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/UDMincho/v2/WIX-UDMinchoPr6N-M.woff2") format("woff2")
}
@font-face {
    font-display: block;
    font-family: "UDMincho";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/UDMincho/v2/WIX-UDMinchoPr6N-DB.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "TsukushiOldMincho";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/TsukushiOldMincho/v2/WIX-TsukuAOldMinPr6N-M.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "Matisse";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/Matisse/v2/WIX-MatisseProN-M.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "Matisse";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/Matisse/v2/WIX-MatisseProN-DB.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "Skip";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/Skip/v2/WIX-SkipStd-M.woff2") format("woff2")
}

@font-face {
    font-display: block;
    font-family: "Cookhand";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Japanese/Cookhand/v2/WIX-CookHandStd-R.woff2") format("woff2")
}

@font-face {
    font-family: "Adobe-Caslon-W08-SmBd";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Adobe_Caslon/v1/AdobeCaslonW08-Semibold.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Adobe_Caslon/v1/AdobeCaslonW08-Semibold.woff") format("woff");
}

@font-face {
    font-family: "Avenir-LT-W05_85-Heavy";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Avenir_Family_Pack/v1/AvenirLTW05-85Heavy.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Avenir_Family_Pack/v1/AvenirLTW05-85Heavy.woff") format("woff");
}

@font-face {
    font-family: "Avenir-LT-W05_35-Light";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Avenir_Family_Pack/v1/AvenirLTW05-35Light.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Avenir_Family_Pack/v1/AvenirLTW05-35Light.woff") format("woff");
}

@font-face{
    font-family:"Brandon-Grot-W05-Light";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/BrandonGrot/v1/BrandonGrotesqueCondW05-Lt.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/BrandonGrot/v1/BrandonGrotesqueCondW05-Lt.woff") format("woff");
}

@font-face{
    font-family:"Bree-W05-Thin-Oblique";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Bree_Bundle/v1/BreeW05-ThinOblique.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Bree_Bundle/v1/BreeW05-ThinOblique.woff") format("woff");
}

@font-face {
    font-family: "Geotica-W05-Four-Open";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Geotica/v1/GeoticaFourW05-Open.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Geotica/v1/GeoticaFourW05-Open.woff") format("woff");
}

@font-face{
    font-family:"Didot-W05-Italic";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Linotype_Didot/v1/DidotLTW05-Italic.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Linotype_Didot/v1/DidotLTW05-Italic.woff") format("woff");
}

@font-face{
    font-family:"Lulo-Clean-W05-One-Bold";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Lulo_Clean/v1/LuloCleanW05-OneBold.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Lulo_Clean/v1/LuloCleanW05-OneBold.woff") format("woff");
}

@font-face{
    font-family:"BaskervilleMTW05-SmBdIt";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Monotype_Baskerville/v1/BaskervilleMTW05-SemiboldIt.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Monotype_Baskerville/v1/BaskervilleMTW05-SemiboldIt.woff") format("woff");
}

@font-face {
    font-family: "Museo-W05-700";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Museo/v1/MuseoW05-700.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Museo/v1/MuseoW05-700.woff") format("woff");
}

@font-face {
    font-family: "Museo-Slab-W05-100";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Museo_Slab/v1/MuseoSlabW05-100.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Museo_Slab/v1/MuseoSlabW05-100.woff") format("woff");
}

@font-face {
    font-family: "Futura-LT-W05-Book";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Futura_Complete/v1/FuturaLTW05-Book.woff2") format("woff2");
}

@font-face {
    font-family: "Futura-LT-W05-Light";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Futura_Complete/v1/FuturaLTW05-LightCondensed.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Futura_Complete/v1/FuturaLTW05-LightCondensed.woff") format("woff");
}

@font-face {
    font-family: "Nimbus-Sans-TW05Con";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Nimbus/v1/NimbusSansW05-Regular.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Nimbus/v1/NimbusSansW05-Regular.woff") format("woff");
}

@font-face {
    font-family: "Bodoni-W05-Poster";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/PosterBodoni/v1/PosterBodoniBTWGL4W05-Roman.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/PosterBodoni/v1/PosterBodoniBTWGL4W05-Roman.woff") format("woff");
}

@font-face{
    font-family:"Proxima-N-W05-Reg";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/ProximNova/v1/ProximaNovaW05-Regular.woff2") format("woff2"),
    url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/ProximNova/v1/ProximaNovaW05-Regular.woff") format("woff");
}

@font-face{
    font-family:"Fraunces_120pt-Light";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Fraunces/v1/Fraunces_120pt-Light.ttf") format("truetype");
}

@font-face{
    font-family:"Fraunces_120pt-Light";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Fraunces/v1/Fraunces_120pt-LightItalic.ttf") format("truetype");
}

@font-face{
    /* Fraunces_120pt-Regular */
    font-family:"Fraunces_120pt-Light";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Fraunces/v1/Fraunces_120pt-Regular.ttf") format("truetype");
}
@font-face{
    /* Fraunces_120pt-Regular */
    font-family:"Fraunces_120pt-Light";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Fraunces/v1/Fraunces_120pt-Italic.ttf") format("truetype");
}

@font-face{
    font-family:"Fraunces_120pt-SemiBold";
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Fraunces/v1/Fraunces_120pt-SemiBold.ttf") format("truetype");
}

@font-face{
    font-family:"Fraunces_120pt-SemiBold";
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Fraunces/v1/Fraunces_120pt-SemiBoldItalic.ttf") format("truetype");
}

@font-face{
    /* Fraunces_120pt-Bold */
    font-family:"Fraunces_120pt-SemiBold";
    font-weight: 700;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Fraunces/v1/Fraunces_120pt-Bold.ttf") format("truetype");
}

@font-face{
    /* Fraunces_120pt-Bold */
    font-family:"Fraunces_120pt-SemiBold";
    font-weight: 700;
    font-style: italic;
    src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Fraunces/v1/Fraunces_120pt-BoldItalic.ttf") format("truetype");
}


@font-face {
    font-display: block;
    font-family: 'CormorantGaramond';
    font-style: normal;
    font-weight: 400;
    src: url('//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/CormorantGaramond/v1/cormorant-garamond-v7-latin-ext_latin_cyrillic-regular.woff2') format('woff2'),
    url('//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/CormorantGaramond/v1/cormorant-garamond-v7-latin-ext_latin_cyrillic-regular.woff') format('woff');
}

@font-face {
    font-display: block;
    font-family: 'CormorantGaramond';
    font-style: italic;
    font-weight: 400;
    src: url('//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/CormorantGaramond/v1/cormorant-garamond-v7-latin-ext_latin_cyrillic-italic.woff2') format('woff2'),
    url('//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/CormorantGaramond/v1/cormorant-garamond-v7-latin-ext_latin_cyrillic-italic.woff') format('woff');
}

@font-face {
    font-display: block;
    font-family: 'CormorantGaramond';
    font-style: normal;
    font-weight: 700;
    src: url('//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/CormorantGaramond/v1/cormorant-garamond-v7-latin-ext_latin_cyrillic-700.woff2') format('woff2'),
    url('//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/CormorantGaramond/v1/cormorant-garamond-v7-latin-ext_latin_cyrillic-700.woff') format('woff');
}

@font-face {
    font-display: block;
    font-family: 'CormorantGaramond';
    font-style: italic;
    font-weight: 700;
    src: url('//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/CormorantGaramond/v1/cormorant-garamond-v7-latin-ext_latin_cyrillic-700italic.woff2') format('woff2'),
    url('//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/CormorantGaramond/v1/cormorant-garamond-v7-latin-ext_latin_cyrillic-700italic.woff') format('woff');
}
`)
