import { createRef, Ref } from '@wix/materializer'
import { removeHash } from '../refs/removeHash'
import type { BaseDataItem } from '@wix/thunderbolt-becky-types'
import { Unref } from '../types/refs.types'

export type DataNode<TDataItem extends BaseDataItem, TDependencies = null, SchemaDependencies = any> = {
	getDependencies: (item: TDataItem) => TDependencies
	toViewItem: (
		item: TDataItem,
		schemaDependencies: SchemaDependencies,
		customDependencies: Unref<TDependencies>
	) => any
}

export const createDataNode = <TDataItem extends BaseDataItem, TDependencies = any, SchemaDependencies = null>(
	obj: DataNode<TDataItem, TDependencies, SchemaDependencies>
) => obj

export const createDataRef = <T>(rootId: string, mapName: string, id: string, defaultValue?: T): Ref<T> =>
	createRef<T>([rootId, mapName, removeHash(id)], defaultValue)
