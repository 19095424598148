import { createDataNode, createDataRef } from '../nodeFactory/dataNodeUtils'

import {
	ConnectionList as ConnectionListType,
	ConnectionType,
	ControllerConnectionItem,
	IConnectionItem,
	WixCodeConnectionItem,
} from '@wix/thunderbolt-becky-types'
import { Ref } from '@wix/materializer'
import { ConnectionListViewItem } from '../types/ConnectionListViewItem.types'

const isControllerConnectionItem = (item: IConnectionItem): item is ControllerConnectionItem =>
	item.type === ConnectionType.ConnectionItem
const isWixCodeConnectionItem = (item: IConnectionItem): item is WixCodeConnectionItem =>
	item.type === ConnectionType.WixCodeConnectionItem

type DataWithApplicationId = { applicationId: string }
export const ConnectionList = createDataNode({
	getDependencies: (dataItem: ConnectionListType) => {
		return dataItem.items.reduce<Record<string, Ref<DataWithApplicationId>>>((acc, item) => {
			if (isControllerConnectionItem(item)) {
				const controllerDataRef = createDataRef<DataWithApplicationId>(
					'data',
					'document_data',
					item.controllerId
				)!
				acc[item.controllerId] = controllerDataRef
			}
			return acc
		}, {})
	},
	toViewItem: (dataItem: ConnectionListType, __, controllers): ConnectionListViewItem => {
		const result: ConnectionListViewItem = {
			id: dataItem.id,
			type: dataItem.type,
			wixCodeConnections: [],
			controllerConnections: [],
		}

		dataItem.items.forEach((item) => {
			if (isControllerConnectionItem(item)) {
				const applicationId = controllers[item.controllerId]?.applicationId
				result.controllerConnections.push({
					...item,
					applicationId,
				})
			} else if (isWixCodeConnectionItem(item)) {
				result.wixCodeConnections.push(item)
			}
		})

		return result
	},
})
