import { Component } from '@wix/thunderbolt-becky-types'
import _ from 'lodash'

export const defaultToViewItem = (rawItem: any, refs: any) => {
	const result = { ...rawItem, ...refs }
	delete result.metaData
	return result
}

export const fixStructure = (component: Component): Component => {
	const { componentType, skin } = component
	const fixedComponent = { ...component, componentType: _.last(componentType.split('.'))! }
	if (skin) {
		fixedComponent.skin = skin.startsWith('svgshape.') ? skin : _.last(skin.split('.'))!
	}
	return fixedComponent
}
