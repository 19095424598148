import _ from 'lodash'
import { languagesFonts } from './v16.languages'
import { studioFonts } from './fonts.helveticas'
import { madeforFonts } from './fonts.madefor.v16'
import { googleFonts } from './fonts.google.parastorage'
import { googleFonts as googleFontsFromFontsService } from './fonts.google'

import { getWoff2FontUrl, getWoff1FontUrl, getFontFamilies } from './fontsParser'

const cssRules = {
	...googleFonts,
	...languagesFonts,
	...studioFonts,
	...madeforFonts,
}

const cssRulesWithGoogleFontsFromFontsService = {
	...googleFontsFromFontsService,
	...languagesFonts,
	...studioFonts,
	...madeforFonts,
}

const CONSTANTS = {
	UPLOADED_FONT_PREFIX: 'wf_',
	LONG_UPLOADED_FONT_PREFIX: 'wfont_',
}

const UPLOADED_FONT_MASK = 'wfont_[0-9a-f]{6}_[0-9a-f]{32}'
const UPLOADED_FONT_TEST = new RegExp(`^${UPLOADED_FONT_MASK}`)

export function isUploadedFontFamily(fontFamilyStr: string) {
	if (_.isNil(fontFamilyStr) || _.isEmpty(fontFamilyStr)) {
		return false
	}

	const fontName = _.head(fontFamilyStr.split(','))!
	return UPLOADED_FONT_TEST.test(fontName)
}

function getShortUploadedFontFamily(font: string) {
	const newLocal = /^wfont_[0-9a-f]{6}_([0-9a-f]{25})[0-9a-f]{7}/.exec(font)!
	return `wf_${newLocal[1]}`
}

function getUploadedId(uploadedFonStr: string) {
	return uploadedFonStr.replace(CONSTANTS.LONG_UPLOADED_FONT_PREFIX, '').trim()
}

function getFontName(font: string) {
	return _.head(font.split(',')) || ''
}

function resolveMediaRootUrl(mediaRootUrl: string): string {
	return mediaRootUrl.replace(/(https?:\/\/)|^/, 'https://').replace(/\/$|$/, '/')
}

function getFontUrl(mediaRootUrl: string, fontId: string, type: string) {
	return `${mediaRootUrl}ufonts/${fontId}/${type}/file.${type}`
}

function getSrcUrl(mediaRootUrl: string, fontId: string, type: string) {
	return `url("${getFontUrl(mediaRootUrl, fontId, type)}") format("${type}")`
}

function getUploadFontFace(font: string, mediaRootUrl: string) {
	const fontName = getFontName(font)
	const fontId = getUploadedId(fontName)
	const shortFontName = getShortUploadedFontFamily(fontName)
	return `@font-face {
font-family: ${shortFontName};
src: ${getSrcUrl(mediaRootUrl, fontId, 'woff2')},
${getSrcUrl(mediaRootUrl, fontId, 'woff')},
${getSrcUrl(mediaRootUrl, fontId, 'ttf')};
}`
}

export const getFontFacesCss = (
	fontFamilies: Array<string>,
	mediaRootUrl: string,
	shouldServeGoogleFontsFromFontsService: boolean = false
): string => {
	const fontsCssRules = shouldServeGoogleFontsFromFontsService ? cssRulesWithGoogleFontsFromFontsService : cssRules
	mediaRootUrl = resolveMediaRootUrl(mediaRootUrl)
	const fonts = fontFamilies.reduce<Array<string>>((resultArray, fontFamily) => {
		const isUploadedFont = isUploadedFontFamily(fontFamily)
		const fontFaces = isUploadedFont ? getUploadFontFace(fontFamily, mediaRootUrl) : fontsCssRules[fontFamily]
		return resultArray.concat(fontFaces)
	}, [])
	const fontsCss = _.compact(fonts).join('\n')
	return fontsCss
}

export const getFontUrlsToPreload = (
	fontsToPreload: Array<string>,
	mediaRootUrl: string,
	shouldServeGoogleFontsFromFontsService: boolean = false
): Array<string> =>
	_.chain(fontsToPreload)
		.map((fontToPreload) => {
			const fontsCssRules = shouldServeGoogleFontsFromFontsService
				? cssRulesWithGoogleFontsFromFontsService
				: cssRules
			const fontName = getFontFamilies(fontToPreload)[0]
			const fontFace = fontsCssRules[fontName]?.[0]
			return (
				(fontFace && (getWoff2FontUrl(fontFace) || getWoff1FontUrl(fontFace))) ||
				(isUploadedFontFamily(fontName) &&
					getFontUrl(resolveMediaRootUrl(mediaRootUrl), getUploadedId(fontName), 'woff2'))
			)
		})
		.compact()
		.value()
