import { SelectorObj } from '../../shared.types'
import { getVariantSelectors } from '../../utils/variantsUtils'
import { Selectors } from '../cssFeature.types'
import { DomApplier } from '../cssFeatures.types'

const layoutRuntimeDomSelector = (idSelector: string, variantSelectors: Selectors): string =>
	variantSelectors.map((variantSelector) => (variantSelector ? variantSelector : idSelector)).join(',')

export const layoutRuntimeDomApplier: DomApplier<'layoutRuntime'> = (
	compId,
	idSelector,
	breakpointId,
	{ layoutRuntime, layoutVariants },
	accumulatedData
) => {
	const acc: SelectorObj = {}
	const layoutRuntimePerVarient = layoutRuntime?.[breakpointId]

	for (const variantKey in layoutRuntimePerVarient) {
		const variantSelectors = getVariantSelectors(variantKey, layoutVariants, compId, accumulatedData)
		const selectorObj = layoutRuntimePerVarient[variantKey]
		const domSelector = layoutRuntimeDomSelector(idSelector, variantSelectors)
		acc[domSelector] = selectorObj
	}

	return acc
}
