import type { Component } from '@wix/thunderbolt-becky-types'
import type { FeatureRefs } from '../cssFeatures.types'
import { createCssCompNode } from '../cssCompNode'
import { hasResponsiveLayout } from '../../utils/hasResponsiveLayout'
import { envRefs } from '@wix/thunderbolt-catharsis'
import { toBreakpointVariantsMap } from '../../utils/toBreakpointVariantsMap'

export const motionEffects = createCssCompNode('motionEffects', 'motionEffects', {
	getDependencies: (component: Component, refs: FeatureRefs<'motionEffects'>) =>
		component.effectsQuery
			? {
					effectsData: refs.effectsQuery(component.effectsQuery),
					isMobileView: envRefs.isMobileView,
					showScreenInComp: envRefs.showScreenInComp,
			  }
			: null,

	toViewItem: (component, deps) => {
		if (!deps || deps.showScreenInComp) {
			return null
		}

		const { effectsData, isMobileView } = deps
		const effects = effectsData?.values

		if (!effects?.length) {
			return null
		}

		const isResponsive = hasResponsiveLayout(component)

		return effects.map((effect) =>
			toBreakpointVariantsMap(
				effect.value.values,
				// TODO: assumes we *only* have Mobile variants in adaptive
				(item, isDefault) => (isResponsive ? item : isMobileView === !isDefault ? item : null)
			)
		)
	},
})
