import _ from 'lodash'
import type { ClassicCss, SelectorObj } from '../shared.types'

const EMPTY_OBJECT = {}
export const fromClassicCss = (classic: ClassicCss['css'], compSelector: string) => {
	const {
		'': defaultCss = EMPTY_OBJECT,
		'[data-mode=default]': defaultModeCss,
		'[data-mode=hover]': hoverModeCss,
	} = classic

	const hybridCss = { ...defaultCss, ...defaultModeCss }
	const acc: SelectorObj = {
		[compSelector]: defaultModeCss ? hybridCss : defaultCss,
	}

	if (hoverModeCss || !_.isEqual(hybridCss, defaultCss)) {
		acc[`[data-mode=hover] ${compSelector}`] = { ...defaultCss, ...hoverModeCss }
	}
	return acc
}
