import type { FontObject } from '@wix/thunderbolt-components-native'
import { fixFontWithSpaceOrNotLatinChars } from './fontFixer'

const fontFaceRegex = new RegExp('@font-face.*?font-family:\\s?["\']([^"\']*?)["\'].*?\\}', 'gms')
const woff2Regex = new RegExp('(\\/\\/[^"]*.\\.woff2)')
const woff1Regex = new RegExp('(\\/\\/[^("]+]*.\\.woff)')

export const parseFontFaces = (cssString: string) => {
	let match
	const tahat: Record<string, Array<string>> = {}
	// eslint-disable-next-line no-cond-assign
	while ((match = fontFaceRegex.exec(cssString)) !== null) {
		const fontFamily = match[1].toLowerCase()
		tahat[fontFamily] = tahat[fontFamily] || []
		tahat[fontFamily].push(match[0])
	}
	return tahat
}

const invalidFonObj: FontObject = {
	style: '',
	variant: '',
	weight: '',
	size: -1,
	lineHeight: '',
	family: [],
	bold: false,
	italic: false,
}

const extractFromQuotes = (str: string): string => {
	const match = str.match(/'(?<strInQuotes>.*?)'/)

	return match ? match.groups!.strInQuotes : str
}

const fontRegEx = /(?<style>.*?)\s(?<variant>.*?)\s(?<weight>.*?)\s(?<size>.*?)\s(?<fullFontFamily>.*)/

const isValidFont = (font: string): boolean => fontRegEx.test(font)

export const parseFontStr = (font: string): FontObject => {
	const match = font.match(fontRegEx)
	if (!match) {
		return invalidFonObj
	}

	const { style, variant, weight, size, fullFontFamily } = match.groups!
	const sizeSplit = size ? size.split('/') : []
	const fullFontFamilyArr = fullFontFamily.split(',').map((currFamily) => extractFromQuotes(currFamily))

	return {
		style,
		variant,
		weight,
		size: parseInt(sizeSplit[0].replace('px', ''), 10),
		lineHeight: sizeSplit[1],
		family: fullFontFamilyArr,
		bold: weight === 'bold' || parseInt(weight, 10) >= 700,
		italic: style === 'italic',
	}
}

export function fontObjectToString({ style, variant, weight, size, lineHeight, family }: FontObject): string {
	const fullFontFamily = fixFontWithSpaceOrNotLatinChars(family.join(','))

	return `${style} ${variant} ${weight} ${size}px/${lineHeight} ${fullFontFamily}`
}

const colorRegEx = /(({(?<themeColor>.*)})|(?<customColor>#[A-Za-z0-9]+)|(?<themeColorWithoutCurlyBrackets>color_[0-9]+))$/
export const getFontWithoutColor = (str: string) => str && str.replace(colorRegEx, '').trim()

export const getFontColor = (str: string = '') => {
	const match = str.match(colorRegEx)
	if (!match) {
		return undefined
	}

	const { themeColor, customColor, themeColorWithoutCurlyBrackets } = match.groups!
	return themeColor || customColor || themeColorWithoutCurlyBrackets
}

export const getFontFamilies = (font: string): Array<string> => {
	return isValidFont(font) ? parseFontStr(font).family : []
}

function urlExtractor(font: string, urlExtractorRgx: RegExp) {
	const fontUrlMatch = urlExtractorRgx.exec(font)

	return fontUrlMatch ? fontUrlMatch[0] : ''
}

export const getWoff2FontUrl = (font: string): string => urlExtractor(font, woff2Regex)

export const getWoff1FontUrl = (font: string): string => urlExtractor(font, woff1Regex)
