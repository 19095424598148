import { createCssCompNode } from '../cssCompNode'
import { createCompNode } from '@wix/thunderbolt-catharsis'
import { withModes } from '../modesCompNode'
import { hasResponsiveLayout } from '../../utils/hasResponsiveLayout'

const VISIBILITY_HIDDEN = { visibility: 'hidden' } as const
const VISIBILITY_VISIBLE = { visibility: 'visible' } as const

const OVERRIDEN_PROPS = ['isHiddenByModes' as const]

const hoverBox = createCompNode({
	getDependencies: () => null,
	toViewItem: (component) => {
		if (hasResponsiveLayout(component) || component.componentType === 'SiteRegionContainer') {
			// SOSP Containers should be excluded
			return undefined
		}
		// IS_EDITOR(true) //should pass isEditor somehow
		// 	? {
		// 			visibility: 'hidden',
		// 			height: `${component.layout.height}px !important`,
		// 	  }
		// 	:
		// {
		// 	visibility: 'hidden',
		// }
		// @ts-expect-error
		if (component.isHiddenByModes) {
			return VISIBILITY_HIDDEN
		}

		return VISIBILITY_VISIBLE
	},
})

export const hoverBoxCss = createCssCompNode('hoverBoxCss', 'hoverBoxCss', withModes(hoverBox, OVERRIDEN_PROPS))
