import refData from '@wix/document-services-json-schemas/dist/refData.json'
import { createDataRef } from '../nodeFactory/dataNodeUtils'
import { QueriesRefs } from '../types/refs.types'

const { ComponentQueries } = refData['structure.json']
// todo - TB-6680 - remove this map after adding missed keys in DM schemas
const missingComponentQueries = [
	{
		referencedMap: 'variables',
		path: ['variablesQuery'],
	},
	{
		referencedMap: 'patterns',
		path: ['patternsQuery'],
	},
]
const createMapDataRef = (name: string) => <T>(id: string) => createDataRef<T>('data', name, id)

export const compQueriesRefs = Object.assign(
	{},
	...[...ComponentQueries, ...missingComponentQueries].map(({ path: [query], referencedMap }) => ({
		[query]: createMapDataRef(referencedMap),
	}))
) as QueriesRefs
