import { px } from '../../utils/selectorsUtils'
import { createCssCompNode } from '../cssCompNode'

export const placeholder = createCssCompNode('compsMeasuresCss', 'placeholder', {
	getDependencies: () => null,
	toViewItem: ({ layout, componentType }) => {
		if (layout.fixedPosition && (componentType === 'FooterContainer' || componentType === 'HeaderContainer')) {
			return { height: px(layout.height) }
		}
		return null
	},
})
