import queryMapping from '@wix/document-services-json-schemas/dist/queryMapping.json'
import { createMaterializer, Update } from '@wix/materializer'
import { BaseDataItem, Component, Data, DataMapName } from '@wix/thunderbolt-becky-types'
import _ from 'lodash'
import { createCompRef, MegaStore } from '..'
import { dataNodes } from '../dataNodes/dataNodes'
import { compQueriesRefs } from '../refs/compQueriesRefs'
import { ROOT } from '../refs/constants'
import { createRefResolver } from '../refs/schemaRefResolver'
import { DataStore } from '../stores/DataStore'
import type {
	Batched,
	Catharsis,
	CatharsisArgs,
	UpdateData,
	UpdateEnvironment,
	UpdateStructure,
	UpdateTranslation,
} from '../types/catharsis.types'
import { NodeRefs } from '../types/refs.types'
import { defaultToViewItem, fixStructure } from './catharsisUtils'
import { getTemplateIdAndItemContext, isRepeatedItemOverrideId, createRepeatedItemOverrideId } from './repeatersAPI'

const mapNameToQuery = _.invert(queryMapping) as Record<keyof Data, string>
const EMPTY_SCHEMA = Symbol.for('EMPTY_SCHEMA')

type UpdateDataDependencies = (
	update: Update<void>,
	type: DataMapName,
	id: string,
	dataItem: BaseDataItem | undefined
) => void

export const getCatharsis = ({
	onInvalidation,
	extensions: { componentNodes = {}, schemas },
	resolveRepeatedItems = false,
}: CatharsisArgs): Catharsis => {
	const dataStore = new DataStore()
	const structureStore = new MegaStore()
	const originalDataIdToCompId: Record<string, string> = {}
	const materializer = createMaterializer({
		transform: (value: any, path: Array<string>) => {
			const [namespace, aspect, id] = path
			if (namespace === 'data') {
				const dataItem = dataStore.getData(aspect, id)

				if (typeof dataItem === 'undefined') {
					return undefined
				}

				const toViewItem = dataNodes[dataItem.type]?.toViewItem ?? defaultToViewItem
				return toViewItem(dataItem, value?.schema, value?.custom)
			}

			if (namespace === ROOT && aspect !== 'modes') {
				const [compId, itemContext] = getTemplateIdAndItemContext(id)
				const contextStore = itemContext ? structureStore.getChildStore(itemContext) : structureStore
				const component = contextStore.getById<Component>(compId)
				return component && componentNodes[aspect].toViewItem(component, value)
			}

			return value
		},
	})

	const refs = {
		..._.mapValues(componentNodes, (__, key) => (component: Component | string) =>
			createCompRef(key, typeof component === 'string' ? component : component.id)
		),
		...compQueriesRefs,
		modes: (modeId: string) => createCompRef('modes', modeId),
	} as NodeRefs<any>

	const getRefsForDataItem = schemas ? createRefResolver(schemas) : () => null

	const createCompNodes = (update: Update<any>, id: string, component?: Component) => {
		for (const compNodeName in componentNodes) {
			if (!component) {
				// delete
				update(ROOT, compNodeName, id, undefined)
			} else {
				// update or create
				const dependencies = componentNodes[compNodeName].getDependencies(component, refs) || null
				update(ROOT, compNodeName, id, dependencies, dependencies)
			}
		}

		component?.modes?.definitions?.forEach((modeDefinition) => {
			update(
				ROOT,
				'modes',
				modeDefinition.modeId,
				{ prefix: modeDefinition.type === 'DEFAULT' ? '[data-mode=default]' : '[data-mode=hover]' },
				EMPTY_SCHEMA
			)
		})
	}

	const repeatedComponentOverrides = new Map<string, Partial<Component>>()

	const updateDataDependecies: UpdateDataDependencies = (update, type, id, dataItem) => {
		if (!dataItem) {
			update('data', type, id, undefined)
			return
		}
		const schemaRefs = getRefsForDataItem(dataItem, type)
		const customDeps = dataNodes[dataItem.type]?.getDependencies(dataItem)
		const dependencies = schemaRefs || customDeps ? { schema: schemaRefs, custom: customDeps } : null

		update('data', type, id, dependencies, dependencies)

		if (resolveRepeatedItems && isRepeatedItemOverrideId(id)) {
			const overrides = { [mapNameToQuery[type]]: id }
			repeatedComponentOverrides.set(id, overrides)
		}
	}

	const updateData: Batched<UpdateData> = (update) => (type, id, dataItem) =>
		updateDataDependecies(update, type, id, dataStore.updateData(type, id, dataItem))

	const updateTranslation: Batched<UpdateTranslation> = (update) => (language, id, dataItem) =>
		updateDataDependecies(update, 'document_data', id, dataStore.updateTranslationData(language, id, dataItem))

	const updateDataItemIdToCompId = (component: Component | undefined) => {
		if (!component) {
			return
		}
		const { dataQuery, designQuery } = component
		if (dataQuery) {
			originalDataIdToCompId[dataQuery] = component.id
		}
		if (designQuery) {
			originalDataIdToCompId[designQuery] = component.id
		}
	}

	const updateStructure: Batched<UpdateStructure> = (update) => (id, component) => {
		if (!component) {
			structureStore.removeById(id)
			createCompNodes(update, id)
			update('QA', 'fullNameCompType', id, undefined)
		} else {
			const fixedComponent = fixStructure(component)
			structureStore.updateById(id, fixedComponent)
			updateDataItemIdToCompId(fixedComponent)
			createCompNodes(update, id, fixedComponent)
			update('QA', 'fullNameCompType', id, component.componentType, EMPTY_SCHEMA)
		}
	}

	const updateEnvironment: Batched<UpdateEnvironment> = (update) => (environment) => {
		const { renderFlags, ...atoms } = environment
		for (const renderFlag in renderFlags) {
			update('environment', 'renderFlags', renderFlag, renderFlags[renderFlag], EMPTY_SCHEMA)
		}
		for (const atom in atoms) {
			// @ts-expect-error
			update('environment', 'atoms', atom, atoms[atom], EMPTY_SCHEMA)
		}

		if (atoms.viewMode) {
			update('environment', 'mobile', 'isMobileView', atoms.viewMode === 'mobile', EMPTY_SCHEMA)
			const isNonAdaptiveMobile =
				!environment.isResponsive &&
				environment.deviceType === 'Smartphone' &&
				environment.viewMode === 'desktop'
			update('environment', 'mobile', 'isNonAdaptiveMobile', isNonAdaptiveMobile, EMPTY_SCHEMA)
			update('environment', 'mobile', 'deviceType', environment.deviceType, EMPTY_SCHEMA)
		}

		const invalidatedDataItems = dataStore.updateLanguage(atoms)

		if (!invalidatedDataItems) {
			return
		}

		for (const id in invalidatedDataItems) {
			updateDataDependecies(update, 'document_data', id, invalidatedDataItems[id])
		}
	}

	const transaction: Catharsis['transaction'] = (update) => {
		const invalidations = materializer.batch((batchUpdate) => {
			update({
				updateData: updateData(batchUpdate),
				updateStructure: updateStructure(batchUpdate),
				updateEnvironment: updateEnvironment(batchUpdate),
				updateTranslation: updateTranslation(batchUpdate),
			})

			// Add virtual structure for the overrides data items
			if (repeatedComponentOverrides.size) {
				repeatedComponentOverrides.forEach((overrides, id) => {
					const [dataId, context] = getTemplateIdAndItemContext(id)
					const compId = originalDataIdToCompId[dataId]
					const contextStore = structureStore.getChildStore(context)
					contextStore.updateById(compId, overrides)
					const component = contextStore.getById<Component>(compId)
					createCompNodes(batchUpdate, createRepeatedItemOverrideId(compId, context), component)
				})
				repeatedComponentOverrides.clear()
			}
		}) as Array<Array<string>>

		for (const invalidation of invalidations) {
			onInvalidation(invalidation, materializer.get)
		}
	}

	return {
		transaction,
	}
}
