import { Component, Style } from '@wix/thunderbolt-becky-types'
import { fixStyleDataItem } from '@wix/thunderbolt-becky-root'
import { createCssCompNode } from '../cssCompNode'
import { isRefArray } from '../../utils/variantsUtils'
import { toBreakpointVariantsMap } from '../../utils/toBreakpointVariantsMap'
import { createCompNode, themeRefs } from '@wix/thunderbolt-catharsis'
import _ from 'lodash'
import { FeatureRefs } from '../cssFeatures.types'
import { withModes } from '../modesCompNode'

const OVERRIDEN_PROPS = ['styleId' as const]

const createEmptyStyleItem = (component: Readonly<Component>): Style => ({
	id: component.styleId || _.uniqueId('styleId-'),
	type: 'ComponentStyle' as const,
	skin: component.skin!,
	style: { properties: {} },
})

const variants = createCompNode({
	getDependencies: (component: Component, refs: FeatureRefs<'styleBased'>) => ({
		maybeRefArray: component.styleId ? refs.styleId(component.styleId) : undefined,
		themeFonts: themeRefs.themeFonts,
	}),
	toViewItem: (component, { maybeRefArray, themeFonts }) => {
		const items = isRefArray(maybeRefArray)
			? maybeRefArray.values
			: [maybeRefArray || createEmptyStyleItem(component)]

		return {
			type: 'variants' as const,
			css: toBreakpointVariantsMap(items, (item) => fixStyleDataItem(item, component.componentType, themeFonts)),
		}
	},
})

const modes = withModes(
	createCompNode({
		getDependencies: (component: Component, refs: FeatureRefs<'styleBased'>) => ({
			styleId: component.styleId ? refs.styleId(component.styleId) : undefined,
			themeFonts: themeRefs.themeFonts,
		}),
		toViewItem: (component, { styleId, themeFonts }) =>
			fixStyleDataItem(
				(styleId as Style | undefined) || createEmptyStyleItem(component),
				component.componentType,
				themeFonts
			),
	}),
	OVERRIDEN_PROPS
)

export const compStyles = createCssCompNode('styleBased', 'compStyles', {
	getDependencies: (component: Component, refs: FeatureRefs<'styleBased'>) => {
		const modesDependencies = modes.getDependencies(component, refs)

		return Object.keys(modesDependencies.modes).length > 1
			? modesDependencies
			: variants.getDependencies(component, refs)
	},
	toViewItem: (component, dependencies) => {
		if ('modes' in dependencies) {
			return modes.toViewItem(component, dependencies)
		}
		return variants.toViewItem(component, dependencies)
	},
})
