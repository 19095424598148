import { hoverBoxCss } from './hoverBoxCssCompNode'
import { hoverBoxCssDomApplier } from './hoverBoxCssDomApplier'
import type { HoverBoxCssFeature } from './hoverBoxCss.types'

export const hoverBoxCssFeature: HoverBoxCssFeature = {
	experimentName: 'specs.thunderbolt.hoverbox_css_catharsis',
	renderableNodes: { hoverBoxCss },
	intermediateNodes: {},
	dataMaps: ['layout_data'],
	domApplier: hoverBoxCssDomApplier,
}
