import { DataNode } from '../nodeFactory/dataNodeUtils'
import { RefArray } from './RefArray'
import { VariantRelation } from './VariantRelation'
import { SingleLayoutData } from './SingleLayoutData'
import { WFlatTheme } from './WFlatTheme'
import { BreakpointsData } from './BreakpointsData'
import { ObsoleteBehaviorsList } from './ObsoleteBehaviorsList'
import { ConnectionList } from './ConnectionList'

export const dataNodes: Record<string, DataNode<any, any>> = {
	VariantRelation,
	RefArray,
	SingleLayoutData,
	WFlatTheme,
	BreakpointsData,
	ObsoleteBehaviorsList,
	ConnectionList,
}
