export type CompClassSkins = Record<
	string,
	{
		validSkins: Record<string, string>
		defaultSkin: string
	}
>

export const componentTypeToSkins: CompClassSkins = {
	Page: {
		validSkins: {
			TransparentPageSkin: 'TransparentPageSkin',
			SloopyPageSkin: 'SloopyPageSkin',
			BasicPageSkin: 'BasicPageSkin',
			InnerShadowPageSkin: 'InnerShadowPageSkin',
			BorderPageSkin: 'BorderPageSkin',
			LiftedBottomPageSkin: 'LiftedBottomPageSkin',
			LiftedShadowPageSkin: 'LiftedShadowPageSkin',
			LiftedTopPageSkin: 'LiftedTopPageSkin',
			ShinyIPageSkin: 'ShinyIPageSkin',
			NoMarginPageSkin: 'NoMarginPageSkin',
			ThreeDeePageSkin: 'ThreeDeePageSkin',
			ResponsivePageWithColorBG: 'ResponsivePageWithColorBG',
			VerySimpleSkin: 'VerySimpleSkin',
		},
		defaultSkin: 'BasicPageSkin',
	},
}
