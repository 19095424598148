import type { BaseDataItem, UnresolvedRefArray } from '@wix/thunderbolt-becky-types'
import _ from 'lodash'
import { createDataNode } from '../nodeFactory/dataNodeUtils'

export const RefArray = createDataNode({
	getDependencies: () => null,
	toViewItem: (dataItem: UnresolvedRefArray, { values }: { values: Array<BaseDataItem> }) => {
		const validValues = values.filter((v) => typeof v === 'object')
		// default should be first
		return { ...dataItem, values: _.sortBy(validValues, (v) => (v.type === 'VariantRelation' ? 1 : -1)) }
	},
})
