import { Component } from '@wix/thunderbolt-becky-types'
import { createCssCompNode } from '../cssCompNode'
import { toCompVariants } from '../../utils/toCompVariants'
import { FeatureRefs } from '../cssFeatures.types'
import { isRefArray } from '../../utils/variantsUtils'

export const themeVariants = createCssCompNode('styleBased', 'themeVariants', {
	getDependencies: (component: Component, refs: FeatureRefs<'styleBased'>) =>
		component.styleId ? refs.styleId(component.styleId) : undefined,
	toViewItem: (__, styleRefArray) => (isRefArray(styleRefArray) ? toCompVariants(styleRefArray.values) : null),
})
