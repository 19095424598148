import { BaseDataItem } from '@wix/thunderbolt-becky-types'
import _ from 'lodash'

export type LanguageParams = Partial<{ language: string; originalLanguage: string }>

export interface IDocumentDataStore {
	updateLanguage(params: Partial<LanguageParams>): Record<string, BaseDataItem> | null
	updateData(id: string, dataItem: BaseDataItem | undefined): BaseDataItem | undefined
	updateTranslationData(language: string, id: string, dataItem: BaseDataItem | undefined): BaseDataItem | undefined
	getData<T extends BaseDataItem = BaseDataItem>(id: string): T | undefined
}
export class DocumentDataStore implements IDocumentDataStore {
	private readonly data: Record<string, any> = {}
	private readonly translations: Record<string, Record<string, any>> = {}
	private languages: LanguageParams = {}
	private shouldTranslate: boolean = false
	private readonly cache: Record<string, any> = {}

	private updateCache(id: string) {
		const original = this.data[id]
		if (!this.shouldTranslate || original?.type === 'Repeater') {
			this.cache[id] = this.data[id]
			return
		}

		const { language } = this.languages

		if (language && this.translations[language]) {
			const translated = this.translations[language][id]
			if (translated?.type === original?.type) {
				const actual = { ...original, ...translated }
				if (actual.tpaApplicationId) {
					actual.tpaApplicationId = original.tpaApplicationId
				}
				if (actual.pageUriSEO) {
					actual.pageUriSEO = original.pageUriSEO
				}
				if (actual.compId) {
					actual.compId = original.compId
				}
				this.cache[id] = actual
				return
			}
		}

		this.cache[id] = this.data[id]
	}

	updateLanguage(params: Partial<LanguageParams>) {
		const { language, originalLanguage } = params

		if (!('language' in params || 'originalLanguage' in params)) {
			return null
		}

		const newLanguage = {
			language: language || this.languages.language,
			originalLanguage: originalLanguage || this.languages.originalLanguage,
		}

		this.shouldTranslate =
			!_.isUndefined(newLanguage.language) && newLanguage.language !== newLanguage.originalLanguage

		// TODO: handle originalLanguage change

		if (newLanguage.language === this.languages.language) {
			return null
		}

		const previousTranslations = this.translations[this.languages.language || 'NULL_POINTER']
		const nextTranslations = this.translations[newLanguage.language || 'NULL_POINTER']
		const affectedIds = new Set<string>()
		for (const id in previousTranslations) {
			affectedIds.add(id)
		}
		for (const id in nextTranslations) {
			affectedIds.add(id)
		}

		this.languages = newLanguage
		for (const id of affectedIds) {
			this.updateCache(id)
		}
		return Array.from(affectedIds).reduce<Record<string, BaseDataItem>>((acc, id) => {
			acc[id] = this.cache[id]
			return acc
		}, {})
	}

	updateData(id: string, dataItem: BaseDataItem | undefined) {
		this.data[id] = dataItem
		this.updateCache(id)
		return this.cache[id]
	}

	updateTranslationData(language: string, id: string, dataItem: BaseDataItem | undefined) {
		if (!this.translations[language]) {
			this.translations[language] = {}
		}
		this.translations[language][id] = dataItem
		this.updateCache(id)
		return this.cache[id]
	}

	getData(id: string) {
		return this.cache[id]
	}
}
