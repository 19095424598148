import { mapValues, omit } from 'lodash'
import { createCssCompNode } from '../cssCompNode'
import { LayoutRuntimeCSSPrefix } from './layoutRuntime.types'
import { CONTAINER_LAYOUTS_TYPES } from '../../utils/containerLayoutTypes'
import { LayoutDataItems } from '@wix/thunderbolt-becky-types'

export const layoutRuntime = createCssCompNode('layoutRuntime', 'layoutRuntime', {
	getDependencies: (component, refs) => refs.compLayouts(component),
	toViewItem: (__, breakpointsToLayouts) => {
		if (!breakpointsToLayouts) {
			return null
		}

		const css = mapValues(breakpointsToLayouts, (variantsToLayouts) =>
			mapValues(variantsToLayouts, (layoutDataItems: Array<LayoutDataItems>) => {
				const itemsCss = layoutDataItems.reduce((acc, item) => {
					if (item.type === 'ComponentLayout') {
						return Object.assign(acc, {
							component: omit(item, 'id'),
						})
					}

					if (CONTAINER_LAYOUTS_TYPES.has(item.type)) {
						return Object.assign(acc, {
							container: omit(item, 'id'),
						})
					}

					return Object.assign(acc, {
						item: omit(item, 'id'),
					})
				}, {})

				return { [`--${LayoutRuntimeCSSPrefix}`]: JSON.stringify(itemsCss) }
			})
		)

		return css
	},
})
