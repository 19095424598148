import type { BaseDataItem, UnresolvedVariantRelation } from '@wix/thunderbolt-becky-types'
import type { VariantRelationViewItem, VariantViewDataItem } from '../types/VariantsViewItem.types'
import { createDataNode } from '../nodeFactory/dataNodeUtils'
import _ from 'lodash'

export const VariantRelation = createDataNode({
	getDependencies: () => null,
	toViewItem: (
		dataItem: UnresolvedVariantRelation,
		{ to, variants }: { to: BaseDataItem; variants: Array<VariantViewDataItem> }
	): VariantRelationViewItem<any> => ({
		...dataItem,
		to,
		variants: _.sortBy(
			variants.filter((x) => x),
			(v) => (v.type === 'BreakpointRange' ? -1 : 1)
		),
	}),
})
