import _ from 'lodash'
// eslint-disable-next-line no-restricted-syntax
import { HTMLParser } from '../utils/htmlParser'
import { WRichTextDefinition } from '@wix/thunderbolt-becky-types'
import { StyledTextFontHints } from '../../types/fonts'

const THEME_FONT_REGEX = /font_([0-9]+)/g
const QUOTE_REGEX = /&quot;/g
const FONT_FAMILY_REGEX = /font-family:([^;]+);?/i

export const getStyledTextUsedFonts = (styledTextDataItem: WRichTextDefinition['data']): StyledTextFontHints => {
	const usedFontFamilies: StyledTextFontHints['fontFamilies'] = []
	const usedThemeFontClasses: StyledTextFontHints['themeFonts'] = {}

	try {
		HTMLParser(styledTextDataItem.text, {
			start(tagName, attributes) {
				const classNames = _.find(attributes, { name: 'class' })
				if (classNames) {
					const themeFontClassesUsed = classNames.value!.match(THEME_FONT_REGEX) || []
					themeFontClassesUsed.forEach((fontClass) => {
						usedThemeFontClasses[fontClass] = true
					})
				}

				const style = _.find(attributes, { name: 'style' })
				if (!style) {
					return
				}

				const valueUnquoted = style.value!.replace(QUOTE_REGEX, '')

				const fontFamily = FONT_FAMILY_REGEX.exec(valueUnquoted)
				if (fontFamily) {
					usedFontFamilies.push(fontFamily[1].trim())
				}
			},
		})
	} catch (e) {}

	return { fontFamilies: usedFontFamilies, themeFonts: usedThemeFontClasses }
}
