import { DomApplier } from '../cssFeatures.types'
import { TimeAnimationOptions } from '@wix/thunderbolt-becky-types'

const MOTION_EFFECTS_ENTER_DONE_SELECTOR = ':not([data-motion-enter="done"])'

function getInitialFrame(
	item: TimeAnimationOptions | null,
	defaultFrameStyle?: Record<string, any> | null
): Record<string, any> {
	const OPACITY_ZERO = { opacity: 0 }
	const UNSET_OPACITY_ZERO = { opacity: 'unset' }
	// TODO: implement for keyframeEffect
	if (item?.namedEffect) {
		// TODO: implement this properly - currently just following impl. of ScreenIn
		return OPACITY_ZERO
	} else if (defaultFrameStyle?.opacity === 0) {
		return UNSET_OPACITY_ZERO
	}

	return {}
}

export const motionEffectsDomApplier: DomApplier<'motionEffects'> = (
	compId,
	idSelector,
	breakpointId,
	{ motionEffects }
) => {
	if (motionEffects) {
		// fetches initial frame from all effects per a given breakpointId
		const framesStyle = motionEffects.flatMap((breakpointToEffect) => {
			const { default: defaultBreakpoint, ...restOfBreakpoints } = breakpointToEffect

			const defaultFrameStyles = Object.values(defaultBreakpoint).map((item) => getInitialFrame(item))
			const defaultFrame = Object.assign({}, ...defaultFrameStyles)

			if (breakpointId === 'default') {
				// if we we're on `default` it's either the default of Responsive OR Adaptive with either desktop/mobile
				return defaultFrame
			}

			return restOfBreakpoints[breakpointId]
				? Object.values(restOfBreakpoints[breakpointId]).map((item) => getInitialFrame(item, defaultFrame))
				: []
		})
		// and merges them all
		const frame = Object.assign({}, ...framesStyle)

		return {
			[`${idSelector}${MOTION_EFFECTS_ENTER_DONE_SELECTOR}`]: frame,
		}
	}

	return {}
}
