import { createCssCompNode } from '../cssCompNode'
import type { Component, Docking, StripColumnsContainerDefinition } from '@wix/thunderbolt-becky-types'
import type { FeatureRefs } from '../cssFeatures.types'
import { CompNode, createCompNode, envRefs } from '@wix/thunderbolt-catharsis'
import _ from 'lodash'
import { hasResponsiveLayout } from '../../utils/hasResponsiveLayout'
import { DEFAULT_DESKTOP_SITE_WIDTH, MOBILE_SITE_WIDTH } from '../../utils/constants'
import { CONTAINER_TYPES } from '@wix/thunderbolt-commons'
import { SlideShowContainerDefinition } from '@wix/thunderbolt-components-native/dist'
import { withModes } from '../modesCompNode'
import { px } from '../../utils/selectorsUtils'
import { CompsLayout, RenderableCompMeasures } from './compsMeasuresCss.types'

const excludedComponents = new Set<string>(
	process.env.PACKAGE_NAME === 'thunderbolt-ds' ? ['PayPalButton', 'QuickActionBar'] : ['AppController']
)

const OVERRIDEN_PROPS = ['layout' as const]

const FULL_WIDTH_MOBILE_SITE_MEASURES: CompsLayout = {
	left: '0',
	'margin-left': '0',
	width: px(MOBILE_SITE_WIDTH),
}

const getFullWidthAfterMarginsSubstruction = (layout: Component['layout'], siteMargin: number = 0) => {
	const leftMargin = getDockMargin(layout, 'left')
	const rightMargin = getDockMargin(layout, 'right')
	const marginsToSubtract = [leftMargin, rightMargin, px(siteMargin), px(siteMargin)].filter((v) => v && v !== '0')

	return marginsToSubtract.length > 0 ? `calc(100% - ${marginsToSubtract.join(' - ')})` : '100%'
}

const isHorizontallyDocked = (layout: Component['layout']): boolean => !!(layout?.docked?.left && layout?.docked?.right)
const isVerticallyDocked = (layout: Component['layout']): boolean => !!(layout?.docked?.top && layout?.docked?.bottom)

const StripColumnsContainer = createCompNode({
	getDependencies: (component: Component, refs: FeatureRefs<'compsMeasuresCss'>) => ({
		compProps: refs.propertyQuery<StripColumnsContainerDefinition['property']>(component.propertyQuery!),
		deviceType: envRefs.deviceType,
		absoluteComps: envRefs.meshRuntimeData_absoluteComps,
		siteWidth: envRefs.siteWidth,
		isMobileView: envRefs.isMobileView,
	}),
	toViewItem: ({ id, layout }, deps) => {
		if (!deps.compProps.fullWidth) {
			return { width: px(layout.width) }
		}
		if (deps.isMobileView) {
			return FULL_WIDTH_MOBILE_SITE_MEASURES
		}
		const {
			compProps: { siteMargin },
			siteWidth = DEFAULT_DESKTOP_SITE_WIDTH,
		} = deps

		const isAbsoluteStrip = !!deps.absoluteComps.includes(id)
		const isStripWiderThanSiteWidth = siteWidth < (layout.width || 0)
		const leftValueForAbsStrip = isStripWiderThanSiteWidth ? siteMargin : `calc((100% - ${px(siteWidth)}) * 0.5)`

		return _.pickBy(
			{
				left: isAbsoluteStrip ? leftValueForAbsStrip : siteMargin ? null : `0`,
				'margin-left': siteMargin ? `auto` : getDockMargin(layout, 'left') || '0',
				'margin-right': siteMargin ? `auto` : null,
				width: getFullWidthAfterMarginsSubstruction(layout, siteMargin),
				'min-width': isHorizontallyDocked(layout) ? 'initial' : px(siteWidth),
			},
			_.identity
		) as CompsLayout
	},
})

const fullWidth = createCompNode({
	getDependencies: (component: Component, refs: FeatureRefs<'compsMeasuresCss'>) => ({
		compProps:
			component.componentType === 'SlideShowContainer'
				? refs.propertyQuery<SlideShowContainerDefinition['property']>(component.propertyQuery!)
				: undefined,
		siteWidth: envRefs.siteWidth,
		isMobileView: envRefs.isMobileView,
	}),
	toViewItem: (component, deps) => {
		const { isMobileView, siteWidth, compProps } = deps
		if (
			component.componentType === 'SlideShowContainer' &&
			compProps?.type !== 'StripContainerSlideShowProperties'
		) {
			return defaultMeasures.toViewItem(component, deps)
		}

		if (isMobileView) {
			return FULL_WIDTH_MOBILE_SITE_MEASURES
		}
		const { layout } = component
		return {
			left: '0',
			'margin-left': getDockMargin(layout, 'left') || '0',
			width: getFullWidthAfterMarginsSubstruction(layout),
			'min-width': isHorizontallyDocked(layout) ? 'initial' : px(siteWidth || DEFAULT_DESKTOP_SITE_WIDTH),
		} as CompsLayout
	},
})

const getDockMargin = (layout: Component['layout'], dockKey: keyof Docking): string | null => {
	const dock = layout.docked?.[dockKey]
	const pxMargin = dock?.px
	if (pxMargin) {
		return px(pxMargin)
	}
	const vwMargin = dock?.vw
	if (vwMargin) {
		return `${vwMargin}vw`
	}
	return null
}

const defaultMeasures = createCompNode({
	getDependencies: () => ({
		isMobileView: envRefs.isMobileView,
	}),

	toViewItem: ({ layout, componentType }, deps) =>
		getSimpleComponentMeasures(layout, deps.isMobileView, componentType),
})

const getSimpleComponentMeasures = (
	layout: Component['layout'],
	isMobileView: boolean,
	componentType: Component['componentType']
): CompsLayout => {
	const { width, height } = layout
	const widthObject = isHorizontallyDocked(layout)
		? isMobileView
			? FULL_WIDTH_MOBILE_SITE_MEASURES
			: {
					width: getFullWidthAfterMarginsSubstruction(layout),
					left: '0',
					'margin-left': getDockMargin(layout, 'left') || '0',
					'min-width': `initial`,
			  }
		: { width: px(width) }

	// // WRichText and Containers are getting height from their content
	return componentType === 'WRichText' || componentType in CONTAINER_TYPES || isVerticallyDocked(layout)
		? widthObject
		: { ...widthObject, height: px(height) }
}

const measures: Record<string, CompNode<any, CompsLayout, RenderableCompMeasures>> = {
	StripColumnsContainer,

	SlideShowContainer: fullWidth,
	MasterPage: fullWidth,
	HeaderContainer: fullWidth,
	FooterContainer: fullWidth,
	PagesContainer: fullWidth,
	PageGroup: fullWidth,
	Page: fullWidth,
	ClassicSection: fullWidth,
	BgImageStrip: fullWidth,

	default: defaultMeasures,
}

const shouldNotCalculate = (component: Component) =>
	hasResponsiveLayout(component) || excludedComponents.has(component.componentType)

const compsMeasuresStyleCss = createCompNode({
	getDependencies: (component: Component, refs: FeatureRefs<'compsMeasuresCss'>) => {
		if (shouldNotCalculate(component)) {
			return null
		}
		const internalCompNode = measures[component.componentType] || measures.default
		return internalCompNode.getDependencies(component, refs)
	},
	toViewItem: (component, deps) => {
		if (shouldNotCalculate(component)) {
			return undefined
		}
		const internalCompNode = measures[component.componentType] || measures.default
		return internalCompNode.toViewItem(component, deps)
	},
})

export const compsMeasures = createCssCompNode(
	'compsMeasuresCss',
	'compsMeasures',
	withModes(compsMeasuresStyleCss, OVERRIDEN_PROPS)
)
