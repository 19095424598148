import { createDataNode } from '../nodeFactory/dataNodeUtils'
import { fixOriginalFont, getFontColor, textThemeToOriginalFont } from '@wix/thunderbolt-becky-root'
import { ThemeData } from '@wix/thunderbolt-becky-types'
import { color as colorFunctions } from '@wix/thunderbolt-commons'

export const WFlatTheme = createDataNode({
	getDependencies: () => null,
	toViewItem: (item: ThemeData) => {
		const fonts = item.textTheme
			? item.textTheme.map((textTheme) => textThemeToOriginalFont(textTheme, { withColor: true }))
			: item.font

		const colorMap = item.color.reduce<{ [index: string]: string | null }>((acc, curr, i) => {
			acc[`color_${i}`] = colorFunctions.getHexColor(curr)
			return acc
		}, {})

		const fontMap = fonts.reduce<{ [index: string]: string }>((acc, curr, i) => {
			acc[`font_${i}`] = fixOriginalFont(curr)
			return acc
		}, {})

		const fontColorMap = fonts.reduce<{ [index: string]: string | undefined }>((acc, curr, i) => {
			acc[`font_${i}`] = getFontColor(curr)
			return acc
		}, {})

		return {
			...item,
			colorMap,
			fontMap,
			fontColorMap,
		}
	},
})
