import { motionEffects } from './motionEffectsCompNode'
import { MotionEffectsCssFeature } from './motionEffects.types'
import { motionEffectsDomApplier } from './motionEffectsDomApplier'

export const motionEffectsCssFeature: MotionEffectsCssFeature = {
	experimentName: 'specs.thunderbolt.comps_to_hide_effectsis',
	renderableNodes: { motionEffects },
	intermediateNodes: {},
	dataMaps: ['effects'],
	domApplier: motionEffectsDomApplier,
	parentMediaRule: 'prefers-reduced-motion: no-preference',
}
