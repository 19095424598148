import { Component, ComponentOverride } from '@wix/thunderbolt-becky-types'
import { CompNode, createCompNode, NodeRefs } from '@wix/thunderbolt-catharsis'
import { Modes } from '../shared.types'
import { mapValues } from '../utils/mapValues'

type StructureOverrides = Array<keyof ComponentOverride>
type ComponentModes = { [modeId: string]: Component }

const EMPTY_ARRAY: Array<ComponentOverride> = []

const getComponentsModes = (component: Component, overrides: StructureOverrides): ComponentModes =>
	(component.modes?.overrides || EMPTY_ARRAY).reduce<ComponentModes>(
		(acc, override) => {
			if (overrides.some((field) => field in override) && override.modeIds) {
				const [modeId] = override.modeIds
				acc[modeId] = { ...component, ...override }
			}
			return acc
		},
		{
			'': component.modes?.isHiddenByModes
				? ({ ...component, isHiddenByModes: component.modes?.isHiddenByModes } as Component)
				: component,
		}
	)

export const withModes = <TDependencies, TViewerItem, TComponentNodeRefs>(
	compNode: CompNode<TDependencies, TViewerItem, TComponentNodeRefs>,
	structureOverrides: StructureOverrides
) =>
	createCompNode({
		getDependencies: (component: Component, refs: NodeRefs<TComponentNodeRefs>) => {
			const modes = getComponentsModes(component, structureOverrides)

			return {
				modes: mapValues(modes, (__, modeId) => (modeId ? refs.modes(modeId) : null)),
				deps: mapValues(modes, (comp) => compNode.getDependencies(comp, refs)),
			}
		},
		toViewItem: (component, deps) => {
			const modes = getComponentsModes(component, structureOverrides)
			const css = Object.keys(modes).reduce<Partial<Record<Modes, TViewerItem>>>((acc, modeId) => {
				const prefix = deps.modes[modeId]?.prefix || ''

				const viewerItem = compNode.toViewItem(modes[modeId], deps.deps[modeId])
				if (viewerItem) {
					acc[prefix] = viewerItem
				}
				return acc
			}, {})
			return { type: 'classic' as const, css }
		},
	})
