import { createCompNode } from '@wix/thunderbolt-catharsis'
import { ComponentPattern, RepeaterPatternsRefs, VariantToNthChildren } from './pattern.types'

export const componentPatternsFactory = () =>
	createCompNode<RepeaterPatternsRefs, ComponentPattern, 'patternsQuery'>({
		getDependencies: (component, refs) =>
			component.patternsQuery ? refs.patternsQuery(component.patternsQuery) : null,
		toViewItem: (component, patternsData) => {
			if (!patternsData || !component.components) {
				return null
			}

			const nthChildren =
				patternsData.items?.reduce((acc: VariantToNthChildren, item) => {
					acc[item.variant.id] = acc[item.variant.id] || []
					const offset = item.offset ? `+${item.offset}` : ''
					const patternSelector = `:nth-child(${item.repeat}n${offset})`
					acc[item.variant.id].push(patternSelector)
					return acc
				}, {}) || {}

			return {
				nthChildren,
				componentId: component.components[0],
			}
		},
	})
