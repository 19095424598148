import { EMPTY_OBJECT } from '../../utils/constants'
import { fromClassicCss } from '../../utils/fromClassicCss'
import type { DomApplier } from '../cssFeatures.types'

export const compsMeasuresCssDomApplier: DomApplier<'compsMeasuresCss'> = (
	__,
	idSelector,
	_breakpointId,
	{ compsMeasures, placeholder }
) => {
	if (!compsMeasures) {
		return EMPTY_OBJECT
	}

	const acc = fromClassicCss(compsMeasures.css, idSelector)

	if (!placeholder) {
		return acc
	}
	return { ...acc, [`${idSelector}-placeholder`]: placeholder }
}
