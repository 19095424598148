import { Component } from '@wix/thunderbolt-becky-types'
import { createCompNode } from '@wix/thunderbolt-catharsis'
import _ from 'lodash'
import { CssFeature } from './cssFeature.types'

export const componentCssFactory = (cssFeatures: Record<string, CssFeature<any, any>>) =>
	createCompNode({
		getDependencies: (component: Component, refs: any) => {
			const seed: any = {
				breakpointsOrder: component.breakpointVariantsQuery
					? refs.breakpointVariantsQuery(component.breakpointVariantsQuery)
					: undefined,
			}

			if (component.patternsQuery) {
				seed.compSpecificData = seed.compSpecificData || {}
				seed.compSpecificData = seed.compSpecificData.patterns || {}
				seed.compSpecificData.patterns = {
					[component.id]: refs.repeaterPatterns(component),
				}
			}

			return Object.entries(cssFeatures).reduce((acc, [featureName, feature]) => {
				acc[featureName] = _.mapValues(feature.renderableNodes, (__, compNodeName) =>
					refs[compNodeName](component)
				)
				return acc
			}, seed)
		},
		toViewItem: (__, deps) => deps,
	})
