import { BreakpointsVariantsTo } from '../shared.types'
import { mapValues } from './mapValues'

type MapValuesValues = <TMap extends BreakpointsVariantsTo<any>, TValue, TTo = TMap[string][string]>(
	obj: TMap,
	fn: (value: TTo) => TValue
) => BreakpointsVariantsTo<TValue>

export const mapValuesValues: MapValuesValues = (obj, fn) =>
	mapValues(obj, (variantsToStyle) => mapValues(variantsToStyle, fn))
