import { fontObjectToString, parseFontStr } from './fontsParser'

export const overrideFontSize = (fontString: string, fontSizeOverride: number): string => {
	if (fontSizeOverride) {
		const parsedFont = parseFontStr(fontString)
		const parsedFontWithOverride = { ...parsedFont, size: fontSizeOverride }
		return fontObjectToString(parsedFontWithOverride)
	}
	return fontString
}
