import { SelectorObj } from '../../shared.types'
import { getVariantSelectors } from '../../utils/variantsUtils'
import { fromClassicCss } from '../../utils/fromClassicCss'
import { DomApplier } from '../cssFeatures.types'

const toClass = (className: string) => `.${className}`

export const styleBasedDomApplier: DomApplier<'styleBased'> = (
	compId,
	idSelector,
	breakpointId,
	{ themeVariants, compTheme },
	accumulatedData
) => {
	if (!compTheme) {
		return {}
	}
	const compClass = toClass(compId)

	if (compTheme.type === 'classic') {
		return fromClassicCss(compTheme.css, compClass)
	}

	const acc: SelectorObj = {}

	const cssInBreakpoint = compTheme.css?.[breakpointId]
	for (const variantKey in cssInBreakpoint) {
		const domSelector = themeVariants
			? getVariantSelectors(variantKey, themeVariants, compId, accumulatedData).join(',')
			: idSelector
		const cssObj = cssInBreakpoint[variantKey]
		acc[domSelector] = Object.assign(acc[domSelector] || {}, cssObj)
	}

	return acc
}
