import { ViewMode } from './env'
import { LayoutData } from './layoutData'
import {
	ResolvedStyleRefArray,
	ResolvedStyleVariantRelation,
	VariantsDataMap,
	ResolvedDesignRefArray,
	ResolvedDesignVariantRelation,
} from './variantTypes'
import { BreakpointData } from './breakpointTypes'
import { TransformationsDataMap } from './transformationTypes'
import { TransitionsDataMap } from './transitionTypes'
import { PresetsDataMap } from './presetsTypes'
import { Language } from './language'
import { ReactionsDataMap } from './reactionsTypes'
import { TriggersDataMap } from './triggersTypes'
import { EffectsDataMap } from './effectsTypes'
import { VariablesDataMap } from './variablesTypes'
import { CursorData } from './cursorData'
import { PatternsData } from './patternsTypes'

export type BaseDataItem<Type extends string = string> = {
	id: string
	type: Type
}

export type BaseDataItemWithMetadata<Type extends string = string> = BaseDataItem<Type> & {
	metaData: { pageId: string }
}

export type DataItemMetaData = {
	isPreset: boolean
	schemaVersion: string
	isHidden: boolean
	sig: string
}

export type WidgetRefDataItem = BaseDataItem<'WidgetRef'> & {
	appDefinitionId: string
	widgetId: string
	variationId?: string
}

export type InternalRefDataItem = BaseDataItem<'InternalRef'> & {
	rootCompId: string
	pageId?: string
}

export type RepeaterDataItem = BaseDataItem & {
	items: Array<string>
}

export interface TPAWidgetDataItem extends BaseDataItem {
	appDefinitionId: string
	applicationId: string
	metaData: DataItemMetaData
	tpaData: string
	widgetId: string
}

export type DesignDataItemWithBackground = BaseDataItem & { background: string; type: 'MediaContainerDesignData' }
export type DesignDataItemWithDividers = DesignDataItemWithBackground & {
	type: 'MediaContainerWithDividers'
	dividerTop: string
	dividerBottom: string
}
export type DesignDataItem = BaseDataItem | ResolvedDesignVariantRelation | ResolvedDesignRefArray

export type MediaRefDataItem = BaseDataItem & { mediaRef: string }

export type ComponentQueries = {
	dataQuery?: string
	propertyQuery?: string
	styleId?: string
	connectionQuery?: string
	behaviorQuery?: string
	designQuery?: string
	layoutQuery?: string
	cursorQuery?: string
	slotsQuery?: string
	breakpointsQuery?: string
	breakpointVariantsQuery?: string
	mobileHintsQuery?: string
	transitionQuery?: string
	reactionsQuery?: string
	triggersQuery?: string
	transformationQuery?: string
	presetsQuery?: string
	anchorQuery?: string
	variablesQuery?: string
	patternsQuery?: string
	effectsQuery?: string
}
export type QueryProperty = keyof ComponentQueries

export type Component = ComponentQueries & {
	id: string
	layout: Partial<AbsoluteLayout>
	type: 'Document' | 'Page' | 'Container' | 'Component' | 'RepeaterContainer' | 'RefComponent'
	componentType: string
	components?: Array<string>
	parent: string | null
	metaData: Partial<ComponentMetaData>
	styleId?: string
	skin?: string
	modes?: Modes
	uiType?: string
	templateId?: string
	originCompId?: string
	ghost?: string
	connectionQuery?: any
	mobileStructure?: any
	slots?: { [slotName: string]: string }
	transformationQuery?: string
}

export type PagesGroupData = BaseDataItem<'PagesGroupData'> & {
	pages: Array<string>
}

type ModeSettings = {
	pagesGroupId: string
}

export type ModeDefinition = {
	label: string
	modeId: string
	type: string
	params?: string
	settings?: ModeSettings
}

export type ComponentOverride = {
	modeIds?: Array<string>
	layout?: AbsoluteLayout
	propertyQuery?: string
	isHiddenByModes?: boolean
	layoutQuery?: string
	designQuery?: string
	styleId?: string
}

export type Modes = {
	definitions?: Array<ModeDefinition>
	overrides?: Array<ComponentOverride>
	isHiddenByModes?: boolean
}

type Dock = Partial<{
	vw: number
	px: number
}>

export type Docking = {
	left?: Dock
	right?: Dock
	top?: Dock
	bottom?: Dock
	hCenter?: Dock
	vCenter?: Dock
}

export interface AbsoluteLayout {
	x: number
	y: number
	width: number
	height: number
	rotationInDegrees: number
	fixedPosition: boolean
	scale: number
	docked?: Partial<Docking>
}

export interface ComponentMetaData {
	isPreset: boolean
	pageId: string
	pageMeshOverrides: {
		[key in 'mobile' | 'desktop']: {
			minHeight: number
		}
	}
	popupContainerMeshOverrides: { alignmentType: 'nineGrid' | 'fullHeight' | 'fullWidth' }
	originalComponentType: string
}

export type Style = BaseDataItem<'TopLevelStyle' | 'ComponentStyle'> & {
	skin: string
	style: {
		properties: {
			[id: string]: string
		}
		propertiesOverride?: {
			[id: string]: {
				[id: string]: string
			}
		}
	}
	styleType?: string
}

export type ActionBehavior = {
	action: {
		type: 'comp' | 'site' | 'widget'
		sourceId: string
		name: string
	}
	behavior: {
		type: 'comp' | 'site' | 'widget'
		targetId: string
		name: string
		params?: any
	}
}

export type AnimationsActions = 'screenIn' | 'bgScrub' | 'motionEffects'

export type AnimationsBehavior = {
	name: string
	delay?: number
	duration?: number | string
	action: string
	targetId?: string
	params?: {
		[x: string]: any
	}
	persistOnNav?: boolean
	pageId?: string
	viewMode?: ViewMode | 'MOBILE' | 'DESKTOP'
}

export type BehaviorItem = BaseDataItem & {
	items: Array<ActionBehavior | AnimationsBehavior>
}

export enum ConnectionType {
	WixCodeConnectionItem = 'WixCodeConnectionItem',
	ConnectionItem = 'ConnectionItem',
}
export interface IConnectionItem {
	type: ConnectionType
	role: string
}

export interface ControllerConnectionItem extends IConnectionItem {
	type: ConnectionType.ConnectionItem
	controllerId: string
	config?: string
	isPrimary: boolean
}

export interface WixCodeConnectionItem extends IConnectionItem {
	type: ConnectionType.WixCodeConnectionItem
}

export type ConnectionList = BaseDataItem<'ConnectionList'> & {
	items: Array<IConnectionItem>
}

export type AnchorDataItem = BaseDataItem<'AnchorDataItem'> & {
	name: string
	urlFragment?: string
}

export type AnchorsData = { [anchorId: string]: AnchorDataItem }

export type SlotsDataItem = BaseDataItem<'DynamicSlots' | 'PlaceholderSlot'> & {
	slots: { [slotId: string]: string }
}
export type SlotsData = { [slotsQuery: string]: SlotsDataItem }

export type DataMap = Record<string, BaseDataItem>

export type TextTheme = {
	fontStyle: string
	fontWeight: string
	fontVariant: string
	fontFamily: string
	fontSize: string
	lineHeight: string
	color: string
	letterSpacing: string
}
export type ThemeData = BaseDataItem<'WFlatTheme'> & {
	id: 'THEME_DATA'
	font: Array<string>
	fontColor?: Array<string>
	color: Array<string>
	textTheme?: Array<TextTheme>
}

export type GlobalImageQuality = BaseDataItem<'GLOBAL_IMAGE_QUALITY'> & {
	id: 'IMAGE_QUALITY'
	quality: number
	unsharpMask: {
		radius: number
		amount: number
		threshold: number
	}
}

export type Sharpness = Pick<GlobalImageQuality, 'quality' | 'unsharpMask'>

export type ObsoleteBehaviorsList = BaseDataItem<'ObsoleteBehaviorsList'> & {
	items: string
}

export type StyleDataItem = Style | ResolvedStyleVariantRelation | ResolvedStyleRefArray
export interface Data {
	theme_data: {
		THEME_DATA: ThemeData
	} & { [styleId: string]: StyleDataItem }
	design_data: DataMap
	cursor: CursorData
	document_data: DataMap
	layout_data: LayoutData
	component_properties: DataMap
	connections_data: { [connectionId: string]: ConnectionList }
	mobile_hints: DataMap
	breakpoints_data: BreakpointData
	behaviors_data: {
		[behaviorId: string]: ObsoleteBehaviorsList
	}
	transformations_data: TransformationsDataMap
	transitions_data: TransitionsDataMap
	presets: PresetsDataMap
	slots: SlotsData
	variants_data: VariantsDataMap
	patterns: PatternsData
	reactions: ReactionsDataMap
	triggers: TriggersDataMap
	anchors_data: AnchorsData
	variables: VariablesDataMap
	effects: EffectsDataMap
}

export type DataMapName = keyof Data

export interface Structure {
	[id: string]: Component
}

export type ChildrenKey = 'mobileComponents' | 'children' | 'components'

export type DeepStructure = Omit<Component, 'components'> &
	{
		[K in ChildrenKey]?: Array<DeepStructure>
	}

export type PageJson = {
	structure: DeepStructure
	data: Data
	translations: Record<
		Language,
		{
			data: Pick<Data, 'document_data'>
		}
	>
}

export type CompDefinition<
	SkinType extends string,
	DataType extends BaseDataItem,
	PropertyType extends BaseDataItem,
	DesignType extends BaseDataItem,
	UnresolvedDataType extends BaseDataItem = DataType,
	UnresolvedDesignType extends BaseDataItem = DesignType
> = {
	skin: SkinType
	data: DataType
	property: PropertyType
	design: DesignType
	unresolvedData?: UnresolvedDataType
	unresolvedDesign?: UnresolvedDesignType
}

export type StyleProperties = { [id: string]: string }

export type ColorMap = Array<string>

export type ThemeColorMap = { [colorIndex: string]: string }

export type ThemeFontMap = { [fontIndex: string]: string }

export type ThemeFontColorMap = { [fontIndex: string]: string }

export type MeshContainerProps = {
	wedges: Array<string>
	rotatedComponents: Array<string>
	fixedComponents?: Array<string>
}
