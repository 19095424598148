import { StyleBasedFeature } from './styleBased.types'
import { styleBasedDomApplier } from './styleBasedDomApplier'
import { compStyles } from './compStylesCompNode'
import { compTheme } from './compThemeCompNode'
import { themeVariants } from './themeVariantsCompNode'

export const styleBasedFeature: StyleBasedFeature = {
	experimentName: 'specs.thunderbolt.catharsis_theme',
	renderableNodes: { compTheme, themeVariants },
	intermediateNodes: { compStyles },
	dataMaps: ['theme_data', 'variants_data'],
	domApplier: styleBasedDomApplier,
}
