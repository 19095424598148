import { ViewerModel } from '@wix/thunderbolt-symbols'
import { BiModule } from '@wix/thunderbolt-environment'

const getScreenHeightOverride = () => {
	if (typeof window === 'undefined' || !window.top) {
		return 0
	}
	const screenHeight =
		getComputedStyle(window.top.document.documentElement).getPropertyValue('--g-mobile-preview-max-height') || '0'

	return parseInt(screenHeight, 10)
}

const getIsImagesLazyLoadingDisabled = (requestUrl: string) => {
	if (typeof window === 'undefined') {
		return false
	}
	return new URL(requestUrl || window.location.href).searchParams.get('disableLazyLoading') === 'true'
}

export const initCustomElementsDS = async (viewerModel: ViewerModel, wixCustomElements: any) => {
	window.viewerModel = viewerModel
	const { experiments, media, requestUrl } = viewerModel

	const tbCustomElements = await import('@wix/thunderbolt-custom-elements')
	const biService = BiModule()
	const mediaServicesDS = {
		getScreenHeightOverride: () => {
			return viewerModel.viewMode === 'mobile' ? getScreenHeightOverride() : 0
		},
	}
	const environmentOverrides = {
		disableImagesLazyLoading: getIsImagesLazyLoadingDisabled(viewerModel.requestUrl),
	}

	tbCustomElements.initCustomElements(
		{ experiments, media, requestUrl },
		biService,
		wixCustomElements,
		mediaServicesDS,
		environmentOverrides
	)
}
