import { createRef, Ref } from '@wix/materializer'
import type { Component } from '@wix/thunderbolt-becky-types'
import { ROOT } from '../refs/constants'
import { NodeRefs, Unref } from '../types/refs.types'

export type CompNode<TDependencies, TViewerItem, TComponentNodeRefs> = {
	getDependencies: (structure: Readonly<Component>, compNodeRefs: NodeRefs<TComponentNodeRefs>) => TDependencies
	toViewItem: (structure: Readonly<Component>, data: Unref<TDependencies>) => TViewerItem
}
export type CompNodes<T extends object> = { [key in keyof T]: CompNode<any, T[key], any> }

export const createCompNode = <TDependencies, TViewerItem, TComponentNodeRefs>(
	obj: CompNode<TDependencies, TViewerItem, TComponentNodeRefs>
) => obj

export const createCompRef = <T>(name: string, id: string, defaultValue?: T): Ref<T> =>
	createRef<T>([ROOT, name, id], defaultValue)
