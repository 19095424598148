import { DomApplier } from '../cssFeatures.types'
import { fromClassicCss } from '../../utils/fromClassicCss'

export const hoverBoxCssDomApplier: DomApplier<'hoverBoxCss'> = (
	_compId,
	idSelector, // idSelector is the same as `#${compId}` or '#[id^compId__]' if compId is a repeater
	_breakpointId,
	{ hoverBoxCss }
) => {
	if (!hoverBoxCss) {
		return {}
	}

	const classicCss = fromClassicCss(hoverBoxCss.css, idSelector)

	const { [idSelector]: defaultVisibility, ...rest } = classicCss

	if (defaultVisibility.visibility === 'visible') {
		return rest
	}

	return classicCss
}
