import { fontObjectToString, parseFontStr } from './fontsParser'
import { getTagName, getTagAttributes, getInlineStyle, toInlineStyle, toHtmlOpenTag } from '../elementUtils'
import { getFontClass } from '../fonts'
import { ThemeFontMap } from '../../types'

function getMobileFontSize(desktopFontSize: number): number {
	const MOBILE_DEFAULT_MIN_FONT_SIZE = 12
	const MOBILE_MAX_FONT_SIZE = 50
	const SCALING_FACTOR = 3
	const MIN_SCALED_FONT_SIZE = 14
	const MEDIUM_SCALED_FONT_SIZE = 26
	const MAX_SCALED_FONT_SIZE = 100
	let mobileFontSize

	const intDesktopFontSize = Math.round(desktopFontSize)

	if (intDesktopFontSize < MOBILE_DEFAULT_MIN_FONT_SIZE) {
		mobileFontSize = MOBILE_DEFAULT_MIN_FONT_SIZE
	} else if (intDesktopFontSize <= MIN_SCALED_FONT_SIZE) {
		mobileFontSize = intDesktopFontSize + 1
	} else if (intDesktopFontSize <= MEDIUM_SCALED_FONT_SIZE - 1) {
		mobileFontSize = intDesktopFontSize
	} else if (intDesktopFontSize <= MAX_SCALED_FONT_SIZE) {
		mobileFontSize = Math.floor(
			(intDesktopFontSize - MEDIUM_SCALED_FONT_SIZE) / SCALING_FACTOR + MEDIUM_SCALED_FONT_SIZE
		)
	} else {
		mobileFontSize = MOBILE_MAX_FONT_SIZE
	}
	return mobileFontSize
}

const convertFontSizeToMobile = (fontSize: number, scale: number): number => {
	const mobileFontSize = getMobileFontSize(fontSize)
	return scale * mobileFontSize
}

export const getMobileScaledFont = (
	fontString: string,
	scale: number,
	minimumFontSize: number = 0,
	fontSizeOverride = null
): string => {
	const parsedFont = parseFontStr(fontString)
	const scaledSize = convertFontSizeToMobile(parsedFont.size, scale)
	const fontSize = fontSizeOverride || (minimumFontSize ? Math.max(minimumFontSize, scaledSize) : scaledSize)
	const scaledFont = { ...parsedFont, size: fontSize }
	return fontObjectToString(scaledFont)
}

export const correctInlineFontStyle = (html: string, scale: number, fontMap: ThemeFontMap) => {
	return html.replace(/<[^/>]+>/gm, (htmlOpenTag) => {
		const tagName = getTagName(htmlOpenTag)
		const attributes = getTagAttributes(htmlOpenTag)
		const inlineStyles = attributes.style && getInlineStyle(attributes.style)
		const fontClass = attributes.class && getFontClass(attributes.class)
		let desktopFontSize: number | undefined

		if (inlineStyles && inlineStyles['font-size']) {
			desktopFontSize = Number(removePx(inlineStyles['font-size']))
		} else if (fontClass && fontMap[fontClass]) {
			const fontStr = fontMap[fontClass]
			desktopFontSize = parseFontStr(fontStr).size
		}

		// This is a reproduction of a bug in the legacy textTransform algorithm
		// In some rare cases when the user would have font-size with a floating point value the legacy algorithm failed to recognize it.
		// The floating point font-size was then left untouched.
		// Fixing this bug now may have noticeable effect on these sites.
		if (!desktopFontSize || !Number.isInteger(desktopFontSize)) {
			return htmlOpenTag
		}

		const newInlineStyles = inlineStyles ? { ...inlineStyles } : {}
		newInlineStyles['font-size'] = `${Math.round(convertFontSizeToMobile(desktopFontSize, scale))}px`
		attributes.style = toInlineStyle(newInlineStyles)

		return toHtmlOpenTag(tagName, attributes)
	})

	function removePx(cssValue: string) {
		return cssValue.replace('px', '')
	}
}
