import { ThemeFontMap } from '@wix/thunderbolt-becky-types'
import { getInlineStyle, getTagAttributes, getTagName, toHtmlOpenTag, toInlineStyle } from '../elementUtils'
import { getFontClass, parseFontStr } from '../fonts'

/**
 * This code resolves a bug in Edge 18
 * where line-height is miscalculated when the font-size comes from a css variable
 */
export const correctInlineFontStyleDesktop = (html: string, fontMap: ThemeFontMap) => {
	return html.replace(/<[^/>]+>/gm, (htmlOpenTag) => {
		const tagName = getTagName(htmlOpenTag)
		const attributes = getTagAttributes(htmlOpenTag)
		const inlineStyles = attributes.style && getInlineStyle(attributes.style)
		const fontClass = attributes.class && getFontClass(attributes.class)

		if ((inlineStyles && inlineStyles['font-size']) || !fontClass || !fontMap[fontClass]) {
			return htmlOpenTag
		}
		const fontStr = fontMap[fontClass]
		const desktopFontSize = parseFontStr(fontStr).size

		const newInlineStyles = inlineStyles ? { ...inlineStyles } : {}
		newInlineStyles['font-size'] = `${desktopFontSize}px`
		attributes.style = toInlineStyle(newInlineStyles)

		return toHtmlOpenTag(tagName, attributes)
	})
}
