import {
	EditorFeatureConfigSymbol,
	IAppDidMountHandler,
	IAppWillMountHandler,
	IRenderer,
	IStructureStore,
	IPagesModelsRegistrar,
	LOADING_PHASES,
	LifeCycle,
	MasterPageFeatureConfigSymbol,
	PageFeatureConfigSymbol,
	PlatformSymbol,
	Props,
	RendererSymbol,
	SiteFeatureConfigSymbol,
	Structure,
	isViewerFragmentSymbol,
	CarmiInstance,
	PagesModelsRegistrarSymbol,
	IAppWillUnmountHandler,
} from '@wix/thunderbolt-symbols'
import { Container, Identifier } from '@wix/thunderbolt-ioc'
import { ClientRenderResponse, RendererProps } from 'feature-react-renderer'
import {
	ManifestSymbol,
	ViewerBaseSymbol,
	ViewerSourceSymbol,
	ViewerManagerApi,
} from 'thunderbolt-viewer-manager-types'
import type { ViewerAPIProviderInitializer } from 'thunderbolt-viewer-manager-types'
import { ViewerManagerProvider } from 'thunderbolt-viewer-manager'
import { createEnvLoader } from '@wix/thunderbolt-environment'
import type { Environment } from '@wix/thunderbolt-environment'
import type { ContainerModulesValues, DSCarmi } from 'thunderbolt-ds-carmi-root'
import { initCustomElementsDS } from './initCustomElementsDS'
import { DsApiFactoryEnv, DsApis } from './getDsApis'
import { ComponentsLoaderSymbol, IComponentsLoader } from '@wix/thunderbolt-components-loader'
import { ModelUpdatesInvoker } from 'ds-feature-model-updates-invoker'
import { taskify } from '@wix/thunderbolt-commons'
import {
	Catharsis,
	MegaStoreWithSubscriptions,
	CatharsisMegaStoreSymbol,
	CatharsisSymbol,
} from '@wix/thunderbolt-catharsis'

const modulesToInjectToCarmi = {
	modelUpdatesInvoker: ModelUpdatesInvoker,
	props: Props,
	platform: PlatformSymbol,
}

export const initThunderboltDS = async ({
	dsCarmi,
	pagesModelsRegistrar,
	environment,
	container,
	wixCustomElements,
	catharsisMegaStore,
	catharsis,
	isViewerFragment = false,
}: {
	dsCarmi: DSCarmi
	pagesModelsRegistrar: IPagesModelsRegistrar
	environment: Omit<Environment, 'browserWindow'> & { browserWindow: DsApiFactoryEnv['window'] }
	container: Container
	wixCustomElements: any
	catharsisMegaStore: MegaStoreWithSubscriptions | null
	catharsis: Catharsis
	isViewerFragment: boolean
}): Promise<DsApis> => {
	const window = environment.browserWindow
	container.bind(ViewerSourceSymbol).toConstantValue(window.viewerSource)
	container.bind(PagesModelsRegistrarSymbol).toConstantValue(pagesModelsRegistrar)
	container.bind(ViewerBaseSymbol).toConstantValue(window.viewerBase)
	container.bind(ManifestSymbol).toConstantValue(window.manifest)
	container.bind(CarmiInstance).toConstantValue(dsCarmi)
	container.bind(isViewerFragmentSymbol).toConstantValue(isViewerFragment)
	container.bind(CatharsisMegaStoreSymbol).toConstantValue(catharsisMegaStore)
	container.bind(CatharsisSymbol).toConstantValue(catharsis)
	container.bind(ViewerManagerApi).toProvider(ViewerManagerProvider)

	const {
		container: dsContainer,
		editorFeatures,
		editorFeaturesConfigs,
		masterPageConfigs,
		siteFeaturesConfigs,
		allPageFeaturesConfigs,
		viewerModel,
		$runInBatch,
	} = dsCarmi

	Object.entries(editorFeaturesConfigs).forEach(([featureName, featureConfig]) => {
		container.bind(EditorFeatureConfigSymbol).toConstantValue(featureConfig).whenTargetNamed(featureName)
	})
	Object.entries(siteFeaturesConfigs).forEach(([featureName, featureConfig]) => {
		container.bind(SiteFeatureConfigSymbol).toConstantValue(featureConfig).whenTargetNamed(featureName)
	})
	Object.entries(masterPageConfigs).forEach(([featureName, featureConfig]) => {
		container.bind(MasterPageFeatureConfigSymbol).toConstantValue(featureConfig).whenTargetNamed(featureName)
	})
	Object.entries(allPageFeaturesConfigs).forEach(([featureName, featureConfig]) => {
		container.bind(PageFeatureConfigSymbol).toConstantValue(featureConfig).whenTargetNamed(featureName)
	})
	container.load(createEnvLoader(environment))

	await environment.specificEnvFeaturesLoaders?.loadEditorFeatures(container, editorFeatures)

	// Inject all modules to the carmi instance
	$runInBatch(() => {
		Object.entries(modulesToInjectToCarmi).forEach(([key, symbol]) => {
			const injectedModule = container.get<ContainerModulesValues>(symbol)
			dsContainer.setContainerModule(key, injectedModule)
		})
	})

	const appWillMountHandlers = container.getAll<IAppWillMountHandler>(LifeCycle.AppWillMountHandler)
	await Promise.all(
		appWillMountHandlers.map((appWillMountHandler) => taskify(() => appWillMountHandler.appWillMount()))
	)

	const renderer = container.get<IRenderer<RendererProps, Promise<ClientRenderResponse>>>(RendererSymbol)
	await renderer.init()

	const componentsLoader = container.get<IComponentsLoader>(ComponentsLoaderSymbol)
	const structureStore = container.get<IStructureStore>(Structure)

	environment.logger.phaseStarted(LOADING_PHASES.LOAD_INITIAL_DS_COMPONENTS)
	await Promise.all([
		componentsLoader.loadComponents(structureStore.getEntireStore()),
		componentsLoader.loadComponent('PageMountUnmount'),
	])
	environment.logger.phaseEnded(LOADING_PHASES.LOAD_INITIAL_DS_COMPONENTS)

	environment.logger.phaseStarted(LOADING_PHASES.INIT_DS_VIEWER_API)
	const viewerAPIProvider = await container.get<ViewerAPIProviderInitializer>(ViewerManagerApi)()
	environment.logger.phaseEnded(LOADING_PHASES.INIT_DS_VIEWER_API)
	const getModule = <T>(symbol: Identifier) => container.get<T>(symbol)

	return {
		getViewerAPI: viewerAPIProvider.getViewerAPI,
		render: renderer.render,
		appDidMount: () => {
			const appDidMountHandlers = container.getAll<IAppDidMountHandler>(LifeCycle.AppDidMountHandler)
			appDidMountHandlers.forEach((appDidMountHandler) => appDidMountHandler.appDidMount())
		},
		appWillUnmount: () => {
			const appWillUnmountHandlers = container.getAll<IAppWillUnmountHandler>(LifeCycle.AppWillUnmountHandler)
			appWillUnmountHandlers.forEach((appWillUnmountHandler) => appWillUnmountHandler.appWillUnmount())
		},
		getModule,
		initCustomElements: () => initCustomElementsDS(viewerModel, wixCustomElements),
		...(process.env.NODE_ENV === 'test' ? { dsCarmi } : {}),
	}
}
